import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AddBusiness, EditBusiness, signupBusinessUser } from '../appActions';
import cross_logo from '../Assets/Icons/cross.png'
import configVariables from '../util/constants';
import DeviceVerification from './DeviceVerification';

function AddBusinessForm(props) {

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: props.edit_business_det ? props.edit_business_det : {}
  });
  const dispatch = useDispatch()
  const state = useSelector((state) => state);
  const nav = useNavigate()


  useEffect(() => {
    reset(props.edit_business_det)
  }, [props.edit_business_det])


  const onSubmit = (data, event) => {
    // checking password
    if (data.password !== data.re_password) {
      alert("Password doesn't match")
      return
    }

    // calling function based on mode
    if (props.mode === "signup") {

      data.device_id = [state.device_det[0]._id]
      signupBusinessUser(data).then((res) => {
        if (res) {
          nav('/')
        } else {
          alert(configVariables.error_contact_message)
        }
      }).catch(err => {
        alert(err)
      })

    } else if (props.mode === "edit") {

      data._id = props.edit_business_det._id
      dispatch(EditBusiness(data)).then((res) => {
        if (res) {
          props.setCloseModal(false)
        } else {
          alert(configVariables.error_contact_message)
        }
      }).catch(err => {
        alert(err)
      })

    } else {

      data.device_id = [state.device_det[0]._id]
      dispatch(AddBusiness(data)).then((res) => {
        if (res) {
          props.setCloseModal(false)
        } else {
          alert(configVariables.error_contact_message)
        }

      }).catch(err => {
        alert(err)
      })
    }
  };

  return (
    <div className={props.mode === 'signup' ? 'non-modal' : 'modal'}>
      <form>
        {props.mode !== 'signup' && <img onClick={() => { props.setCloseModal(false) }} className='form-cross-but' src={cross_logo} alt="" />}

        {props.mode !== 'edit' &&
          <DeviceVerification />
        }
        {(state.isDeviceVerified || props.mode === 'edit') &&
          <React.Fragment>
            {props.mode === 'signup' &&
              <>
                <label className='form-label' htmlFor="">
                  Email
                  {props.mode === 'signup' ?
                    <input type="email" autoComplete="email" {...register('email', { required: "This field is required" })} />
                    :

                    props.mode === 'edit' ?
                      <input type="email" {...register('email', { required: "This field is required" })} value={props.edit_business_det.email} readOnly />
                      :
                      <input type="email" {...register('email', { required: "This field is required" })} value={props.user_email} readOnly />
                  }
                  {errors.email && <span className='form-err-span'>{errors.email.message}</span>}
                </label>
                <label className='form-label' htmlFor="">
                  Username
                  <input type="text" {...register('username', { required: "This field is required", maxLength: { value: 20, message: "Maximum length is 20 characters" } })} />
                  {errors.username && <span className='form-err-span'>{errors.username.message}</span>}
                </label>

                <label className='form-label' htmlFor="">
                  Password
                  <input type="password" autoComplete="password" {...register('password', { required: "This field is required", maxLength: { value: 20, message: "Maximum length is 20 characters" } })} />
                  {errors.password && <span className='form-err-span'>{errors.password.message}</span>}
                </label>

                <label className='form-label' htmlFor="">
                  Re-Password
                  <input type="password" {...register('re_password', { required: "This field is required", maxLength: { value: 20, message: "Maximum length is 20 characters" } })} />
                  {errors.re_password && <span className='form-err-span'>{errors.re_password.message}</span>}
                </label>
              </>
            }

            <label className='form-label' htmlFor="">
              Business Name
              <input type="text" {...register('name', { required: "This field is required", maxLength: { value: 20, message: "Maximum length is 20 characters" } })} />
              {errors.name && <span className='form-err-span'>{errors.name.message}</span>}
            </label>

            <label className='form-label' htmlFor="">
              Phone No
              <input type="text" {...register('phone', { required: "This field is required", maxLength: { value: 13, message: "Maximum length is 12 characters" }, pattern: /^[0-9]+$/i })} />
              {errors.phone && <span className='form-err-span'>{errors.phone.message}</span>}
            </label>

            <label className='form-label' htmlFor="">
              Business Address
              <textarea {...register('address', { required: "This field is required" })} id="" cols="30" rows="10"></textarea>
              {errors.address && <span className='form-err-span'>{errors.address.message}</span>}
            </label>
            <div className="form-but-group">
              <button onClick={handleSubmit(onSubmit)} className='form-but-1' type="button">
                {props.mode === "signup" ?
                  'Register'
                  :
                  props.mode === 'edit' ?
                    'Save'
                    :
                    'Add'
                }
              </button>
              {props.mode === 'edit' && <button className='form-but-2' onClick={() => { props.deleteBusiness(props.edit_business_det) }} type='button'>Delete</button>}
            </div>
          </React.Fragment>
        }
      </form>
    </div>
  )
}



export default AddBusinessForm