import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTypes } from '../../appActions'
import AddTypeForm from '../../Components/AddTypeForm'
import edit_logo from '../../Assets/Icons/edit.png'
import trash_logo from '../../Assets/Icons/trash.png'
import callApi from '../../util/callApi'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function Types() {

  const types = useSelector(state => state.types)
  const dispatch = useDispatch()
  const [addModal, setaddModal] = useState(false)
  const [editModal, seteditModal] = useState(false)

  useEffect(() => {
    if(!types){
      dispatch(fetchTypes())
    }
  }, [])

  const handleAddType = ()=> {
    setaddModal(true)
  }

  const handleDelete = async (id)=> {
    if(window.confirm('Are you sure?')){
      try {
        await callApi('delete_type', 'post', {_id:id})
        dispatch(fetchTypes())
      } catch (err) {
        alert('Ann error occured. Please try again!')
      }
    }
  }

  const handleEdit = (type)=> {
    seteditModal(type)
  }

  return (
    <div className='admin-top biz-top containor'>
      <div className="biz-header noti-header">
        <h1>Types</h1>
        <div className="report-data-opts-top">
          <div onClick={()=>{}} className="graph-header-widget noti-add-but ptr">
            <p onClick={handleAddType}>Add Type</p>
          </div>
        </div>
      </div>
      <div className="device-table-top">
        <div className="device-table">
          <div className="table-row table-row-types">
            <p>SI No</p>
            <p>Code</p>
            <p>Name</p>
            <p>Min</p>
            <p>Max</p>
            <p>Unit</p>
            <p>Symbol</p>
            <p>Action</p>
          </div>
          {types?.map((type,i)=>(
            <div className="table-row table-row-types">
              <p>{i+1}</p>
              <p>{type.code}</p>
              <p>{type.name}</p>
              <p>{type.min_value}</p>
              <p>{type.max_value}</p>
              <p>{type.unit_name}</p>
              <p>{type.unit_symbol}</p>
              <div className='gap-5'>
                {/* <img onClick={()=>{handleEdit(type)}} src={edit_logo} alt="edit" />
                <img onClick={()=>{handleDelete(type._id)}} src={trash_logo} alt="delete" /> */}
                <EditIcon onClick={() => handleEdit(type)} />
                <DeleteIcon onClick={() => handleDelete(type._id)} />
              </div>
            </div>
          ))}
        </div>
      </div>

      {addModal && <AddTypeForm setCloseModal={setaddModal} /> }
      {editModal && <AddTypeForm setCloseModal={seteditModal} editObject={editModal} /> }
    </div>
  )
}

export default Types