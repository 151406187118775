import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import configVariables from '../util/constants';
import storageService from '../util/storageService';
import Sidebar from './Navigation/Sidebar';
import burger_logo from '../Assets/Icons/burger.png'
import { useDispatch } from 'react-redux';
import { OpenMobileNav } from '../appActions';
import logo from '../Assets/Images/logo.png'
import Navbar from './Navigation/Navbar';


function SidebarLayout() {

  const dispatch = useDispatch()
  const handleOpenMobileNav = () => {
    dispatch(OpenMobileNav(true))
  }


  return (<>
    {/* <div className="ham_menu_img">
      <img onClick={handleOpenMobileNav} src={burger_logo} alt="" />
      <img src={logo} alt="logo" className='mobile-logo' />
    </div> */}
    <div className="nav-layout-top">
      <Navbar />
      <div className="nav-bars">
        <Sidebar />
        <Outlet />
      </div>
    </div>
  </>)


};


export default SidebarLayout