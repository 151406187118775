import React from 'react'
import './Notification.css'
import add_logo from '../../Assets/Icons/add.png'
import add_big_logo from '../../Assets/Icons/add_big.png'
import edit_logo from '../../Assets/Icons/edit.png'
import trash_logo from '../../Assets/Icons/trash.png'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { DeleteNotification, FetchNotifications, fetchUserDetails } from '../../appActions'
import { useState } from 'react'
import AddNotificationForm from '../../Components/AddNotificationForm'
import AddIcon from '@mui/icons-material/Add';
import Header from '../dashboardheader/header'
import useMediaQuery from '@mui/material/useMediaQuery';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

function Notification() {
  const state = useSelector((state) => state);
  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width: 766px)');

  const [addNotification, setAddNotification] = useState(false)
  const [editNotification, setEditNotification] = useState(false)

  useEffect(() => {
    if(!state.user_det){
      dispatch(fetchUserDetails())
    }
    if(state.user_det && !state.notifications){
      dispatch(FetchNotifications({business_id:state.user_det.business_id}))
    }

  }, [state.user_det])

  const handleDelete = (id)=>{
    if(window.confirm('Are you certain you wish to remove this notification? All related data will also be deleted.')){
      DeleteNotification({_id:id}).then(res=>{
        if(res){
          dispatch(FetchNotifications({business_id:state.user_det.business_id}))
        }else{
          alert("Error occured. Please try again!")
        }
      })
    }
  }
  

  return (
    <div className='noti-top containor'>
  {isMobile && <Header />}
      <div className="noti-header">
        <h1>Notifications</h1>
        {/* <div className="round_but noti-add-but">
          <img src={add_logo} alt="" onClick={()=>{setAddNotification(true)}} />
        </div> */}
      </div>
      <div className="noti-cards-top">
        {state?.notifications?.map(notification =>
          <div className="noti-card">
            <div className="noti-card-header">
              <h1>{notification.sensor_id.name}</h1>
              <div className="noti-card-opts">
                <div className="opt-box" onClick={()=>{setEditNotification(notification)}}>
                  {/* <img src={edit_logo} alt="" /> */}
                  <EditIcon/>
                  
                </div>
                <div className="opt-box">
                  {/* <img src={trash_logo} alt="" onClick={()=>{handleDelete(notification._id)}} /> */}
                  <DeleteIcon onClick={()=>{handleDelete(notification._id)}}/>
                </div>       
              </div>
            </div>
            <div className="noti-card-footer">
              <div className="noti-card-cont">
                <p>Device Name: <span>{notification.device_id.name}</span></p>
                <p>Lower Limit: <span>{notification.lower_limit} </span></p>
                <p>Upper Limit: <span>{notification.upper_limit}</span></p>
                <p>Lower Threshold : <span>{notification.lower_threshold}</span></p>
                <p>Upper Threshold: <span>{notification.upper_threshold}</span></p>
              </div>
            </div>
          </div>
          )}
        <div className="noti-card noti-add-card" onClick={()=>{setAddNotification(true)}}>
          <h1>Add <br /> Notification</h1>
          {/* <img src={add_big_logo} alt="" /> */}
          <AddIcon/>
        </div>
      </div>
      {addNotification && <AddNotificationForm setCloseModal={setAddNotification} user_det={state.user_det} />}
      {Object.keys(editNotification).length>0 && <AddNotificationForm setCloseModal={setEditNotification} user_det={state.user_det} edit_obj={editNotification} />}
    </div>
  )
}

export default Notification