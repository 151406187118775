import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FetchUsersInBusiness, ShareGraph } from '../appActions';
import cross_logo from '../Assets/Icons/cross.png'

function ShareGraphForm({business_id,graph_id, setCloseModal}) {

  const dispatch = useDispatch()
  const state = useSelector((state) => state);

  const [user_id, setUser_id] = useState('')

  useEffect(() => {
    if(!state.users_in_business){
      dispatch(FetchUsersInBusiness({business_id:business_id}))
    }
  }, [])
  
  const hnadleSubmit = (e)=>{
    e.preventDefault()
    if(user_id){
      let data = {}
      let user = getObjectById(state.users_in_business,user_id)
      data.graph_id = graph_id
      data[`${user.userRole}_id`] = user_id
      ShareGraph(data).then(res=>{
        if(res){
          setCloseModal(false)
        }else{
          alert("Error Occured. PLease try again!")
        }
      })
    }else{
      alert("Please select a user!")
    }
  }

  const getObjectById = (arr,id)=>{
    for(let i =0;i<arr.length;i++){
      if(arr[i]._id === id){
        return arr[i];
      }
    }
  }

  return (
    <div className='modal'>
      <form >
        <img onClick={()=>{setCloseModal(false)}} className='form-cross-but' src={cross_logo} alt="" />
        <label htmlFor="" className='form-label'>
          Select user
          <select name="user" required onChange={(e)=>{setUser_id(e.target.value)}}>
            <option value=""></option>
            {state?.users_in_business?.map(user=>(
              <option value={user?._id}>{user?.username}</option>
            ))}
          </select>
        </label>
        <button className='form-but-1' role={'button'} onClick={hnadleSubmit}>Share</button>
      </form>
    </div>
  )
}

export default ShareGraphForm