import React, { useEffect, useState } from 'react'
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import getFormattedDate from '../util/publishTimeGen';
import { publishData } from '../appActions';
import configVariables from '../util/constants';

function SliderChart({data, graphDet, unit}) {
  useEffect(() => {
    if(data && data.length>0){
      let last_value = data[0].value
      setValue(last_value)
    }
  }, [data])
  const [value, setValue] = useState('')

  const handleDragEnd = (x)=>{
    let val = ['n','n','n','n','n','n','n']
    // sending opp data before updating
    val[parseInt(graphDet.sensor_no)] = `${value}`
    let data = {
      topic:`${configVariables.mqtt_topic}/add/${graphDet.topic}`,
      data: {
        tim: getFormattedDate(),
        val:val.join()
      }
    }
    publishData(data).then(res=>{
      // do something
    }).catch(err=>{
      alert(err)
    })
  }
  return (
    <>
      <div className='slider-box'>
        <p className='slider-text'>{value} {unit}</p>
        <RangeSlider 
          value={[0,value]}
          thumbsDisabled={[true,false]}
          rangeSlideDisabled={true}
          onInput={x=>{setValue(x[1])}}
          onThumbDragEnd={handleDragEnd}
          onRangeDragEnd={handleDragEnd}
          className='slider-chart'
        />
      </div>
    </>
  )
}

export default SliderChart