// import React from 'react'
// import { useState } from 'react'
// import { useEffect } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { CreateRegistrationKey, FetchProjectsOfUser, FetchRegistrationKeys } from '../appActions'
// import cross_logo from '../Assets/Icons/cross.png'
// import copy_logo from '../Assets/Icons/copy.png'
// import configVariables from '../util/constants'
// import share_logo from '../Assets/Icons/share.png'

// function AddManagerSuperForm({setCloseModal, manager_project_det, role}) {

//   const dispatch = useDispatch()
//   const state = useSelector((state) => state);
//   const [projectId, setProjectId] = useState(null)
//   const [project_det, setProject_det] = useState([])

//   useEffect(() => {
//     if(!manager_project_det && project_det.length === 0){
//       dispatch(FetchProjectsOfUser(false))
//     }else{
//       setProject_det(manager_project_det)
//     }
//     if(!state.reg_keys){
//       dispatch(FetchRegistrationKeys())
//     }
//     if(state.projects_det){
//       setProject_det(state.projects_det)
//     }
//   }, [state.projects_det])

//   const createRegKey = (e)=>{
//     e.preventDefault()
//     let project;
//     let userRole;
//     if(projectId){
//       project = getObjectById(project_det,projectId)
//       userRole = role
//     }else{
//       alert("Select Project!")
//       return
//     }
//     let expire_date = new Date()
//     expire_date.setMinutes(expire_date.getMinutes()+5)
//     let save_data = {
//       project_id:project._id,
//       business_id:project.business_id,
//       key_expire: expire_date,
//       userRole:userRole
//     }
//     dispatch(CreateRegistrationKey(save_data)).then(res=>{
//       if(!res){
//         alert(configVariables.error_contact_message)
//       }
//     })
//   }

//   const getObjectById = (arr,id)=>{
//     for(let i =0;i<arr.length;i++){
//       if(arr[i]._id === id){
//         return arr[i];
//       }
//     }
//   }
  
//   const handleShare = (reg_key)=>{
//     if (navigator.share) {
//       navigator.share({
//         title: 'Add user link',
//         url: `${window.location.protocol}//${window.location.host}/signup/${reg_key}`
//       }).then(() => {
//         console.log('Thanks for sharing!');
//       })
//       .catch(console.error);
//     }
//   }

//   return (
//     <div className='modal'>
//      <form>
//      <img onClick={()=>{setCloseModal(false)}} className='form-cross-but' src={cross_logo} alt="" />
//       <label htmlFor="" className='form-label'>
//         Select Project
//         <select name="project_id" required onChange={(e)=>{setProjectId(e.target.value)}}>
//           <option value=""></option>
//           {project_det.map(project=>(
//             <option value={project?._id}>{project?.name}</option>
//           ))}
//         </select>
//       </label>
//       <button className='form-but-1' role={'button'} onClick={createRegKey}>Create Key</button>
//       <div className="key-list-top form-label">
//         <p className='key-list-head'>Active Keys</p>
//         <div className="key-list">
//           {state.reg_keys?.map(key=>
//             <div className='key-list-item'>
//               <p>{key.reg_key}</p>
//               <div className='key-list-icons'>
//                 <img src={copy_logo} className='icon' onClick={()=>{navigator.clipboard.writeText(key.reg_key)}} />
//                 <img src={share_logo} alt="share_icon" onClick={()=>{handleShare(key.reg_key)}} />
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//      </form>
//     </div>
//   )
// }

// export default AddManagerSuperForm

import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CreateRegistrationKey, FetchProjectsOfUser, FetchRegistrationKeys } from '../appActions'
import cross_logo from '../Assets/Icons/cross.png'
import copy_logo from '../Assets/Icons/copy.png'
import configVariables from '../util/constants'
import share_logo from '../Assets/Icons/share.png'
import Select from 'react-select'
import { useForm } from 'react-hook-form';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    
    backgroundColor: 'rgba(47, 47, 47, 1)',
    boxShadow: '0px 7.2px 10.8px 5.4px rgba(0, 0, 0, 0.15), 0px 3.6px 3.6px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',
    border: 'none',
    color: 'white',
    height: '50px',
    padding: '10px',
    width: '100%',
    gridArea: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px'
    
  }),
  menu: (provided) => ({
    ...provided,
    background:'rgba( 76, 76, 76, 0.65 )',
    backdropFilter:'blur( 9.5px )',
    borderRadius: '10px',
    border: '1px solid rgba( 255, 255, 255, 0.18 )',
    // backgroundColor: '#4a4948',
    fontSize:'12px',
    
    // border:'1px solid #7469B6',
    color: 'white',
    fontSize:'12px'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '' : 'transparent',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#303043',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'white',
    ':hover': {
      backgroundColor: '#FB493F',
      color: 'white',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'white',
  }),
};

function AddManagerSuperForm({setCloseModal, manager_project_det, role,edit_obj}) {
  const { register, handleSubmit, getValues, setValue } = useForm({
    defaultValues: {
      ...edit_obj,
      project_id: edit_obj?.device_id.project_id,
      // device_id: edit_obj?.device_id._id,
      // sensor_id: edit_obj?.sensor_id._id,
    },
  });

  const dispatch = useDispatch()
  const state = useSelector((state) => state);
  const [projectId, setProjectId] = useState(null)
  const [project_det, setProject_det] = useState([])

  useEffect(() => {
    if(!manager_project_det && project_det.length === 0){
      dispatch(FetchProjectsOfUser(false))
    }else{
      setProject_det(manager_project_det)
    }
    if(!state.reg_keys){
      dispatch(FetchRegistrationKeys())
    }
    if(state.projects_det){
      setProject_det(state.projects_det)
    }
  }, [state.projects_det])

  const createRegKey = (e)=>{
    e.preventDefault()
    let project;
    let userRole;
    if(projectId){
      project = getObjectById(project_det,projectId)
      userRole = role
    }else{
      alert("Select Project!")
      return
    }
    let expire_date = new Date()
    expire_date.setMinutes(expire_date.getMinutes()+5)
    let save_data = {
      project_id:project._id,
      business_id:project.business_id,
      key_expire: expire_date,
      userRole:userRole
    }
    dispatch(CreateRegistrationKey(save_data)).then(res=>{
      if(!res){
        alert(configVariables.error_contact_message)
      }
    })
  }

  const getObjectById = (arr,id)=>{
    for(let i =0;i<arr.length;i++){
      if(arr[i]._id === id){
        return arr[i];
      }
    }
  }
  
  const handleShare = (reg_key)=>{
    if (navigator.share) {
      navigator.share({
        title: 'Add user link',
        url: `${window.location.protocol}//${window.location.host}/signup/${reg_key}`
      }).then(() => {
      })
      .catch(console.error);
    }
  }
 
  const projectOptions = project_det.map(project => ({
    value: project?._id, // the value of the option
    label: project?.name // the label that will be displayed
  }));
  console.log(projectOptions)
  

  return (
    <div className='modal'>
     <form>
     <img onClick={()=>{setCloseModal(false)}} className='form-cross-but' src={cross_logo} alt="" />
      <label htmlFor="" className='form-label'>
        Select Project
        {/* <select name="project_id" required onChange={(e)=>{setProjectId(e.target.value)}}>
          <option value=""></option>
          {project_det.map(project=>(
            <option value={project?._id}>{project?.name}</option>
          ))}
        </select> */}
        <Select
          styles={customStyles}
          options={projectOptions}
          onChange={(selectedOption) => {
            setProjectId(selectedOption ? selectedOption.value : null);
          }}
            />
      </label>
      <button className='form-but-1' role={'button'} onClick={createRegKey}>Create Key</button>
      <div className="key-list-top form-label">
        <p className='key-list-head'>Active Keys</p>
        <div className="key-list">
          {state.reg_keys?.map(key=>
            <div className='key-list-item'>
              <p>{key.reg_key}</p>
              <div className='key-list-icons'>
                <img src={copy_logo} className='icon' onClick={()=>{navigator.clipboard.writeText(key.reg_key)}} />
                <img src={share_logo} alt="share_icon" onClick={()=>{handleShare(key.reg_key)}} />
              </div>
            </div>
          )}
        </div>
      </div>
     </form>
    </div>
  )
}

export default AddManagerSuperForm