import React, { useEffect } from 'react'
import warning_logo from '../../Assets/Icons/warning.png'
import './Error.css'
import logout_logo from '../../Assets/Icons/logout.png'
import logo from '../../Assets/Images/logo.png'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ResetState } from '../../appActions'
import storageService from '../../util/storageService'
import configVariables from '../../util/constants'


function InactiveAccount() {

  const dispatch = useDispatch()
  const nav = useNavigate()

  useEffect(() => {
    
  }, [])
  
  const logOut = () => {
    storageService.remove(configVariables.USER_ID)
    storageService.remove(configVariables.USER_ROLE);
    storageService.remove(configVariables.USER_JWT_TOKEN);
    dispatch(ResetState())
    nav('/login')
  }

  return (
    <div className='warning-top'>
      <div className='warning-header pay-table-head-right'>
        <img src={logo} alt="logo" />
        <p onClick={logOut}><img src={logout_logo} alt="logo" />Logout</p>
      </div>
      <div className="warning-cont">
        <div className="warning-img">
          <img src={warning_logo} alt="warning_logo" />
        </div>
        <div className="warning-title">
          <h2>Account Suspended</h2>
        </div>
        <div className="warning-tag">
          <p>The business account is currently under temporary suspension due to outstanding payments</p>
        </div>
      </div>
    </div>
  )
}

export default InactiveAccount