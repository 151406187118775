import React from 'react'
import { Tooltip } from 'react-tooltip'
import info_logo from '../Assets/Icons/info.png'

function InfoButton({content}) {
  return (
    <>
    <a className="my-anchor-element" data-tooltip-content={content}><img src={info_logo} alt="tooltip" /></a>
    <Tooltip anchorSelect=".my-anchor-element"></Tooltip>
    </>
  )
}

export default InfoButton