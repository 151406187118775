import g1_logo from '../Assets/Images/g1.png'
import single_logo from '../Assets/Images/single.png'
import alert_logo from '../Assets/Images/alert.png'
import bar_logo from '../Assets/Images/g2.png'
import switch_logo from '../Assets/Images/switch.png'
import battery_logo from '../Assets/Images/battery.png'
import level_logo from '../Assets/Images/level.png'
import progress_logo from '../Assets/Images/progress.png'
import net_logo from '../Assets/Images/net.png'
import slider_logo from '../Assets/Images/slider.png'
import map_logo from '../Assets/Images/map.png'
import flight_indicator from '../Assets/Images/flight_indicator.png'
import flightgps from '../Assets/Images/flightgps.png'
const configVariables = {
  type_business: "business",
  type_manager:"manager",
  type_super:"super",
  USER_ID:"user",
  USER_ROLE:"role",
  USER_JWT_TOKEN:'jwt_token',
  role_manager:"manager",
  role_super:"super",
  role_owner:"owner",
  role_admin:"admin",
  data_link: process.env.REACT_APP_ENV == 'development' ? "http://localhost:3002" : "https://cloud.abhram.com",
  mqtt_topic: process.env.REACT_APP_ENV == 'development' ? 'ahtest' : 'ah', 
  error_contact_message: "Some error occurred. Please try again. If issue persists contact the administrator!",
  data_refresh_interval: 30000
}
export const graphTypes = {
  line:{height:'28em',width:1},
  gps:{height:'28em',width:1},
  flight_Indicator:{height:'28em',width:1},
  alert:{height:'14em',width:1},
  fixed:{height:'14em',width:1},
  bar:{height:'28em',width:1},
  switch:{height:'14em',width:1},
  battery:{height:'14em',width:1},
  level:{height:'28em',width:1},
  progress:{height:'14em',width:1},
  horizontal_level:{height:'14em',width:1},
  network_meter:{height:'14em',width:1},
  slider:{height:'14em',width:1},
  flight_Indicator:{height:'28em',width:1},
  flightgps:{height:'28em',width:1},
  flightmessage:{height:'28em',width:1},
  flightmap:{height:'28em',width:1},
  ARM_RTL:{height:'28em',width:1},
  Table:{height:'28em',width:1},
  FlightmapSecond:{height:'28em',width:1}
  

}

export const grpahImages = {
  line: g1_logo,
  gps: map_logo,
  alert:alert_logo,
  fixed:single_logo,
  bar:bar_logo,
  switch:switch_logo,
  battery:battery_logo,
  level:level_logo,
  progress:progress_logo,
  horizontal_level:level_logo,
  network_meter:net_logo,
  slider:slider_logo,
  flight_Indicator:flight_indicator,
  flightgps:flightgps,
  flightmessage:alert_logo,
  flightmap:map_logo,
  ARM_RTL:switch_logo,
  ARM_RTL_INP:'',
  Table:'',
  FlightmapSecond:map_logo
}
export default configVariables;
// data_link: "http://localhost:3002",