import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { sendOtp, verifyDevice, VerifyMultiDevices } from '../appActions';

function DeviceVerification({ isMulti, biz_phone = null}) {

  // react-hook-form vars
  const { register, handleSubmit, getValues, control } = useForm();
  const otp = useWatch({
    control,
    name: "otp",
  });

  // redux vars
  const dispatch = useDispatch()
  const state = useSelector((state) => state);

  // states
  const [prevState, setPrevState] = useState({})
  const [isDeviceVerifying, setIsDeviceVerifying] = useState(false)
  const [isDeviceVerified, setIsDeviceVerified] = useState(false)

  // update handling
  useEffect(() => {
    if(prevState?.user_det?.userExists !== state?.user_det?.userExists && state?.user_det?.userExists){
      alert("User already registered")
    }
    if(prevState?.isOtpSent !== state?.isOtpSent){
      alert(state.otp_message)
      if(state?.isOtpSent){
        setIsDeviceVerifying(true)
      }
    }
    if(prevState?.isDeviceVerified !== state?.isDeviceVerified){
      alert(state.verification_message)
      if(state?.isDeviceVerified){
        setIsDeviceVerified(true)
        setIsDeviceVerifying(false)
      }else{
        setIsDeviceVerifying(true)
      }
    }
    setPrevState(state)
    // eslint-disable-next-line
  }, [state])

  useEffect(() => {
    if(otp?.length === 4 && !isDeviceVerified){
      setIsDeviceVerifying(false)
      dispatch(verifyDevice({otp:otp,serial_no: getValues("serial_no").split(',')}))
    }
    // eslint-disable-next-line
  }, [otp])

  // functions
  const verfiyDevice = async ()=>{
    if(isMulti){
      const data = {
        serial_nos: getValues("serial_no").split(','),
        phone:biz_phone
      }
      try{
        const res = await dispatch(VerifyMultiDevices(data))
        alert(res)
      }catch(err){
        alert(err)
      }
    }else{
      const data = {serial_no: [getValues("serial_no")]}
      dispatch(sendOtp(data))
    }
   
  }


  return (
    <div className='device-ver-top'>
      <form>
      <label className='form-label' htmlFor="">
        Serial No
        <input 
          type="text" 
          {...register('serial_no',{ required: true, maxLength: 20 })} 
          readOnly={isDeviceVerified ? true : false}
        />
      </label>
      {isDeviceVerifying && 
        <>
        <label className='form-label' htmlFor="">
          OTP
          <input type="text" {...register('otp',{ required: true, maxLength: 4 })}/>
        </label>
        </>
      }
      {!isDeviceVerified && 
        <button className='form-but-1' onClick={handleSubmit(verfiyDevice)} type="button">Verify Device</button>
      }
      </form>
    </div>
  )
}

export default DeviceVerification