import React from 'react'
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { LoginUser } from '../../appActions';
import configVariables from '../../util/constants';
import storageService from '../../util/storageService';
import logo from '../../Assets/Images/logo.png'
import { ErrorMessage } from '@hookform/error-message';


function Login({ onLoginSuccess }) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.setProperty('background-color', 'black', 'important');
    }
  }, []);

  const { register, handleSubmit, formState:{errors} } = useForm();
  const nav = useNavigate()

  const [formErrors, setFormErrors] = useState('')

  const onSubmit = (data) => {
    if(!data.email || !data.password){
      alert("Please fill all fields!")
      return
    }
    LoginUser(data).then(res=>{
      if(res.status){
        if(storageService.get(configVariables.USER_ROLE) === configVariables.role_admin){
          nav('/admin')
        }else{
          onLoginSuccess();
          nav('/')
          // setTimeout(() => {
          //   window.location.reload('/');
          // }, 500);
        }
      }else{
        alert(res.message)
      }
    })
  };

  
  return (
    <div className='login-box'>
      <form action="" onSubmit={handleSubmit(onSubmit)} className='form-box'>
        <div className="login-logo">
          <img src={logo} alt="login-logo" />
        </div>
        <div className="login-head">
          <p>Login</p>
        </div>
        <div className="login-form-body-top">
          {/* {formErrors && <p>{formErrors}</p>} */}
          <div className="login-form-body">
            <label htmlFor="" className='login-label'>
              Email
              <input ref={inputRef} placeholder='john@doe.com' type="email" {...register('email',{ required: "Please fill out this required field!"})} style={{backgroundColor: 'black !important '}} />
              <ErrorMessage errors={errors} name="email"
              
              />
            </label>   
            
            <label htmlFor="" className='login-label'>
              Password
              <input ref={inputRef} placeholder='Some strong Password' type="password" {...register('password',{ required: 'Please fill out this required field!', maxLength: 20 })}/>
              <ErrorMessage errors={errors} name="password" />
            </label>
          </div>
          <Link to={'/forgot_password'}>Forgot Password?</Link>      
          <div className="login-but-group">
            <button className='login-but'>Login</button>
          </div>
          <p className='login-reg-link'>
            Don't have an account yet? 
            <Link to={'/signup'}>Register</Link> 
          </p>
        </div>
      </form>
    </div>
  )
}



export default Login