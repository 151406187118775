import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DeleteBusiness, DeleteProject, DeleteSuper, fetchDeviceInBusiness, fetchUserDetails } from '../../appActions';
import AddBusinessForm from '../../Components/AddBusinessForm';
import AddProjectForm from '../../Components/AddProjectForm';
import mail_logo from '../../Assets/Icons/mail.png'
import phone_logo from '../../Assets/Icons/phone.png'
import loc_logo from '../../Assets/Icons/loc.png'
import edit_logo from '../../Assets/Icons/edit.png'
import trash_logo from '../../Assets/Icons/trash.png'
import add_big_logo from '../../Assets/Icons/add_big.png'
import add_logo from '../../Assets/Icons/add.png'
import configVariables from '../../util/constants';
import AddManagerSuperForm from '../../Components/AddManagerSuperForm';
import storageService from '../../util/storageService';
import Header from '../dashboardheader/header';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';


function Project() {

  const dispatch = useDispatch()
  const state = useSelector((state) => state);
  const isMobile = useMediaQuery('(max-width: 766px)');

  const [addManagerSuperModal, setAddManagerSuperModal] = useState(false)
  const [project_det, setProject_det] = useState([])


  useEffect(() => {
    if(!state.user_det && !state.user_project_det){
      dispatch(fetchUserDetails(storageService.get(configVariables.USER_ROLE)))
    }
    if(state.user_project_det && project_det.length===0){
      setProject_det([state.user_project_det.project_id])
    }else if(state.user_det && project_det.length===0){
      let projects = []
      state.user_det.business_id.map(biz=>{
        projects.push(...biz.project_id)
      })
      setProject_det(projects)
    }
  }, [state.user_project_det,state.user_det])

  const deleteSuper = (super_det)=>{
    if(window.confirm('Are you certain you wish to remove this supervisor? All related data will also be deleted.')){
      DeleteSuper({_id:super_det._id, project_id:super_det.project_id}).then(res=>{
        if(res){
          dispatch(fetchUserDetails(configVariables.role_manager))
        }else{
          alert(configVariables.error_contact_message)
        }
      })
    }
  }

  return (
    <div className='biz-top containor'>
     {isMobile && <Header />}
      <div className="noti-header">
        <h1>Project</h1>
      </div>
      {project_det.length === 0 && <p className='empty-message'>No Projects to show. Please create one!</p>}
      {project_det.map(project=>(
         <div className='biz-box'>
          <div className="det-box">
            <div className="biz-det">
              <h1>{project.name}</h1>
              <p className='biz-p'><EmailIcon/>{project.email}</p>
              <p className='biz-p'><PhoneIcon/>{project.phone}</p>
              <p className='biz-p-2'><LocationOnIcon/>{project.address}</p>
              <h3>Total Devices : {project.device_id?.length}</h3>
            </div>
          </div>
          <div className="proj-cards-top">
            {project.super_id?.map(super_det=>{
              return(
                <div className="proj-card">
                  <div className="proj-card-header">
                    <h1>{super_det.username}</h1>
                    <div className="proj-card-opts">
                      <div className="opt-box ptr">
                        {/* <img src={trash_logo} alt="" onClick={()=>{deleteSuper(super_det)}} /> */}
                        <DeleteIcon onClick={()=>{deleteSuper(super_det)}}/>
                      </div>
                    </div>
                  </div>
                  <div className="proj-card-cont">
                    <p>Phone : <span>{super_det.phone_number}</span></p>
                    <p>Email : <span>{super_det.contact_email}</span></p>
                  </div>
                </div>
                  )
                })}
                <div onClick={()=>{setAddManagerSuperModal(true)}} className="proj-card proj-add-card ptr">
                  <h1>Add <br /> Supervisor</h1>
                  {/* <img src={add_big_logo} alt="" /> */}
                  <AddIcon/>
                </div>
          </div>
        </div>
      ))}
      
      {addManagerSuperModal &&
        <AddManagerSuperForm
          setCloseModal={setAddManagerSuperModal}
          manager_project_det={project_det}
          role={configVariables.role_super}
          
        />
      }
    </div>
  )
}
  
export default Project