import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AddSensor, fetchDeviceById, fetchTypes } from '../appActions';
import cross_logo from '../Assets/Icons/cross.png'
import callApi from '../util/callApi';

function AddSensorForm({mode, setCloseModal, device_id, editObject, sensor_no}) {

  const { register, handleSubmit } = useForm({defaultValues: editObject ? editObject : {'save_data':true}});
  const [typeOptions, setTypeOptions] = useState([])
  const dispatch = useDispatch()
  const state = useSelector((state) => state);

  useEffect(() => {
    if(!state.types){
      dispatch(fetchTypes())
    }
  }, [])
  
  const [intervalOptions, setIntervalOptions] = useState([
    {label: '10 seconds', value: 10},
    {label: '30 seconds', value: 30},
    {label: '1 minute', value: 60},
    {label: '5 minutes', value: 300},
    {label: '10 minutes', value: 600},
    {label: '3 hours', value: 10800},
    {label: '6 hours', value: 21600},
    {label: '12 hours', value: 43200},
    {label: '24 hours', value: 86400},
    {label: '48 hours', value: 172800},
  ])


  const handleTypeSelect = (e)=>{
  }
  const onSubmit = (data)=>{
    if(mode === 'edit'){
      data._id = editObject._id
      if(data.save_data){
        data.last_save_data = new Date()
      }else{
        data.last_save_data = null
      }
      callApi('edit_sensor','post',data).then(res=>{
        if(res.status === 'Success'){
          alert("Sensor updated succesfully!")
          dispatch(fetchDeviceById({_id:device_id}))
          setCloseModal(false)
        }else{
          alert("An error occured. Please try again!")
          dispatch(fetchDeviceById({_id:device_id}))
          setCloseModal(false)
        }
      })
    }else{
      if(data.save_data){
        data.last_save_data = new Date()
      }else{
        data.last_save_data = null
      }
      data.device_id = device_id
      data.sensor_no =  sensor_no
      dispatch(AddSensor(data)).then(obj=>{
        setCloseModal(false)
      })
    }
  }

  return (
    <div className='modal'>
      <form >
        <img onClick={()=>{setCloseModal(false)}} className='form-cross-but' src={cross_logo} alt="" />
        <label className='form-label' htmlFor="">
          Sensor Name
          <input type="text" {...register('name',{ required: true })}/>
        </label>
        <label htmlFor="" className='form-label'>
          Select Type
          <select {...register('type',{ required: true })} onChange={handleTypeSelect}>
            <option value={null}>Select</option>
            {state?.types?.map(type=> <option selected={type._id === editObject.type} value={type._id}>{type.name} - {type.code}</option>)}
          </select>
        </label>
        <label htmlFor="" className='form-label'>
          Select Fetch Interval
          <select {...register('fetch_interval',{ required: true })} onChange={handleTypeSelect}>
            <option value={null}>Select</option>
            {intervalOptions.map(interval=> <option value={interval.value}>{interval.label}</option>)}
          </select>
        </label>
        <label className='form-label form-label-checkbox' htmlFor="">
          Save Data?
          <input type="checkbox"  {...register('save_data')}/>
        </label>
        {/* <label className='form-label form-label-checkbox' htmlFor="">
          Hide in report?
          <input type="checkbox" {...register('hide_report')}/>
        </label> */}
        <div className="form-but-group">
          <button className='form-but-1' onClick={handleSubmit(onSubmit)} type="button">
          {
          mode === 'edit'?
            'Save'
          :
            'Add'
          }
          </button>
          </div>
      </form>
    </div>
  )
}

export default AddSensorForm