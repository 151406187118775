import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { DisableGraph } from '../appActions';

function DisableGraphForm({graph_det,setCloseModal,fetchGraphs,disabled_graphs}) {
  const { register, handleSubmit, control} = useForm({defaultValues:{'disable_graphs':disabled_graphs}});

  const onSubmit = (data)=>{
    if(JSON.stringify(data.disable_graphs) !== JSON.stringify(disabled_graphs)){
      data.enable_graphs = disabled_graphs.filter(d=>!data.disable_graphs.includes(d))
      DisableGraph(data).then((res)=>{
        if(res){
          fetchGraphs()
          setCloseModal(false)
        }else{
          alert("An error occured. Please try again!")
        }
      })
    }else{
      setCloseModal(false)
    }
  }

  return (
    <div className='modal'>
      <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="disable_graphs"
        control={control}
        render={({ field }) => (
          <>
           {graph_det?.map(graph=>
              <label className='form-label-checkbox' htmlFor="">
                <p className='checkbox-p'>{graph.name}</p>
                <input 
                  type="checkbox" 
                  value={graph._id} 
                  {...register('disable_graphs')}
                  checked={field.value.some(
                    (existingValue) => existingValue === graph._id
                  )}
                  onChange={(event, checked) => {
                    if (event.target.checked) {
                      field.onChange([
                        ...field.value,
                        event.target.value
                      ]);
                    } else {
                      field.onChange(
                        field.value.filter(
                          (value) => value !== event.target.value
                        )
                      );
                    }
                  }}
                />
              </label>
            )}
          </>
            )}
          />
       
        <div className="form-but-group">
        <button className='form-but-2' role={'button'} onClick={()=>{setCloseModal(false)}}>Cancel</button>
          <button className='form-but-1'>Save</button>

        </div>
      </form>
    </div>
  )
}

export default DisableGraphForm