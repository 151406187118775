// src/Components/Header/Header.jsx

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Notification from '../../Components/Notification/Notification';
import { fetchUserDetails, FetchSubscription } from '../../appActions';
import '../dashboardheader/header.css'
import useMediaQuery from '@mui/material/useMediaQuery';

const Header = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const isMobile = useMediaQuery('(max-width: 766px)');

  const [isFullScreenEnabled, setIsFullScreenEnabled] = useState(false);
  const [deviceConnectionCount, setDeviceConnectionCount] = useState({
    connected: 0,
    disconnected: 0
  });

  useEffect(() => {
    if (!state.user_det) {
      dispatch(fetchUserDetails());
    }
    if (!state.subscription) {
      dispatch(FetchSubscription());
    }
    if (state.user_det) {
      let count = {
        connected: 0,
        disconnected: 0
      };
      state.user_det.business_id?.map(business => {
        business.device_id.map(device => {
          if (device.connection_status) {
            count.connected += 1;
          } else {
            count.disconnected += 1;
          }
        });
      });
      setDeviceConnectionCount(count);
    }
  }, [state.user_det]);

  const handleFullScreen = () => {
    if (isFullScreenEnabled) {
      document.exitFullscreen();
      setIsFullScreenEnabled(false);
    } else {
      document.body.requestFullscreen();
      setIsFullScreenEnabled(true);
    }
  };

  return (
    <div className="dash-header1">
          <div className="header-widget">
            {/* <img src={full_screen_logo} alt="nav_icon" className='icon' onClick={handleFullScreen} /> */}
            {/* <FullscreenIcon className='icon' onClick={handleFullScreen}  /> */}

          </div>
          <div className="header-widget">
            <div className="dev_conn header_but">
              <p style={{color:"white"}}>Online  :  {deviceConnectionCount.connected}</p>
            </div>
            <div className="offline_count header_but">
              <p style={{color:"white"}}>Offline : {deviceConnectionCount.disconnected}</p>
            </div>
            <div className="bal_data header_but">
              <p style={{color:"white"}}>Data : {state.subscription?.subscription?.dataUsage}</p>
            </div>
          </div>
          <div className="header-widget">
            <div className="bal_day header_but">
              <p style={{color:"white"}}>Days : {Math.floor((new Date(state.subscription?.subscription?.validUpto).getTime() - new Date().getTime()) / (1000 * 3600 * 24))}</p>
            </div>
            {/* <div className="round_but noti_but">
            /*notification
            </div> */}
            {/* <div className="round_but settings_but">
              <img src={settings_logo} alt="" />
            </div> */}
            {!isMobile && <Notification/> }
            <br />
          </div>
        </div>
  );
};

export default Header;
