



import {
    Airspeed,
    Altimeter,
    AttitudeIndicator,
    HeadingIndicator,
    TurnCoordinator,
    Variometer,
} from "react-typescript-flight-indicators";
import React, { useState, useEffect, useRef } from 'react';
import './altitude.css';

const FlightIndicators = ({ data }) => {
    
    const [currentIndex, setCurrentIndex] = useState(0);
    const [pitchValues, setPitchValues] = useState([]);
    const [rollValues, setRollValues] = useState([]);
    const [altitude, setAltitude] = useState(0);
    const [pitch, setPitch] = useState(0);
    const [roll, setRoll] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const [lastProcessedDatetime, setLastProcessedDatetime] = useState(localStorage.getItem('lastProcessedDatetime'));

    const processCurrentData = (currentData) => {
        const [pitchstart, pitchend, rollstart, rollend, altitude] = currentData.value.split('+');
        const pitchArray = [];
        const rollArray = [];

        for (let i = parseFloat(pitchstart); i <= parseFloat(pitchend); i++) {
            pitchArray.push(i);
        }
        for (let i = parseFloat(rollstart); i <= parseFloat(rollend); i++) {
            rollArray.push(i);
        }

        setPitchValues(pitchArray);
        setRollValues(rollArray);
        setAltitude(altitude);
        setCurrentIndex(0);
        setRoll(rollArray[0]);
        setPitch(pitchArray[0]);

        localStorage.setItem('lastpitch', pitchArray[pitchArray.length - 1]);
        localStorage.setItem('lastroll', rollArray[rollArray.length - 1]);
        localStorage.setItem('lastaltitude', altitude);

        if (intervalId) {
            clearInterval(intervalId);
        }
        setLastProcessedDatetime(currentData.datetime);
        localStorage.setItem('lastProcessedDatetime', currentData.datetime);

        const id = setInterval(() => {
            setCurrentIndex(prevIndex => {
                const newIndex = prevIndex + 1;
                if (newIndex < pitchArray.length) {
                    setPitch(pitchArray[newIndex]);
                    setRoll(rollArray[newIndex]);
                    return newIndex;
                } else {
                    clearInterval(id);
                    return prevIndex;
                }
            });
        }, 1200);

        setIntervalId(id);
    };

    useEffect(() => {
        if (data && data.length > 0) {
            const currentData = data[0];

            if (lastProcessedDatetime !== currentData.datetime) {
                processCurrentData(currentData);
            } else {
            }
        } else {
            const lastpitch = localStorage.getItem('lastpitch');
            const lastroll = localStorage.getItem('lastroll');
            const lastaltitude = localStorage.getItem('lastaltitude');


            if (lastpitch && lastroll && lastaltitude) {
                setPitch(lastpitch);
                setRoll(lastroll);
                setAltitude(lastaltitude);
            }
        }
    }, [data, lastProcessedDatetime]);

    useEffect(() => {
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [intervalId]);
    if (!data || data.length === 0) {
        return <p>No data available</p>;
    }

    return (
        <div className="flight-card" style={{ borderRadius: '5px' }}>
            <br /><br />
            <div style={{ marginRight: "20px" }}>
                <AttitudeIndicator roll={parseFloat(roll)} pitch={parseFloat(pitch)} showBox={false} />
            </div>
            <div className="flight-items">
                <p id="myfont">Alt: {altitude} m/s</p>
            </div>
        </div>
    );
};

export default FlightIndicators;