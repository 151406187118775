import callApi from "./util/callApi";
import configVariables from "./util/constants";
import storageService from "./util/storageService";

export const SET_USER = "SET_USER";
export const GET_USER = "GET_USER";
export const GET_USER_AND_PROJECT = "GET_USER_AND_PROJECT";
export const SET_BUSINESS = "SET_BUSINESS";
export const GET_BUSINESS = "GET_BUSINESS";
export const DELETE_BUSINESS = "DELETE_BUSINESS";
export const VERIFY_DEVICE = "VERIFY_DEVICE" 
export const SEND_OTP = "SEND_OTP"
export const SET_KEY = "SET_KEY"
export const LOGIN_USER = "LOGIN_USER"
export const FETCH_DEVICES = "FETCH_DEVICES"
export const FETCH_DEVICE = "FETCH_DEVICE"
export const FETCH_PROJECTS = "FETCH_PROJECTS"
export const ADD_PROJECT = "ADD_PROJECT"
export const ADD_EDIT_DEVICE = "ADD_EDIT_DEVICE"
export const VERIFY_MULTI_DEVICES = "VERIFY_MULTI_DEVICES"
export const ADD_GRAPH = "ADD_GRAPH"
export const FETCH_GRAPH = "FETCH_GRAPH"
export const FETCH_GRAPH_DATA = "FETCH_GRAPH_DATA"
export const ADD_SENSOR = "ADD_SENSOR"
export const FETCH_KEYS = "FETCH_KEYS"
export const OPEN_CLSOE_NAV = "OPEN_CLSOE_NAV"
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS"
export const USERS_IN_BUSINESS = "USERS_IN_BUSINESS"
export const RESET_STATE = "RESET_STATE"
export const FETCH_SUBSCRIPTION = "FETCH_SUBSCRIPTION"
export const FETCH_TYPES = "FETCH_TYPES"

const signupBusinessUser = (data)=>{
  return new Promise((resolve,reject)=>{
    return callApi('signup_business', 'post', data).then((res)=>{
      if (res.status === 'Success') {
        // dispatch({
        //   type: SET_BUSINESS,
        //   business_det: res.data.business_det,
        //   signupStatus: res.data.signupStatus
        // });
        // dispatch({
        //   type: SET_USER,
        //   user_det: res.data.user_det,
        //   signupStatus: res.data.signupStatus
        // })
        storageService.set(configVariables.USER_ID, res.data.user_det.user._id);
        storageService.set(configVariables.USER_JWT_TOKEN, res.data.token);
        storageService.set(configVariables.USER_ROLE, res.data.user_det.user.userRole);
        resolve(true)
      }else{
        reject(false)
      }
    })
  })
}

const sendOtp = (data)=>{
  return(dispatch=>{
    return callApi('device_send_otp', 'post', data).then((res)=>{
      if (res.status === 'Success') {
        dispatch({
          type: SEND_OTP,
          isOtpSent: res.data.isOtpSent,
          message:res.data.message,
          device_det: res.data.device_det,
        });
      }else{
        dispatch({
          type: SEND_OTP,
          isOtpSent: false,
          message:"Unable to send OTP. Please try again!"
        });
      }
    })
  })
}

const verifyDevice = (data)=>{
  return(dispatch=>{
    return callApi('verify_device', 'post', data).then((res)=>{
      if (res.status === 'Success') {
        dispatch({
          type: VERIFY_DEVICE,
          isDeviceVerified: true,
          message: res.data
        });
      }else if(res.status === 'Failed'){
        dispatch({
          type: VERIFY_DEVICE,
          isDeviceVerified: false,
          message: res.data
        });
      }else{
        dispatch({
          type: VERIFY_DEVICE,
          isDeviceVerified: false,
          message: "An error occured plaese try again!"
        });
      }
    })
  })
}

const signupNormalUsers = (data)=>{

    return(dispatch=>{
      return new Promise((resolve,reject)=>{
        return callApi('signup_normal', 'post', data).then((res)=>{
          if (res.status === 'Success') {
            storageService.set(configVariables.USER_ID, res.data.user_det.user._id);
            storageService.set(configVariables.USER_JWT_TOKEN, res.data.token);
            storageService.set(configVariables.USER_ROLE, res.data.user_det.user.userRole);
            resolve("Registered Succesfully!")
          }else{
            reject(res.data.message)
          }
        })
    })
  })
}

const LoginUser = (data)=>{
  return new Promise((resolve, reject)=>{
    return callApi('login', 'post', data).then((res)=>{
      if (res.status === 'Success') {
        storageService.set(configVariables.USER_ID, res.data._id);
        storageService.set(configVariables.USER_JWT_TOKEN, res.data.token);
        storageService.set(configVariables.USER_ROLE, res.data.userRole);
        resolve({status:true});
      }else{
        resolve({status:false,message:res.data.message})
      }
    })
  })
}

const fetchUserDetails = (data = 'owner')=>{
  return(dispatch)=>{
    const userId = storageService.get(configVariables.USER_ID)
    return callApi('fetch_user_details', 'post',{userId:userId,role:storageService.get(configVariables.USER_ROLE)}).then(res=>{
      if(res.status === "Success"){
        dispatch({
          type:GET_USER,
          user_det:res.data
        })
      }else{
        dispatch({
          type:GET_USER,
          user_det:[]
        })
      }
    })
  }
}
const FetchUsersInBusiness = (data)=>{
  return dispatch=>{
    return callApi('fetch_users_in_business','post',data).then(res=>{
      if(res.status === "Success"){
        dispatch({
          type:USERS_IN_BUSINESS,
          users_in_business:res.data
        })
      }else{
        dispatch({
          type:USERS_IN_BUSINESS,
          users_in_business:[]
        })
      }
    })
  }
}

const AddBusiness = (data)=>{
  return(dispatch)=>{
    return new Promise((resolve,reject)=>{
      data.owner_id = storageService.get(configVariables.USER_ID)
      return callApi('add_business', 'post', data).then((res)=>{
        if (res.status === 'Success') {
          dispatch(fetchUserDetails())
          // dispatch({
          //   type: SET_BUSINESS,
          //   business_det: res.data.business_det,
          //   signupStatus: res.data.signupStatus
          // });
          resolve(true)
        }else{
          reject(res.data.message)
        }
      })
    })
  }
}
const EditBusiness = (data)=>{
  return(dispatch=>{
    return new Promise((resolve,reject)=>{
      data.owner_id = storageService.get(configVariables.USER_ID)
      return callApi('edit_business', 'post', data).then((res)=>{
        if (res.status === 'Success') {
          dispatch(fetchUserDetails())
          // dispatch({
          //   type: SET_BUSINESS,
          //   business_det: res.data.business_det,
          //   signupStatus: res.data.signupStatus
          // });
          resolve(true)
        }else{
          reject(false)
        }
      })
    })
  })
}
const DeleteBusiness = (data)=>{
  return(dispatch=>{
    return new Promise((resolve,reject) => {
      return callApi('delete_business', 'post', data).then((res)=>{
        if (res.status === 'Success') {
          dispatch(fetchUserDetails())
          resolve(true)
        }else{
          reject(false)
        }
      })
    })
  })
}

const fetchDeviceInBusiness = (data)=>{
  return(dispatch=>{
    return callApi('fetch_devices', 'post', data).then((res)=>{
      if (res.status === 'Success') {
        dispatch({
          type: FETCH_DEVICES,
          devicesInBusiness: res.data
        });
      }
    })
  })
}
const fetchDeviceById = (data)=>{
  return(dispatch=>{
    return callApi('fetch_device', 'post', data).then((res)=>{
      if (res.status === 'Success') {
        dispatch({
          type: FETCH_DEVICE,
          device_det: res.data
        });
      }
    })
  })
}
const AddProject = (data)=>{
  return(dispatch=>{
    return new Promise((resolve,reject)=>{
      return callApi('add_project', 'post', data).then((res)=>{
        if (res.status === 'Success') {
          dispatch(fetchUserDetails())
          dispatch(FetchProjectsOfUser())
          resolve(true)
        }else{
          reject(false)
        }
      })
    })
  })
}
const EditProject = (data)=>{
  return(dispatch)=>{
    return new Promise((resolve, reject)=>{
      return callApi('edit_project','post', data).then((res)=>{
        if (res.status === 'Success') {
          dispatch(fetchUserDetails())
          resolve(true)
        }else{
          reject(false)
        }
      })
    })
  }
}
const DeleteProject = (data)=>{
  return(dispatch)=>{
    return new Promise((resolve, reject)=>{
      return callApi('delete_project','post', data).then((res)=>{
        if (res.status === 'Success') {
          dispatch(fetchUserDetails())
          dispatch(FetchProjectsOfUser())
          resolve(true)
        }else{
          reject(false)
        }
      })
    })
  }
}
const AddSensor = (data)=>{
  return(dispatch=>{
    return new Promise((resolve,reject)=>{
      return callApi('add_sensor', 'post', data).then((res)=>{
        if (res.status === 'Success') {
          dispatch(fetchDeviceById({_id:data.device_id}))
          resolve(res.data)
        }else{
          reject(false)
        }
      })
    })
  })
}
const AddEditDevice = (data)=>{
  return(dispatch=>{
    return callApi('edit_device', 'post', data).then((res)=>{
      if (res.status === 'Success') {
        dispatch(fetchUserDetails())
        dispatch({
          type: ADD_EDIT_DEVICE,
          device_updated: true,
          device_det: res.data
        });
      }else{
        dispatch({
          type: ADD_EDIT_DEVICE,
          device_updated: false,
        });
      }
    })
  })
}

const DeleteDevice = (data)=>{
  return new Promise((resolve,reject)=>{
    return callApi('delete_device', 'post', data).then((res)=>{
      if(res.status === 'Success'){
        resolve(true)
      }else{
        reject(false)
      }
    })
  })
}

const updateDeviceVerifiedState = (state) =>{
  return (dispatch=>{
    dispatch({
      type: VERIFY_DEVICE,
      isDeviceVerified: state,
      message: ""
    })
  })
}

const VerifyMultiDevices = (data)=>{
  return dispatch=>{
    return new Promise((resolve,reject)=>{
      return callApi('verify_multi_device', 'post', data).then((res)=>{
        if (res.status === 'Success') {
          dispatch(sendOtp({serial_no:data.serial_nos}))
          resolve(res.data)
        }else{
          reject(res.data)
        }
      })
    })
  }
 
}
const FetchProjectsOfUser = (populateFields = true)=>{
  return(dispatch=>{
    let data = {
      query : {},
      populateFields
    }
    data.query[`${storageService.get(configVariables.USER_ROLE)}_id`] = storageService.get(configVariables.USER_ID)
    return callApi('fetch_projects', 'post', data).then((res)=>{
      if (res.status === 'Success') {
        dispatch({
          type: FETCH_PROJECTS,
          projects: res.data
        });
      }else{
        dispatch({
          type: FETCH_PROJECTS,
          projects: []
        });
      }
    })
  })
 
}
const AddGraph = (data)=>{
  return(dispatch=>{
    return new Promise((resolve,reject)=>{
      return callApi('add_graph', 'post', data).then((res)=>{
        if (res.status === 'Success') {
          dispatch({
            type: ADD_GRAPH,
            graph_added: true
          });
          resolve({graph_added: true})
        }else{
          dispatch({
            type: ADD_GRAPH,
            graph_added: false
          });
          reject({graph_added: false})
        }
      })
    })
  })
 
}
const FetchGraphs = ()=>{
  return(dispatch=>{
    let data = {
      user_id: storageService.get(configVariables.USER_ID),
      user_role: storageService.get(configVariables.USER_ROLE)
    }
    return callApi('fetch_graph', 'post', data).then((res)=>{
      if (res.status === 'Success') {
        dispatch({
          type: FETCH_GRAPH,
          graph_det: res.data.graph_det,
          disabled_graph_det: res.data.disabled_graph_det 
        });
      }else{
        dispatch({
          type: FETCH_GRAPH,
          graph_det: [],
          disabled_graph_det: []
        });
      }
    })
  })
 
}
const FetchGraphData = (data)=>{
  return new Promise((resolve,reject)=>{
    let query = {}
    if(data.metaquery){
      query = {
        metaquery : {sensor_no: data.metaquery.sensor_no,topic: data.metaquery.topic},
      }
      
    }
    if(data.timequery){
      query.timequery = data.timequery
      // query.timequery=data.sensor_no
    }
    if(data.selectquery){
      query.selectquery = data.selectquery 
    }
    if(data.limitquery){
      query.limitquery= data.limitquery
    }
    if(data.skipquery){
      query.skipquery= data.skipquery
    }
    callApi('fetch_data', 'post', query, true).then((res)=>{
      if (res.status === 'Success') {
        resolve(res.data)
      }else{
        reject("An error occured")
      }
    })
  })
 
}

const DisableGraph = (data)=>{
  return new Promise((resolve, reject)=>{
    data.user_id = storageService.get(configVariables.USER_ID)
    return callApi('disable_graph','post', data).then((res)=>{
      if (res.status === 'Success') {
        
        resolve(res.data)
      }else{
        reject("An error occured")
      }
    })
  })
}
const EditGraph = (data)=>{
  return new Promise((resolve, reject)=>{
    return callApi('edit_graph','post', data).then((res)=>{
      if (res.status === 'Success') {
        resolve()
      }else{
        reject("An error occured")
      }
    })
  })
}
const DeleteGraph = (data)=>{
  return new Promise((resolve, reject)=>{
    return callApi('delete_graph','post', data).then((res)=>{
      if (res.status === 'Success') {
        resolve()
      }else{
        reject("An error occured")
      }
    })
  })
}

// reg keys actions

const FetchRegistrationKeys = (data)=>{
  return(dispatch=>{
    data = {owner_id: storageService.get(configVariables.USER_ID)}
    return callApi('fetch_keys', 'post', data).then((res)=>{
      if (res.status === 'Success') {
        dispatch({
          type: FETCH_KEYS,
          reg_keys: res.data
        });
      }else{
        dispatch({
          type: FETCH_KEYS,
          reg_keys: []
        });
      }
    })
  })
}
const CreateRegistrationKey = (data)=>{
  return (dispatch)=>{
    return new Promise((resolve, reject)=>{
      data.owner_id = storageService.get(configVariables.USER_ID)
      return callApi('create_key', 'post', data).then((res)=>{
        if (res.status === 'Success') {
          dispatch(FetchRegistrationKeys())
          resolve(res.data)
        }else{
          reject(false)
        }
      })
    })
  }
}

// Supervisor
const DeleteSuper = (data)=>{
  return new Promise((resolve, reject)=>{
    return callApi('delete_super','post', data).then((res)=>{
      if (res.status === 'Success') {
        resolve(true)
      }else{
        reject(false)
      }
    })
  })
}

const OpenMobileNav = (state)=>{
  return (dispatch)=>{
    dispatch({
      type:OPEN_CLSOE_NAV,
      nav_state:state
    })
  }
}

// Notifications

const FetchNotifications = (data)=>{
  return (dispatch)=>{
    return callApi("fetch_notifications",'post',data).then((res)=>{
      if(res.status === 'Success'){
        dispatch({
          type:FETCH_NOTIFICATIONS,
          notifications: res.data
        })
      }else{
        dispatch({
          type:FETCH_NOTIFICATIONS,
          notifications: []
        })
      }
    })
  }
}

const AddNotification = (data)=>{
  return new Promise((resolve,reject)=>{
    return callApi("add_notification",'post',data).then((res)=>{
      if(res.status === 'Success'){
        resolve(true)
      }else{
        reject(false)
      }
    })
  })
}

const EditNotification = (data)=>{
  return new Promise((resolve,reject)=>{
    return callApi("edit_notification",'post',data).then((res)=>{
      if(res.status === 'Success'){
        resolve(true)
      }else{
        reject(false)
      }
    })
  })
}
const DeleteNotification = (data)=>{
  return new Promise((resolve,reject)=>{
    return callApi("delete_notification",'post',data).then((res)=>{
      if(res.status === 'Success'){
        resolve(true)
      }else{
        reject(false)
      }
    })
  })
}

// Notifications end

const FetchSerialNos = ()=>{
  return new Promise((resolve,reject)=>{
    return callApi("fetch_serial",'post',{}).then((res)=>{
      if(res.status === 'Success'){
        resolve(res.data)
      }else{
        reject(false)
      }
    })
  })
}

const ShareGraph = (data)=>{
  return new Promise((resolve,reject)=>{
    return callApi("share_graph",'post',data).then((res)=>{
      if(res.status === 'Success'){
        resolve(res.data)
      }else{
        reject(false)
      }
    })
  })
}

const ResetState = ()=>{
  return (dispatch)=>{
    dispatch({
      type:RESET_STATE,
    })
  }
}

const publishData = (data)=>{
  return new Promise((resolve,reject)=>{
    return callApi("send_data",'post',data, true).then((res)=>{
      if(res.status === 'Success'){
        FetchSubscription()
        resolve("Success")
      }else{
        reject('Unable to update please try again later !')
      }
    }).catch(err=>{
      reject(err)
    })
  })
}

const FetchSubscription = ()=>{
  return (dispatch)=>{
    return callApi("fetch_subscription",'post',{userId: storageService.get(configVariables.USER_ID)}).then((res)=>{
      if(res.status === 'Success'){
        dispatch({
          type:FETCH_SUBSCRIPTION,
          subscription: res.data
        })
      }else{
        dispatch({
          type:FETCH_SUBSCRIPTION,
          subscription: []
        })
      }
    })
  }
}

const fetchTypes = ()=>{
  return (dispatch)=>{
    return callApi("fetch_types",'post').then((res)=>{
      if(res.status === 'Success'){
        dispatch({
          type:FETCH_TYPES,
          types: res.data
        })
      }else{
        dispatch({
          type:FETCH_TYPES,
          types: []
        })
      }
    })
  }
}


const SubscribeUser = (userId, subscription) => {
  return new Promise((resolve, reject) => {
    // Define the data you want to send
    const data = {
      userId,
      subscription,
    };
   
    

    // Call your API to subscribe the user
    return callApi('subscribe_notification', 'post', data).then((res) => {
      if (res.status === 'Success') {
        resolve({ status: true });
      } else {
        resolve({ status: false});
      }
    }).catch((error) => {
      // Handle any errors from the API call
      console.error('Error during subscription:', error);
      reject({ status: false, message: 'Failed to subscribe user' });
    });
  });
};

const UnSubscribeUser = (userId, subscription) => {
  return new Promise((resolve, reject) => {
    // Define the data you want to send
    const data = {
      userId,
      subscription,
    };
   
    

    // Call your API to subscribe the user
    return callApi('unsubscribe_notification', 'post', data).then((res) => {
      if (res.status === 'Success') {
        resolve({ status: true });
      } else {
        resolve({ status: false});
      }
    }).catch((error) => {
      // Handle any errors from the API call
      console.error('Error during subscription:', error);
      reject({ status: false, message: 'Failed to subscribe user' });
    });
  });
};


export {
  signupBusinessUser,
  sendOtp,
  verifyDevice,
  signupNormalUsers,
  LoginUser,
  fetchUserDetails,
  FetchUsersInBusiness,
  AddBusiness,
  EditBusiness,
  DeleteBusiness,
  fetchDeviceInBusiness,
  AddProject,
  EditProject,
  DeleteProject,
  AddEditDevice,
  DeleteDevice,
  updateDeviceVerifiedState,
  VerifyMultiDevices,
  FetchProjectsOfUser,
  AddGraph,
  FetchGraphs,
  FetchGraphData,
  fetchDeviceById,
  AddSensor,
  DisableGraph,
  EditGraph,
  DeleteGraph,
  FetchRegistrationKeys,
  CreateRegistrationKey,
  DeleteSuper,
  OpenMobileNav,
  FetchNotifications,
  AddNotification,
  EditNotification,
  DeleteNotification,
  FetchSerialNos,
  ShareGraph,
  ResetState,
  publishData,
  FetchSubscription,
  fetchTypes,
  SubscribeUser,
  UnSubscribeUser
}