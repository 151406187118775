import { json2csv } from 'json-2-csv';
import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeviceInBusiness, FetchSerialNos } from '../../appActions';
import add_logo from '../../Assets/Icons/add.png'
import callApi from '../../util/callApi';
import './Admin.css'

function Admin() {

  const dispatch = useDispatch()
  const state = useSelector((state) => state);

  useEffect(() => {
    if(!state.devicesInBusiness){
      dispatch(fetchDeviceInBusiness({selectFrom:'topic phone serial_no'}))
    }
  
  }, [])

  const handleSerialNoDownload = ()=>{
    FetchSerialNos().then(res=>{
      const fields = ['Serial No', 'Client Id', 'Time'];
      const opts = { fields };
      json2csv(JSON.parse(JSON.stringify(res)), (err, csv) => {
        const url = window.URL.createObjectURL(new Blob([csv])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'serial_nos.csv')
        document.body.appendChild(link)
        link.click()
      })
    })
  }

  const handleAddDevice = (e,noOfDevices = 1)=>{
    callApi('add_device','post',{count:noOfDevices}).then(res=>{
      if(res.status === 'Success'){
        alert('Device added successfully!');
        dispatch(fetchDeviceInBusiness({selectFrom:'topic phone serial_no'}))
      }else{
        alert('Error occurred while adding device! Please try again')
      }
    }).catch(err=>{
      alert(err)
    })
  }
  

  return (
    <div className='admin-top biz-top containor'>
      <div className="biz-header noti-header">
        <h1>Devices</h1>
        <div className="report-data-opts-top">
          <div onClick={()=>{}} className="graph-header-widget noti-add-but ptr">
            <p onClick={handleSerialNoDownload}>Download Serial No's</p>
          </div>
          <div onClick={()=>{}} className="graph-header-widget noti-add-but ptr">
            <p onClick={handleAddDevice}>Add Device</p>
          </div>
          <div onClick={()=>{}} className="graph-header-widget noti-add-but ptr">
            <p onClick={()=>{handleAddDevice(10)}}>Add 10 Devices</p>
          </div>
        </div>
      </div>
      <div className="device-table-top">
        <div className="device-table">
          <div className="table-row">
            <p>SI No</p>
            <p>Client Id</p>
            <p>Serial No</p>
            <p>User Phone No</p>
          </div>
          {state?.devicesInBusiness?.map((device,i)=>(
            <div className="table-row">
              <p>{i+1}</p>
              <p>{device.topic}</p>
              <p>{device.serial_no}</p>
              <p>{device.phone}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Admin