import React, { useRef } from 'react'
import Select from 'react-select'
import './Report.css'
import '../../Components/Form.css'
import down_arrow_logo from '../../Assets/Icons/down.png'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { useEffect } from 'react'
import { FetchGraphData, FetchProjectsOfUser } from '../../appActions'
import { useForm } from 'react-hook-form'
import { json2csv } from 'json-2-csv'
import { graphTypes } from '../../util/constants'
import Chart from '../../Components/Chart'
import ExcelJS from 'exceljs';
import Header from '../dashboardheader/header'
import useMediaQuery from '@mui/material/useMediaQuery';
import { backdropClasses } from '@mui/material'
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';


const customStyles = {
  control: (provided, state) => ({
    ...provided,
    
    backgroundColor: 'rgba(47, 47, 47, 1)',
    boxShadow: '0px 7.2px 10.8px 5.4px rgba(0, 0, 0, 0.15), 0px 3.6px 3.6px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',
    border: 'none',
    color: 'white',
    height: '50px',
    padding: '10px',
    width: '100%',
    gridArea: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px'
    
  }),
  menu: (provided) => ({
    ...provided,
    background:'rgba( 76, 76, 76, 0.65 )',
    backdropFilter:'blur( 9.5px )',
    borderRadius: '10px',
    border: '1px solid rgba( 255, 255, 255, 0.18 )',
    // backgroundColor: '#4a4948',
    fontSize:'12px',
    
    // border:'1px solid #7469B6',
    color: 'white',

  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '' : 'transparent',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      
    },
  }),
  
  placeholder: (provided) => ({
    ...provided,
    color: 'white',
  }),
};

function Report() {
  // redux hooks
  const dispatch = useDispatch()
  const state = useSelector((state) => state);
  const isMobile = useMediaQuery('(max-width: 766px)');

  // react states
  const [report_data, setReportData] = useState([])
  const pageSize = 50
  const [pageNo, setPageNo] = useState(0)
  const [showDropdown, setShowDropdown] = useState(false)
  const [deviceOptions, setDeviceOptions] = useState([])
  const [sensorOptions, setSensorOptions] = useState([])
  const [selectedSensor, setselectedSensor] = useState(null)
  const [selectedProject, setselectedProject] = useState(null)
  const [selectedDevice, setselectedDevice] = useState(null)
  const [selectedGraph, setselectedGraph] = useState(null)
  const [generatedGraph, setGeneratedGraph] = useState(null)
  const [deviceAndSensor, setDeviceAndSensor] = useState({})
  const [DownloadData, setDownloadData] = useState(null)
  const dropdownRef = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  
  

  // re render handling
  useEffect(() => {
    if (!state.projects_det) {
      dispatch(FetchProjectsOfUser())
    }
    window.addEventListener("click", handleClickOutside);
    return () => {
      // Remove event listener when component unmounts
      window.removeEventListener("click", handleClickOutside);
    };
  }, [])

  useEffect(() => {
    if (report_data.length > 0 && pageNo >= 0) {
      handleSubmit(onSubmitFilter)()
    }
  }, [pageNo])

  // form hooks
  const { register, handleSubmit, reset, getValues ,setValue} = useForm();

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {

      // Click was outside the dropdown, so close the dropdown
      setShowDropdown(false);
    }
  }
  const handleStartDateChange = (date) => {
    setStartDate(date);
    setValue('start_date', date); // Update the form value using setValue
  };

  // Handle changes for End Date
  const handleEndDateChange = (date) => {
    setEndDate(date);
    setValue('end_date', date); // Update the form value using setValue
  };

  // functions
  const onSubmitFilter = async (data) => {
    const device = data.device
    const sensor = data.sensor
    if (device !== 'null' && sensor !== 'null' && ((data.start_date && data.end_date) || data.filter_interval)) {
      setGeneratedGraph(false)
      const data_res = await FetchGraphData({
        metaquery: { sensor_no: sensor, topic: device },
        timequery: { start_date: data.start_date, end_date: data.end_date },
        limitquery: pageSize,
        skipquery: pageSize * pageNo
      })
      let obj = {}
      obj.sensor_name = getObjectById(sensorOptions, sensor, 'sensor_no').name
      obj.device_name = getObjectById(deviceOptions, device, 'topic').name
      setDeviceAndSensor(obj)
      setReportData(data_res)
    } else {
      alert("Please Select a sensor !")
    }
  }

  const onSubmitGraph = (data) => {
    try {
      if (selectedSensor && data.type !== "null" && data.start_date && data.end_date &&selectedGraph ) {
        if ( data.start_date > data.end_date || data.end_date ===data.start_date) {
          alert("Start date must be earlier than end date.");
          return;
        }
        let endDate = new Date(getValues('end_date'));
        let startDate = new Date(getValues('start_date'));
        data.start_date=startDate
        endDate.setDate(endDate.getDate() + 1);
        data.end_date=endDate
        setGeneratedGraph(
          <Chart
            key={Date.now()}
            type={data.type}
            name={selectedDevice.name}
            data_query={{ sensor_no: selectedSensor.sensor_no, topic: selectedDevice.topic, limit: 20, timequery: data }}
            graph_det={{ sensor_id: selectedSensor, name: `${selectedDevice.name}/${selectedSensor.sensor_no}` }}
            report_graph
          />
        )
      
       
      } else {
        alert("Please fill all fields")
      }
    } catch (err) {
      alert('Please slect appropriate graph type!')
    }
  }

  const handlereset = () => {
    setselectedSensor({})
    reset()
  }

  const getDateRange = (exp) => {
    let query_date = new Date()

    if (exp === 'day') {
      query_date.setDate(query_date.getDate() - 1)
    } else if (exp === 'week') {
      query_date.setDate(query_date.getDate() - 7)
    } else if (exp === 'month') {
      query_date.setDate(query_date.getMonth() - 1)
    }
    return { start_date: query_date, end_date: new Date() }
  }

  const fetchDownloadData = async () => {
    let data;
    let metadata;
    let endDate = new Date(getValues('end_date'));
    endDate.setDate(endDate.getDate() + 1);

    if (getValues('start_date') && getValues('end_date')) {
      data = { start_date: getValues('start_date'), end_date: endDate }
      metadata={sensor_no:selectedSensor.sensor_no,topic:selectedDevice.topic}
    } else {
      alert('Please select a time range!')
      return
    }
    const data_res = await FetchGraphData({
      metaquery:metadata,
      timequery: data,
      selectquery: 'metafields',
      limitquery: 0,
    })
    const reversed_data_res = data_res.reverse();
    const fields = ['Time', 'Value', 'Sensor', 'Device'];
    const opts = { fields };
    const formatted_data = reversed_data_res.map(row => (
      {
        "Time": row.datetime,
        "Sensor Name": selectedSensor.name,
        "Device Name": selectedDevice.name,
        "Value": row.value,
      }
    ))
    setDownloadData(formatted_data)
    return formatted_data
  }

  const generateWorksheetColumns = (sensorNo) => {
    
    switch (sensorNo) {
      case 'FY':
        return [
          { header: 'Time', key: 'Time', width: 30 },
          { header: 'Sensor Name', key: 'Sensor Name', width: 20 },
          { header: 'Device Name', key: 'Device Name', width: 20 },
          { header: 'Pitch', key: 'Pitch', width: 10 },
          { header: 'Roll', key: 'Roll', width: 10 },
          { header: 'Altitude', key: 'Altitude', width: 10 }
        ];
      case 'DR':
        return [
          { header: 'Time', key: 'Time', width: 30 },
          { header: 'Sensor Name', key: 'Sensor Name', width: 20 },
          { header: 'Device Name', key: 'Device Name', width: 20 },
          { header: 'Navigation', key: 'Navigation', width: 10 },
          { header: 'Voltage', key: 'Voltage', width: 10 },
          { header: 'Ampere', key: 'Ampere', width: 10 },
          { header: 'Airspeed', key: 'Airspeed', width: 10 },
          { header: 'Groundspeed', key: 'Groundspeed', width: 10 }
        ];
        case 'MG':
          return [
            { header: 'Time', key: 'Time', width: 30 },
            { header: 'Sensor Name', key: 'Sensor Name', width: 20 },
            { header: 'Device Name', key: 'Device Name', width: 20 },
            { header: 'Message', key: 'Message', width: 40 },
            
          ];
          case 'FM':
            return [
              { header: 'Time', key: 'Time', width: 30 },
              { header: 'Sensor Name', key: 'Sensor Name', width: 20 },
              { header: 'Device Name', key: 'Device Name', width: 20 },
              { header: 'Gps', key: 'Gps', width: 10 },
              
            ];
            case 'AR' :
            case 'AR_INP':
            return [
              { header: 'Time', key: 'Time', width: 30 },
              { header: 'Sensor Name', key: 'Sensor Name', width: 20 },
              { header: 'Device Name', key: 'Device Name', width: 20 },
              { header: 'RTL', key: 'RTL', width: 10 },
              { header: 'ARM', key: 'ARM', width: 10 },
              { header: 'DISARM', key: 'DISARM', width: 10 },


              
            ];
            
      default:
        // Default columns (adjust as needed)
        return [
          { header: 'Time', key: 'Time', width: 30 },
          { header: 'Sensor Name', key: 'Sensor Name', width: 20 },
          { header: 'Device Name', key: 'Device Name', width: 20 },
          { header: 'Value', key: 'Value', width: 10 }
        ];
    }
  };

  const generateRowData = (item, sensorNo) => {
    switch (sensorNo) {
      case 'FY':
        const parts0 = item.Value.split('+');
        return {
          Time: item.Time,
          'Sensor Name': item['Sensor Name'],
          'Device Name': item['Device Name'],
          'Pitch': parts0[1],
          'Roll': parts0[3],
          'Altitude': parts0[4]
        };
      case 'DR':
        const parts1 = item.Value.split('+');
        return {
          Time: item.Time,
          'Sensor Name': item['Sensor Name'],
          'Device Name': item['Device Name'],
          'Navigation': parts1[1],
          'Voltage': parts1[2],
          'Ampere': parts1[3],
          'Airspeed': parts1[4],
          'Groundspeed': parts1[5]
        };
        case 'MG':
          const parts2 = item.Value.replace(/\+/g, ',');
          return {
            Time: item.Time,
            'Sensor Name': item['Sensor Name'],
            'Device Name': item['Device Name'],
            'Message': parts2,
            
          };
          case 'FM':

            const parts3 = item.Value.replace(/\+/g, ',');
            return {
              
              Time: item.Time,
              'Sensor Name': item['Sensor Name'],
              'Device Name': item['Device Name'],
              'Gps': parts3
              
            };
          case 'AR':
          case 'AR_INP':
          const parts4 = item.Value.split('+');
          return {
            Time: item.Time,
            'Sensor Name': item['Sensor Name'],
            'Device Name': item['Device Name'],
            'RTL': parts4[0],
            'ARM':parts4[1]
            
          };
      default:
        // Default row data (adjust as needed)
        return {
          Time: item.Time,
          'Sensor Name': item['Sensor Name'],
          'Device Name': item['Device Name'],
          'Value': item.Value
        };
    }
  };

  const generateCsvData = (data, selectedSensor) => {
    let csv = '';
  
    // Define headers based on selected sensor
    switch (selectedSensor) {
      case 'FY':
        csv += 'Time,Sensor Name,Device Name,Pitch,Roll,Altitude\n';
        data.forEach(item => {
          const parts = item.Value.split('+');
          csv += `${item.Time},${item['Sensor Name']},${item['Device Name']},${parts[1]},${parts[3]},${parts[4]}\n`;
        });
        break;
      case 'DR':
        csv += 'Time,Sensor Name,Device Name,Navigation,Voltage,Ampere,Airspeed,Groundspeed\n';
        data.forEach(item => {
          const parts = item.Value.split('+');
          csv += `${item.Time},${item['Sensor Name']},${item['Device Name']},${parts[1]},${parts[2]},${parts[3]},${parts[4]},${parts[5]}\n`;
        });
        break;
      case 'MG':
        csv += 'Time,Sensor Name,Device Name,Message\n';
        data.forEach(item => {
          const parts = item.Value.replace(/\+/g, ',');
          csv += `${item.Time},${item['Sensor Name']},${item['Device Name']},${parts}\n`;
        });
        break;
      case 'FM':
        csv += 'Time,Sensor Name,Device Name,Gps\n';
        data.forEach(item => {
          const parts = item.Value.replace(/\+/g, ',');
          csv += `${item.Time},${item['Sensor Name']},${item['Device Name']},${parts}\n`;
        });
        break;
      case 'AR':
      case 'AR_INP':
        csv += 'Time,Sensor Name,Device Name,RTL,ARM,DISARM\n';
        data.forEach(item => {
          const parts = item.Value.split('+');
          csv += `${item.Time},${item['Sensor Name']},${item['Device Name']},${parts[0]},${parts[1]},${parts[2]}\n`;
        });
        break;
      default:
        // Default CSV format (adjust as needed)
        csv += 'Time,Sensor Name,Device Name,Value\n';
        data.forEach(item => {
          csv += `${item.Time},${item['Sensor Name']},${item['Device Name']},${item.Value}\n`;
        });
        break;
    }
  
    return csv;
  };
  
  const handleCSVDownload = async () => {
    const values = getValues();
  
    if ( values.start_date > values.end_date || values.end_date ===values.start_date) {
      alert("Start date must be earlier than end date.");
      return;
    }

    const data = await fetchDownloadData();
    var csv = generateCsvData(data, selectedSensor.type.code);
  
    // Download CSV file
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${selectedProject.name}_${selectedSensor.name}.csv`);
    document.body.appendChild(link);
    link.click();
  };
  
  

  const handleExcelDownload = async () => {
    const values = getValues();
    if ( values.start_date > values.end_date || values.end_date ===values.start_date) {
      alert("Start date must be earlier than end date.");
      return;
    }
    const data = await fetchDownloadData();
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('My Sheet');
    var sensorColumns = generateWorksheetColumns(selectedSensor.type.code);



    // Define columns with widths based on selected sensor
    worksheet.columns = sensorColumns;
    // Add data rows based on selected sensor
    data.forEach(item => {
    var rowData = generateRowData(item, selectedSensor.type.code);
    

      worksheet.addRow(rowData);
    });
  
    // Save on the client side
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${selectedProject.name}_${selectedSensor.name}.xlsx`);
    document.body.appendChild(link);
    link.click();
  };

  const getObjectById = (arr, id, field) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][field] === id) {
        return arr[i];
      }
    }
  }

  
  const handleProjectSelect = (selectedOption) => {
    if (selectedOption) {
      let project_det = getObjectById(state?.projects_det, selectedOption.value, '_id')
      setDeviceOptions(project_det.device_id)
      setselectedProject(project_det)
    }
  }
  const handleDeviceSelect = (selectedOption) => {
    if (selectedOption) {
      let device_det = getObjectById(deviceOptions, selectedOption.value, 'topic')
      setSensorOptions(device_det.sensor_id)
      setselectedDevice(device_det)
    }
  }
  const handleSensorSelect = (selectedOption) => {
    if (selectedOption) {
      let sensor_det = getObjectById(sensorOptions, selectedOption.value, 'sensor_no')
      setselectedSensor(sensor_det)
    }
  }
  const handleGraphselect = (selectedOption) => {
    if (selectedOption) {
      setselectedGraph(selectedOption.value)
      setValue('type', selectedOption.value)
    }
  }
  
  const projectOptions = state?.projects_det?.map(project => ({ value: project._id, label: project.name }));
  const deviceOptionsFormatted = deviceOptions?.map(device => ({ value: device.topic, label: device.name }));
  const sensorOptionsFormatted = sensorOptions?.map(sensor => ({ value: sensor.sensor_no, label: sensor.name }));
  const graphTypeOptions = selectedSensor?.type?.graph_types?.map(graph => ({ value: graph, label: graph }));

  return (
    <div className='report-top containor'>
    {isMobile && <Header />}
   
      <div className="noti-header">
        <h1>Report</h1>
      </div>
      <div className="report-opts-top">
        <div className="report-opt-box-2">
          <div className="report-inps">
            <label className='form-label' htmlFor="">
              Select Project
              <Select
              
              
              styles={customStyles}
              options={projectOptions}
              onChange={handleProjectSelect}
              value={selectedProject?.name ? { value: selectedProject.name, label: selectedProject.name } : null}
            />
            </label>
            <label className='form-label' htmlFor="">
              Select Device
             
              <Select
              
              
              styles={customStyles}
              options={deviceOptionsFormatted}
              onChange={handleDeviceSelect}
              value={selectedDevice?.name ? { value: selectedDevice.name, label: selectedDevice.name } : null}
            />
            </label>
            <label className='form-label' htmlFor="">
              Select Sensor
              <Select
              
              
              styles={customStyles}
              options={sensorOptionsFormatted}
              onChange={handleSensorSelect}
              value={selectedSensor?.name ? { value: selectedSensor.name, label: selectedSensor.name } : null}
            />
            </label>
            <label className='form-label' htmlFor="start_date">
                Start Date
                <Datetime
                key={startDate || 'startDate'} 
                dateFormat="DD-MM-YYYY"  /* Specify the date format */
                timeFormat={false} 
                  onChange={handleStartDateChange}
                  inputProps={{ placeholder: 'Select start date', className: 'input-min-width-95p' }}
                  value={startDate}
                />
                <input type="hidden" {...register('start_date', {})} />
             </label>
            <label className='form-label' htmlFor="end_date">
                End Date
                <Datetime
                key={endDate || 'endDate'} 
                dateFormat="DD-MM-YYYY"  /* Specify the date format */
                timeFormat={false} 
                  onChange={handleEndDateChange}
                  inputProps={{ placeholder: 'Select end date', className: 'input-min-width-95p' }}
                  value={endDate}
                />
                  <input type="hidden" {...register('end_date', {})} className='dateinp'/>
            </label>
                    
                    
           <label className='form-label' htmlFor="">
              Select Graph Type
              <Select
              
                 options={graphTypeOptions}
                 onChange={handleGraphselect}
                 value={selectedGraph ? { value: selectedGraph, label: selectedGraph } : null}
                 styles={customStyles}
               />
            </label>
          </div>
          {/* <label className='form-label' htmlFor="">
              Custom Interval
              <select  {...register('filter_interval', {})}>
                <option value="null" defaultValue={null}>Selectt</option>
                <option value="day">Last day</option>
                <option value="week">Last Week</option>
                <option value="month">Last Month</option>
              </select>
            </label> */}
          <div className="report-but-grp">
            <div className="form-but-group">
              {/* <button className='form-but-1' role='button' onClick={handleSubmit(onSubmitFilter)}>Filter</button> */}
              <button className='form-but-1' role='button' onClick={handleSubmit(onSubmitGraph)}>Generate Graph</button>
              {/* <button className='form-but-2 reset-but' role='button' onClick={handlereset}>Reset</button> */}
            </div>
            <button className="form-but-1 report-dropdown" ref={dropdownRef}>
              {/* <p onClick={() => { setShowDropdown(!showDropdown) }}>Download <img src={down_arrow_logo} alt="" /></p> */}
              Download
              <div className="report-dropdown-content navbar-dropdown">
                <p onClick={() => { handleCSVDownload() }}>CSV</p>
                <p onClick={() => { handleExcelDownload() }}>Excel</p>
              </div>
            </button>
          </div>
        </div>
      </div>
      { }
      <div className="report-data-top">

        {generatedGraph ?
          generatedGraph
          :
          report_data.length > 0 &&
          <div className='report-data-box'>
            <>
              <div className="report-data-header">
                <p className="header-item">Si.No</p>
                <p className="header-item">Time</p>
                <p className="header-item">Value</p>
                <p className="header-item">Sensor</p>
                <p className="header-item">Device</p>
              </div>
              <div className="report-data">
                {report_data.map((data, i) =>
                  <div className="report-data-row">
                    <p className="header-item">{i + 1}</p>
                    <p className="header-item">{data.datetime}</p>
                    <p className="header-item">{data.value}</p>
                    <p className="header-item">{deviceAndSensor.sensor_name}</p>
                    <p className="header-item">{deviceAndSensor.device_name}</p>
                  </div>
                )}
              </div>
            </>
            {(report_data.length > 0 && !generatedGraph) &&
              <div className="page-nav-box">
                {pageNo !== 0 && <p onClick={() => { setPageNo(pageNo - 1) }}>Prev </p>}
                <p> | </p>
                {report_data.length >= pageSize && <p onClick={() => { setPageNo(pageNo + 1) }}> Next</p>}
              </div>
            }
          </div>
        }

      </div>
    </div>
  )
}

export default Report