import React, { useEffect, useState } from 'react';
import Notifications from 'react-notifications-menu';
import noti_logo from '../../Assets/Icons/noti_white.png';
import './Notification.css';
import callApi from '../../util/callApi';
import call_logo from '../../Assets/Icons/call-chat-svgrepo-com.svg';
import mail_logo from '../../Assets/Icons/mail-plus-svgrepo-com.svg';
import smslogo from '../../Assets/Icons/message-square-lines-alt-svgrepo-com (1).svg';

const icons = {
  'call': call_logo,
  'sms': smslogo,
  'email': mail_logo
};

function Notification() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchAlerts();
  }, []);

  const fetchAlerts = async () => {
    try {
      const alerts = await callApi('fetch_alerts', 'get');
      const formattedAlerts = alerts.data.map(alert => ({
        key: alert.id, // Ensure `alert.id` is a unique identifier
        image: icons[alert.type],
        message: `
          ${alert.message} -
          ${new Date(alert.time).toLocaleString()}
        `,
      }));
      setData(formattedAlerts);
    } catch (error) {
      console.error('Error fetching alerts:', error);
    }
  };

  return (
    <Notifications
      data={data}
      header={{
        title: 'Notifications',
        option: { onClick: () => {} }
      }}
      icon={noti_logo}
      renderNotification={(notification) => (
        <div
          key={notification.key} // Ensure key is unique
          dangerouslySetInnerHTML={{ __html: notification.message }}
          style={{ whiteSpace: 'pre-wrap' }} // Ensure line breaks are respected
        />
      )}
    />
  );
}

export default Notification;
