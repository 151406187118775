


import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-curve';
import './FlightMap.css'; // Make sure to create this file for custom styles
import { map } from 'lodash';

// Custom blue dot icon with transparent circle
const blueDotIcon = new L.DivIcon({
  html: '<div class="blue-dot"></div>',
  className: 'custom-blue-dot-icon',
  iconSize: [20, 20],
  iconAnchor: [10, 10] // Center the icon
});

const CurvedPolyline = ({ positions }) => {
  
  const map = useMap();

  useEffect(() => {
    if (positions.length > 1) {
      const path = [];
      for (let i = 0; i < positions.length - 1; i++) {
        path.push('M', positions[i], 'Q', positions[i], positions[i + 1]);
      }
      const curve = L.curve(path, { color: 'blue' });
      curve.addTo(map);

      return () => {
        map.removeLayer(curve);
      };
    }
  }, [map, positions]);

  return null;
};

function FlightMap({ data }) {
  if (data?.length > 0) {
    // Extract positions for markers and polyline
    const positions = data.map(point => point.value.split('+').map(Number));

    return (
      <MapContainer style={{ borderRadius: '5px', height: '500px', width: '100%' }} center={positions[0]} zoom={13} scrollWheelZoom={true} className='secondmap'>
        {/* <TileLayer
          attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
          maxZoom={20}
        /> */}
        <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        maxZoom={30}
      />
        {positions.map((position, index) => (
          <Marker key={index} position={position} icon={blueDotIcon}>
            <Popup>
                <div style={{ textAlign: 'center' }}>
                  <h3>Point {index + 1}</h3>
                  <p>Latitude: {position[0]}</p>
                  <p>Longitude: {position[1]}</p>
                </div>
              </Popup>
          </Marker>
        ))}
        <CurvedPolyline positions={positions} />
      </MapContainer>
    );
  }
  else{
    return <div>no data</div>
  }

  
}

export default FlightMap;

// for testing map
// import React, { useState, useEffect } from 'react';
// import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap } from 'react-leaflet';
// import L from 'leaflet';
// import 'leaflet-curve';
// import './FlightMap.css'; // Make sure to create this file for custom styles

// // Custom blue dot icon with transparent circle
// const blueDotIcon = new L.DivIcon({
//   html: '<div class="blue-dot"></div>',
//   className: 'custom-blue-dot-icon',
//   iconSize: [20, 20],
//   iconAnchor: [10, 10] // Center the icon
// });

// const CurvedPolyline = ({ positions }) => {
//   const map = useMap();

//   useEffect(() => {
//     if (positions.length > 1) {
//       const path = [];
//       for (let i = 0; i < positions.length - 1; i++) {
//         path.push('M', positions[i], 'Q', positions[i], positions[i + 1]);
//       }
//       const curve = L.curve(path, { color: 'blue' });
//       curve.addTo(map);

//       return () => {
//         map.removeLayer(curve);
//       };
//     }
//   }, [map, positions]);

//   return null;
// };

// function FlightMap() {
//   const [markers, setMarkers] = useState([]);
//   const [positions, setPositions] = useState([]);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       const lat = 51.505 + Math.random() / 100; // Random latitude within a small range
//       const lng = -0.09 + Math.random() / 100; // Random longitude within a small range

//       const newMarkers = [...markers, { lat, lng }];
//       setMarkers(newMarkers);
//       setPositions(newMarkers.map(marker => [marker.lat, marker.lng]));
//     }, 2000); // Update every 2 seconds

//     return () => clearInterval(interval);
//   }, [markers]);

//   return (
//     <MapContainer style={{ borderRadius: '5px', height: '500px', width: '100%' }} center={[51.505, -0.09]} zoom={13} scrollWheelZoom={true}>
//       <TileLayer
//         attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
//         url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
//       />
//       {positions.map((position, index) => (
//         <Marker key={index} position={position} icon={blueDotIcon}>
//           <Popup>
//             A pretty CSS3 popup. <br /> Easily customizable.
//           </Popup>
//         </Marker>
//       ))}
//       <CurvedPolyline positions={positions} />
//     </MapContainer>
//   );
// }

// export default FlightMap;
