import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import _ from 'lodash';

// Function to parse datetime string to JavaScript Date
const parseDate = (datetime) => {
  const [time, date] = datetime.split(' ');
  const [day, month, year] = date.split('/').map(Number);
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return new Date(year + 2000, month - 1, day, hours, minutes, seconds);
};

function BarChart({ data, graph_det }) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data) {
      // Parse and format data
      let grped = _.groupBy(data, d => {
        const date = parseDate(d.datetime);
        return date.toISOString();  // Use ISO format for better grouping
      });

      let formattedData = _.map(grped, (value, key) => {
        return {
          x: key,
          y: _.sumBy(value, d => parseFloat(d.value))  // Aggregate values if needed
        };
      });

      // Sort data by date in descending order
      formattedData = formattedData.reverse();

      setChartData(formattedData);
    }
  }, [data]);

  const getColor = (datum) => {
    let color;
    const value = Math.abs(datum.data.y);  // Use absolute value for color determination
    if (value <= graph_det.min_threshold) {
      color = graph_det.colors[0];
    } else if (value <= graph_det.mid_threshold) {
      color = graph_det.colors[1];
    } else if (value >= graph_det.max_threshold) {
      color = graph_det.colors[2];
    } else {
      color = 'tomato';
    }
    return color;
  }

  return (
    <ResponsiveBar
      data={chartData}
      keys={['y']}
      indexBy='x'
      groupMode="grouped"
      enableGridX={false}
      enableGridY={true}
      margin={{ top: 30, right: 60, bottom: 80, left: 60 }}
      colors={getColor}
      valueScale={{ type: "linear" }}
      axisTop={null}
      axisRight={null}
      xScale={{
        type: 'time',
        format: '%Y-%m-%dT%H:%M:%S.%LZ',
        useUTC: false,
      }}
      xFormat="time:%Y-%m-%d %H:%M:%S"
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false  // Ensure bars are not inverted
      }}
      axisBottom={{
        format: value => new Date(value).toLocaleDateString(),
        legendOffset: 36,
        legendPosition: 'middle',
        tickRotation: -45,
        tickSize: 10,
        tickPadding: 5,
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Value',
        legendOffset: -40,
        legendPosition: 'middle',  
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      theme={{
        textColor: 'white',
        axis: {
          tickColor: "white",
          textColor: "white"
        }
      }}
    />
  );
}

export default BarChart;
