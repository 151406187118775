import React, { useEffect } from 'react'
import './Devices.css'
import mail_logo from '../../Assets/Icons/mail.png'
import phone_logo from '../../Assets/Icons/phone.png'
import loc_logo from '../../Assets/Icons/loc.png'
import edit_logo from '../../Assets/Icons/edit.png'
import trash_logo from '../../Assets/Icons/trash.png'
import add_big_logo from '../../Assets/Icons/add_big.png'
import { useDispatch, useSelector } from 'react-redux';
import { DeleteDevice, fetchUserDetails } from '../../appActions'
import { useState } from 'react'
import AddDeviceForm from '../../Components/AddDeviceForm'
import { Link, useParams } from 'react-router-dom'
import configVariables from '../../util/constants'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Header from '../dashboardheader/header'
import useMediaQuery from '@mui/material/useMediaQuery';

function Devices() {

  const state = useSelector((state) => state);
  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width: 766px)');

  const [viewFormModal, setViewFormModal] = useState(false)
  const [editObject, setEditObject] = useState({})

  useEffect(() => {
    if(!state.user_det){
      dispatch(fetchUserDetails())
    }
  }, [])

  const handleEditDevice = (device_det)=>{
    setEditObject(device_det)
  }
  const handleAddDevice = (data)=>{
    setViewFormModal(true)
  }
  const handleDeleteDevice = (device_det)=>{
    let text = "Are you sure you want to delete? All the data including sensors, graphs, etc associated with the device will be deleted!"
    if(window.confirm(text)){
      let data = {
        device_id:device_det._id,
        business_id: device_det.business_id,
        project_id: device_det.project_id
      }
      DeleteDevice(data).then(res=>{
        if(res){
          dispatch(fetchUserDetails())
        }else{
          alert("unable to delete device. Please try again later.")
        }
      })
    }
  }

  return (
    <div className='device-top containor'>
   {isMobile && <Header />}
      <div className="noti-header">
        <h1>Devices</h1>
      </div>
      {state?.user_det?.business_id?.map(business=>
      <div className='biz-box'>
        <div className='det-box'>
          <div className="biz-det device-biz-det">
            <h1>{business.name}</h1>
            <p className='biz-p'><EmailIcon/>{business.email}</p>
            <p className='biz-p'><PhoneIcon/>{business.phone}</p>
            <p className='biz-p'><LocationOnIcon/>{business.address}</p>
          </div>
          <div className="proj-det device-proj-det">
            <h1>Projects :{business.project_id.length}</h1>
            <p className='biz-p'>Admin: {state.user_det.username}</p>
            <h4>Total Devices : {business.device_id.length}</h4>
          </div>
        </div>
        <div className="proj-cards-top"> 
          {business.device_id.map(device=>{
            return(
              <div className="proj-card">
                <div className="proj-card-header">
                  <h1>{device.name}</h1>
                  {state?.user_det?.userRole !== configVariables.role_super &&
                    <div className="proj-card-opts">
                      <div onClick={()=>{handleEditDevice(device)}} className="opt-box ptr">
                        {/* <img src={edit_logo} alt="" /> */}
                        <EditIcon/>
                      </div>
                      <div className="opt-box ptr">
                        <DeleteIcon onClick={() => {
                          if (window.confirm("Are you sure you want to delete this device?")) {
                            handleDeleteDevice(device);
                          }
                        }} />
                        {/* <img src={trash_logo} onClick={() => { handleDeleteDevice(device) }} alt="" /> */}
                      </div>

                    </div>
                  }
                </div>
                <dic className="device-card-footer">
                  <div className="proj-card-cont">
                    <p>Serial No: <span>{device.serial_no}</span></p>
                    {/* <p>Project name: <span>{project.name}</span></p> */}
                    <p>Username : <span>{device?.created_by?.username}</span></p>
                    <p>Role : <span>{device?.created_by?.userRole}</span></p>
                    <p>AC Connect : {device.connection_status ? <span className='green-text'>Online</span>:<span className='red-text'>Offline</span>}</p>
                  </div>
                  <div className="device-card-but">
                    <button className="form-but-2"><Link to={`/manage/devices/sensors/${device?._id}`}>Sensors</Link></button>
                  </div>
                </dic>
              </div>
            )
          })}
          <div onClick={()=>{handleAddDevice(business._id)}} className="proj-card proj-add-card ptr">
            <h1>Add <br /> Device</h1>
            {/* <img src={add_big_logo} alt="" /> */}
            <AddIcon/>
          </div>
        </div>
      </div>  
      )}
      {viewFormModal && <AddDeviceForm business_det={state?.user_det?.business_id} setCloseModal={setViewFormModal}/>}
      {Object.keys(editObject).length !== 0 && 
        <AddDeviceForm 
          editObject={editObject} 
          business_det={state?.user_det?.business_id} 
          setCloseModal={setEditObject}
          mode = 'edit'
        />
      }
    </div>
  )
}

export default Devices