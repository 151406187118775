import React, { useEffect, useState } from 'react'
import './SubscriptionDetails.css'
import { useDispatch, useSelector } from 'react-redux'
import { FetchSubscription } from '../../appActions'
import { useNavigate } from 'react-router-dom'
import callApi from '../../util/callApi'
import ChangePasswordForm from '../../Components/ChangePasswordForm'
import storageService from '../../util/storageService'
import configVariables from '../../util/constants'

function SubscriptionDetails() {

  const state = useSelector((state) => state)
  const dispatch = useDispatch()
  const nav = useNavigate()
  const [changePasswordModal, setChangePasswordModal] = useState(false)

  useEffect(() => {
    if (!state.subscription) {
      dispatch(FetchSubscription())
    }
  }, [])

  const handleCancel = () => {
    if (window.confirm("Are you sure you want to cancel?")) {
      if (state.subscription.user._id) {
        callApi('cancel_subscription', 'post', { userId: state.subscription.user._id }).then(res => {
          if (res.status === 'Success') {
            alert(res.data)
            nav('/pay')
          } else {
            alert("An error occured. Please try again later.")
          }
        })
      } else {
        alert('No active subscriptions!')
      }
    }
  }

  const handlePasswordChange = () => {
    setChangePasswordModal(true)
  }
  return (
    <>
      {/* Main section with subscription details */}
      <div className="main sub-det-main containor">
        <div className="sub-det-card-top">
          <div className="sub-det-card">
            <div className="sub-det-card-head">
              <h2 className="card-title">Profile</h2>
            </div>
            <div className="sub-det-card-cont">
              <p className="card-period">username: <span>{state?.subscription?.user?.username}</span></p>
              <p className="card-period">Email: <span>{state?.subscription?.user?.contact_email}</span></p>
              <p className="card-period">Phone: <span>{state?.subscription?.user?.phone_number}</span></p>
              <p className="card-period">Role: <span>{state?.subscription?.user?.userRole}</span></p>
            </div>
            <button onClick={handlePasswordChange} className="cancel-btn">
              Change Password
            </button>

          </div>
          <div className="sub-det-card">
            <div className="sub-det-card-head">
              <h2 className="card-title">{state.subscription?.subscription.planName}</h2>
              <p className={`card-status ${state.subscription?.subscription.status === "active" ? "active" : "inactive"}`}>
                {state.subscription?.subscription.status === "active" ? "Active" : "Inactive"}
              </p>
            </div>
            <div className="sub-det-card-cont">
              <p className="card-period"><span>Cancels at: {new Date(state.subscription?.subscription?.validUpto).toDateString()}</span></p>
            </div>
            {storageService.get(configVariables.USER_ROLE) === configVariables.role_owner && (
              <button onClick={handleCancel} className="cancel-btn">
                Cancel Subscription
              </button>
            )}
            {(state.subscription?.subscription?.dataUsage <= 0) && (
              <button className="recharge-btn" onClick={() => { nav('/pay') }}>
                Recharge
              </button>
            )}
          </div>
        </div>

        {/* Table of previous invoices */}
        <div className="invoices-table device-table sub-det-device-table">
          <div className='table-row sub-det-table-row'>
            <p>Transaction Id</p>
            <p>Date</p>
            <p>Amount</p>
            <p>Status</p>
          </div>
          {state.subscription?.user?.transactions?.map((invoice) => (
            <div key={invoice.id} className='table-row sub-det-table-row'>
              <p>{invoice.transaction_id}</p>
              <p>{new Date(invoice.transaction_date).toLocaleDateString()}</p>
              <p>{(invoice.amount / 100).toFixed(2)} {invoice.currency}</p>
              <p className={`paid inv-status`}>Paid</p>
            </div>
          ))}
        </div>
      </div>
      {changePasswordModal && <ChangePasswordForm user_email={state?.subscription?.user?.contact_email} setCloseModal={setChangePasswordModal} />}
    </>
  )

}

export default SubscriptionDetails