import React, { useEffect, useState } from 'react'
import Switch from 'react-js-switch';
import { publishData } from '../appActions';
import getFormattedDate from '../util/publishTimeGen';

function SwitchChart({data, graphDet}) {
  const [isSwitchOn, setisSwitchOn] = useState(false)
  const [lastValueDate, setLastValueDate] = useState('');
  function convertTo12Hour(time24) {
    // Split the input into hours and minutes
    const [time, date] = time24.split(' ');
    let [hours, minutes] = time.split(':').map(Number);
    
    // Determine AM or PM suffix
    const suffix = hours >= 12 ? 'PM' : 'AM';
    
    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    
    // Format minutes and hours as two-digit strings
    minutes = minutes < 10 ? '0' + minutes : minutes;
    
    setLastValueDate( `${date} ${hours}:${minutes} ${suffix}`);
}
  useEffect(() => {
    if(data?.length>0){
      convertTo12Hour(data[0].datetime)
      if(data[0].value === '1' || data[0].value === '1'){
        setisSwitchOn(true)
      }else{
        setisSwitchOn(false)
      }
    }
  }, [data])

  const handleChange = ()=>{
    let code = ['n','n','n','n','n','n','n']
    let value = ['n','n','n','n','n','n','n']
    code[parseInt(graphDet.sensor_no)] = 'X'
    // sending opp data before updating
    value[parseInt(graphDet.sensor_no)] = isSwitchOn ? 'off' : 'on'
    setisSwitchOn(!isSwitchOn)
    let data = {
      topic:`ah/add/${graphDet.topic}`,
      data: {
        tim: getFormattedDate(),
        cod:code.join(),
        val:value.join()
      }
    }
    publishData(data).then(res=>{
      // do something
    }).catch(err=>{
      alert(err)
    })
  }
  return (
    <>
     <div className='alert-graph-card' >
      <Switch
        value={isSwitchOn}
        size={80}
        backgroundColor={{ on: 'rgba(36, 255, 0, 0.75)', off: 'gray' }}
        borderColor={{ on: 'rgba(36, 255, 0, 0.75)', off: 'gray' }}
        // onChange={handleChange} 
      />
      </div>
      {lastValueDate && (
       
        <p style={{ paddingTop: '20px', color: '#999' }}>
          Last updated on: {lastValueDate}
        </p>
       
      )}
    </>
  )
}

export default SwitchChart