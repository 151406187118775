import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

function Map({ data, width }) {
  if (data?.length > 0) {
    return (
      <>
        {/* <iframe 
          className='map-frame'
          frameborder="0" 
          scrolling="no" 
          marginheight="0" 
          marginwidth="0" 
          src={`https://maps.google.com/maps?q=${data[0]?.value.split('+')[0]},${data[0]?.value.split('+')[1]}&hl=en&z=14&amp&output=embed`}
        >
        </iframe>  */}
        <MapContainer style={{borderRadius:'5px'}} center={data[0].value.split('+')} zoom={13} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {data?.map(point => (
            <Marker position={point.value.split('+')}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </>
    )
  }
}

export default Map