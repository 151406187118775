import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signupNormalUsers } from '../appActions';

function AddUserForm({mode, secret_key}) {

  const { register, handleSubmit, formState:{errors}, setValue } = useForm();
  const dispatch = useDispatch()
  const nav = useNavigate()

  useEffect(() => {
    if(secret_key){
      setValue('reg_key',secret_key)
    }
  }, [secret_key])


  const onSubmit = (data,event) => {
    if(data.password !== data.re_password) {
      alert("Password doesn't match")
      return
    }
    dispatch(signupNormalUsers(data)).then(res=>{
      if(res){
        nav('/')
      }
    }).catch(err=>{
      alert(err)
    })
  };

  
  return (
    <div className='non-modal'>
      <form>
        <label htmlFor="" className='form-label'>
          Secret Key
          <input 
            type="text" 
            {...register('reg_key',{ required: "This field is required", maxLength: {value:20, message:"Maximum length is 20 characters"} })} 
          />
           {errors.reg_key && <span className='form-err-span'>{errors.reg_key.message}</span>}
        </label>
        <label htmlFor="" className='form-label'>
          Email
          <input type="email" {...register('email',{ required: "This field is required"})}/>
          {errors.email && <span className='form-err-span'>{errors.email.message}</span>}
        </label>     
        <label htmlFor="" className='form-label'>
          Username
          <input type="text" {...register('username',{ required: "This field is required", maxLength: {value:20, message:"Maximum length is 20 characters"} })}/>
          {errors.username && <span className='form-err-span'>{errors.username.message}</span>}
        </label>
        
        <label htmlFor="" className='form-label'>
          Password
          <input type="password" {...register('password',{ required: "This field is required", maxLength: {value:20, message:"Maximum length is 20 characters"} })}/>
          {errors.password && <span className='form-err-span'>{errors.password.message}</span>}
        </label>
        
        <label htmlFor="" className='form-label'>
          Re-Password
          <input type="password" {...register('re_password',{ required: "This field is required", maxLength: {value:20, message:"Maximum length is 20 characters"} })}/>
          {errors.re_password && <span className='form-err-span'>{errors.re_password.message}</span>}
        </label>
        
        <label htmlFor="" className='form-label'>
          Phone No
          <input type="text" {...register('phone',{ required: "This field is required", maxLength: {value:12, message:"Maximum length is 12 characters"}, pattern: /^[0-9]+$/i })}/>
          {errors.phone && <span className='form-err-span'>{errors.phone.message}</span>}
        </label>
        
        <button className='form-but-1' type='button' onClick={handleSubmit(onSubmit)}>Register</button>
      </form>
    </div>
  )
}



export default AddUserForm