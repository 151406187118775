

import React, { useState, useEffect } from 'react';
import './FlightMessage.css'
function FlightMessage({ data, width }) {
  const [cardColor, setCardColor] = useState('#24ff00bf');
  const [cardContent, setCardContent] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      // Combine all values and check for 'fire alert'
      const allValues = data.map(d => d.value).join('+');
      if (allValues.includes('fire alert')) {
        setCardColor('#D30000');
      } else {
        setCardColor('#24ff00bf');
      }

      // Set the split values with datetime as an array of objects
      const content = data.flatMap((item, index) => 
        item.value.split('+').map((line, lineIndex) => ({
          lineContent: line,
          lineDatetime: item.datetime,
          key: `${index}-${lineIndex}` // Combine index and lineIndex for a unique key
        }))
      );

      setCardContent(content);
    }
  }, [data]);

  return (
    <div className='message-card' style={{ color: cardColor }}>
      <div className='message-card-content'>
        {cardContent.map(({ lineContent, lineDatetime, key }) => (
          <div key={key}>
            <p className='message-content'>{lineDatetime}: {lineContent}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FlightMessage;
