// import React from 'react'
// import { useState } from 'react';
// import { useForm } from 'react-hook-form';
// import { useDispatch } from 'react-redux';

// import { AddGraph } from '../appActions';
// import cross_logo from '../Assets/Icons/cross.png'
// import configVariables, { graphTypes, grpahImages } from '../util/constants';
// import InfoButton from './InfoButton';

// function AddGraphForm({user_det,setCloseModal, fetchGraphs}) {

//   const { register, handleSubmit } = useForm({defaultValues:{fetch_data_period:10}});
//   const dispatch = useDispatch()
//   // const state = useSelector((state) => state);

//   const [projectOptions, setProjectOptions] = useState([])
//   const [deviceOptions, setDeviceOptions] = useState([])
//   const [sensorOptions, setSensorOptions] = useState([])
//   const [selectedSensor, setSelectedSensor] = useState({})
//   const [graphImagePath, setGraphImagePath] = useState('')
  
//   const handleBusinessSelect = (e)=>{
//     if(e.target.value !== null){
//       setProjectOptions(getObjectById(user_det.business_id,e.target.value).project_id)
//     }
//   }
//   const handleProjectSelect = (e)=>{
//     if(e.target.value !== null){
//      setDeviceOptions(getObjectById(projectOptions,e.target.value).device_id)
//     }
//   }
//   const handleDeviceSelect = (e)=>{
//     if(e.target.value !== null){
//       let sensor_det = getObjectById(deviceOptions,e.target.value).sensor_id
//       setSensorOptions(sensor_det)
//     }
//   }
//   const handleSensorSelect = (e)=>{
//     if(e.target.value !== null){
//       let sensor_det = getObjectById(sensorOptions,e.target.value)
//       setSelectedSensor(sensor_det)
//     }
//   }

//   const getObjectById = (arr,id)=>{
//     for(let i =0;i<arr.length;i++){
//       if(arr[i]._id === id){
//         return arr[i];
//       }
//     }
//   }
//   const onSubmit = (data)=>{
//     if(user_det.userRole === configVariables.role_owner){
//       data.owner_id = [user_det._id]
//     }else if(user_det.userRole === configVariables.role_manager){
//       data.manager_id = [user_det._id]
//     }else{
//       data.super_id = [user_det._id]
//     }
//     console.log(data);
//     dispatch(AddGraph(data)).then(status=>{
//       if(status){
//         setCloseModal(false)
//         fetchGraphs()
//       }
//     })
//   }

//   const handleGraphTypeSelect = (e)=>{
//     const graphType = e.target.value
//     const imgPath = grpahImages[graphType]
//     setGraphImagePath(imgPath)
//   }

//   return (
//     <div className='modal'>
//       <form onSubmit={handleSubmit(onSubmit)}>
//         <img onClick={()=>{setCloseModal(false)}} className='form-cross-but' src={cross_logo} alt="" />
//         <label className='form-label' htmlFor="">
//           Select Business
//           <select {...register('business_id',{ required: true })} onChange={handleBusinessSelect}>
//             <option value="">Select</option>
//             {user_det?.business_id?.map(business=> <option value={business?._id}>{business?.name}</option>)}
//           </select>
//         </label>
//         <label className='form-label' htmlFor="">
//           Select Project
//           <select {...register('project_id',{ required: true })} onChange={handleProjectSelect}>
//             <option value="">Select</option>
//             {projectOptions?.map(project=> <option value={project?._id}>{project?.name}</option>)}
//           </select>
//         </label>
//         <label className='form-label' htmlFor="">
//           Select Device
//           <select {...register('device_id',{ required: true })} onChange={handleDeviceSelect}>
//             <option value="">Select</option>
//             {deviceOptions?.map(device=> <option value={device?._id}>{device?.name}</option>)}
//           </select>
//         </label>
//         <label className='form-label' htmlFor="">
//           Select Sensor
//           <select {...register('sensor_id',{ required: true })} onChange={handleSensorSelect}>
//             <option value="">Select</option>
//             {sensorOptions?.map(sensor=> <option value={sensor?._id}>{sensor?.name}</option>)}
//           </select>
           
//         </label>
//         <label className='form-label' htmlFor="">
//           <div className='form-label-tooltip'>
//           Select Graph Type
//           <InfoButton content={'This is a sample tooltip'} />
//           </div>
//           <select {...register('type',{ required: true })} onChange={handleGraphTypeSelect}>
//             <option value="">Select</option>
//             {/* {Object.keys(graphTypes).map(key=><option value={key}>{key}</option>)} */}
//             {selectedSensor?.type?.graph_types?.map(key=><option value={key}>{key}</option>)}
//           </select>
           
//         </label>
//         <label className='form-label' htmlFor="">
//           {graphImagePath?.length > 0 && <img src={graphImagePath} alt='graph_image'></img>}  
//         </label>
        
//         <label className='form-label' htmlFor="">
//           Fetch Period
//           <input type="number" max={50} min={10}   {...register('fetch_data_period',{ required: true  })}/>
//         </label>
//         <label className='form-label' htmlFor="">
//           Graph Name
//           <input type="text"   {...register('name',{ required: true })}/>
//         </label>
//           <button className='form-but-1'>Add</button>
//       </form>
//     </div>
//   )
// }

// export default AddGraphForm

import React from 'react'
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Select from 'react-select'
import { AddGraph } from '../appActions';
import cross_logo from '../Assets/Icons/cross.png'
import configVariables, { graphTypes, grpahImages } from '../util/constants';
import InfoButton from './InfoButton';
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    
    backgroundColor: 'rgba(47, 47, 47, 1)',
    boxShadow: '0px 7.2px 10.8px 5.4px rgba(0, 0, 0, 0.15), 0px 3.6px 3.6px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',
    border: 'none',
    color: 'white',
    height: '50px',
    padding: '10px',
    width: '100%',
    gridArea: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px'
    
  }),
  menu: (provided) => ({
    ...provided,
    background:'rgba( 76, 76, 76, 0.65 )',
    backdropFilter:'blur( 9.5px )',
    borderRadius: '10px',
    border: '1px solid rgba( 255, 255, 255, 0.18 )',
    // backgroundColor: '#4a4948',
    fontSize:'12px',
    
    // border:'1px solid #7469B6',
    color: 'white',
    fontSize:'12px'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '' : 'transparent',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#303043',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'white',
    ':hover': {
      backgroundColor: '#FB493F',
      color: 'white',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'white',
  }),
};

function AddGraphForm({user_det,setCloseModal, fetchGraphs,edit_obj}) {

  const { register, handleSubmit, getValues, setValue } = useForm(
    {defaultValues:{
      fetch_data_period:10,
      ...edit_obj,
      project_id: edit_obj?.device_id.project_id,
      device_id: edit_obj?.device_id._id,
      sensor_id: edit_obj?.sensor_id._id,

    }});
  const dispatch = useDispatch()
  // const state = useSelector((state) => state);

  const [projectOptions, setProjectOptions] = useState([])
  const [deviceOptions, setDeviceOptions] = useState([])
  const [sensorOptions, setSensorOptions] = useState([])
  const [selectedSensor, setSelectedSensor] = useState({})
  const [graphImagePath, setGraphImagePath] = useState('')
  
  const handleBusinessSelect = (selectedOption) => {
    if (selectedOption) {
      setValue('business_id', selectedOption.value);
      setProjectOptions(getObjectById(user_det.business_id, selectedOption.value).project_id);
    }
  };

  const handleProjectSelect = (selectedOption) => {
    if (selectedOption) {
      setValue('project_id', selectedOption.value);
      setDeviceOptions(getObjectById(projectOptions, selectedOption.value).device_id);
    }
  };

  const handleDeviceSelect = (selectedOption) => {
    if (selectedOption) {
      setValue('device_id', selectedOption.value);
      let sensor_det = getObjectById(deviceOptions, selectedOption.value);
      setSensorOptions(sensor_det);
    }
  };

  const handleSensorSelect = (selectedOption) => {
    if (selectedOption) {
      setValue('sensor_id', selectedOption.value);
      let sensor_det = getObjectById(sensorOptions.sensor_id, selectedOption.value);
      setSelectedSensor(sensor_det);
    }
  };

  const getObjectById = (arr,id)=>{
    for(let i =0;i<arr.length;i++){
      if(arr[i]._id === id){
        return arr[i];
      }
    }
  }
  const onSubmit = (data)=>{
    if(user_det.userRole === configVariables.role_owner){
      data.owner_id = [user_det._id]
    }else if(user_det.userRole === configVariables.role_manager){
      data.manager_id = [user_det._id]
    }else{
      data.super_id = [user_det._id]
    }
    // console.log(data);
    dispatch(AddGraph(data)).then(status=>{
      if(status){
        setCloseModal(false)
        fetchGraphs()
        window.location.reload();
      }
    })
  }

  const handleGraphTypeSelect = (selectedOption) => {
    if (selectedOption) {
      const graphType = selectedOption.value;
      const imgPath = grpahImages[graphType];
      setGraphImagePath(imgPath);
      setValue('type', graphType);
    }
  };

  return ( 
    <div className='modal'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <img onClick={()=>{setCloseModal(false)}} className='form-cross-but' src={cross_logo} alt="" />
        <label className='form-label' htmlFor="">
          Select Business
          <Select
              placeholder="Select Business"
              
              styles={customStyles}
              options={user_det?.business_id?.map((business) => ({
                value: business?._id,
                label: business?.name,
              }))}
              onChange={handleBusinessSelect}
            />
        </label>
        <label className='form-label' htmlFor="">
        <Select
              placeholder="Select Project"
              styles={customStyles}
              options={projectOptions?.map((proj) => ({
                value: proj?._id,
                label: proj?.name,
              }))}
              onChange={handleProjectSelect}
            />
        </label>
        <label className='form-label' htmlFor="">
        <Select
              placeholder="Select Device"
              styles={customStyles}
              options={deviceOptions?.map((dev) => ({
                value: dev?._id,
                label: dev?.name,
              }))}
              onChange={handleDeviceSelect}
            />
        </label>
        <label className='form-label' htmlFor="">
          Select Sensor
          <Select
              placeholder="Select Sensor"
              styles={customStyles}
              options={sensorOptions?.sensor_id?.map((sensor) => ({
                value: sensor?._id,
                label: sensor?.name,
              }))}
              onChange={handleSensorSelect}
            />
           
        </label>
        <label className='form-label' htmlFor="">
          <div className='form-label-tooltip'>
          Select Graph Type
          <InfoButton content={'This is a sample tooltip'} />
          </div>
          <Select
                 options={selectedSensor?.type?.graph_types?.map(graph => ({ value: graph, label: graph }))}
                //  onChange={(selectedOption) => setValue('type', selectedOption.value)}
                onChange={handleGraphTypeSelect}
                 styles={customStyles}
               />
           
        </label>
        <label className='form-label' htmlFor="">
          
          {graphImagePath?.length > 0 && <img src={graphImagePath} alt='graph_image'></img>}  
        </label>
        
        <label className='form-label' htmlFor="">
          Fetch Period
          <input type="number" max={50} min={10}   {...register('fetch_data_period',{ required: true  })}/>
        </label>
        <label className='form-label' htmlFor="">
          Graph Name
          <input type="text"   {...register('name',{ required: true })}/>
        </label>
          <button className='form-but-1'>Add</button>
      </form>
    </div>
  )
}

export default AddGraphForm