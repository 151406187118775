import React, { useEffect, useState } from 'react'
import cross_logo from '../Assets/Icons/cross.png'
import { grpahImages } from '../util/constants';
import { Controller, useForm } from 'react-hook-form';
import colourStyles from '../util/reactSelectStyle';
import Select from 'react-select';
import callApi from '../util/callApi';
import { useDispatch } from 'react-redux';
import { fetchTypes } from '../appActions';
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    
    backgroundColor: 'rgba(47, 47, 47, 1)',
    boxShadow: '0px 7.2px 10.8px 5.4px rgba(0, 0, 0, 0.15), 0px 3.6px 3.6px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',
    border: 'none',
    color: 'white',
    height: '50px',
    padding: '10px',
    width: '100%',
    gridArea: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px'
    
  }),
  menu: (provided) => ({
    ...provided,
    background:'rgba( 76, 76, 76, 0.65 )',
    backdropFilter:'blur( 9.5px )',
    borderRadius: '10px',
    border: '1px solid rgba( 255, 255, 255, 0.18 )',
    // backgroundColor: '#4a4948',
    fontSize:'12px',
    
    // border:'1px solid #7469B6',
    color: 'white',
    fontSize:'12px'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '' : 'transparent',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#303043',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'white',
    ':hover': {
      backgroundColor: '#FB493F',
      color: 'white',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'white',
  }),
};
function AddTypeForm({ editObject = {}, setCloseModal }) {

  const { register, handleSubmit, control, getValues } = useForm({ defaultValues: editObject });
  const [selectedGraphs, setselectedGraphs] = useState([])
  const graphOptions = Object.keys(grpahImages).map(graph => ({ label: graph, value: graph }))
  const dispatch = useDispatch()

  useEffect(() => {
    if(Object.keys(editObject).length > 0){
      setselectedGraphs(editObject?.graph_types.map(graph => ({ label: graph, value: graph })))
    }
  }, [])
  

  const onSubmit = async (data) => {
    if (data.min_value > data.max_value) {
      return alert('Min value should be less than max value')
    }

    let endpoint = 'add_type'
    if(Object.keys(editObject).length > 0){
      endpoint = 'edit_type'
    }
    try {
      data.graph_types = selectedGraphs.map(graph => graph.value)
      await callApi(endpoint, 'post', data)
      dispatch(fetchTypes())
      setCloseModal(false)
    } catch (err) {
      alert('Ann error occured. Please try again!')
    }
  }

  return (
    <div className='modal'>
      <form >
        <img onClick={() => { setCloseModal(false) }} className='form-cross-but' src={cross_logo} alt="" />
        <label className='form-label' htmlFor="">
          Code
          <input type="text" {...register('code', { required: true })} />
        </label>
        <label className='form-label' htmlFor="">
          Name
          <input type="text" {...register('name', { required: true })} />
        </label>
        <div className="color-box">
          <label className='form-label' htmlFor="">
            Min Value
            <input type="number" min={0} defaultValue={0} {...register('min_value', { required: true })} />
          </label>
          <label className='form-label' htmlFor="">
            Max Value
            <input type="number" min={getValues('min_value') || 0} defaultValue={getValues('min_value') || 0} {...register('max_value', { required: true })} />
          </label>
        </div>
        <div className="color-box">
          <label className='form-label' htmlFor="">
            Unit Name
            <input type="text" {...register('unit_name', { required: true })} />
          </label>
          <label className='form-label' htmlFor="">
            Unit Symbol
            <input type="text" {...register('unit_symbol', { required: true })} />
          </label>
        </div>

        <label className='form-react-select' htmlFor="">
          Select Graphs
          <Controller
            control={control}
            name="graph_types"
            // rules={{ required: true }}
            render={({ field: { onChange, ref } }) => (
              <Select
                inputRef={ref}
                value={selectedGraphs}
                onChange={(val, action) => { setselectedGraphs(val) }}
                options={graphOptions}
                isMulti
                styles={customStyles}
              />
            )}
          />

        </label>

        <div className="form-but-group">
          <button className='form-but-1' onClick={handleSubmit(onSubmit)} type="button">
            {Object.keys(editObject).length > 0 ? 'Save' : 'Add'}
          </button>
        </div>
      </form>
    </div>
  )
}

export default AddTypeForm