import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AddBusinessForm from '../../Components/AddBusinessForm'
import AddUserForm from '../../Components/AddUserForm'
import './Signup.css'

function SignUp() {

  const { secret_key } = useParams()
  const [role, setRole] = useState('owner')
  
  return (
    <div className='signup-top containor'>
      <div className='form-box-top signup-form'>
      <form className="form-box">
        <div className='login-head'><p>Register</p></div>
        <label className='form-label' htmlFor="">
          Select User Type
          <select name="" id="" onChange={e=>{setRole(e.target.value)}}>
            <option value="owner">Owner</option>
            <option value="other">Manager</option>
            <option value="other">Supervisor</option>
          </select>
           
        </label>
       
        {role === 'owner'?
          <AddBusinessForm mode='signup' />
        : <AddUserForm secret_key={secret_key}/>
        }
      </form>
      <p className='login-reg-link'>
        Already have an account, 
        <Link to={'/login'}>Login</Link> 
      </p>
      </div>
    </div>
  )
}

export default SignUp