import React, { useRef } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { DeleteGraph, FetchGraphs, FetchSubscription, fetchUserDetails } from '../../appActions'
import AddGraphForm from '../../Components/AddGraphForm'
import { useEffect } from 'react'
import './Dashboard.css'

import full_screen_logo from '../../Assets/Icons/fullscreen.png'
import down_arrow_logo from '../../Assets/Icons/down.png'
import add_logo from '../../Assets/Icons/add.png'

import Chart from '../../Components/Chart';
import DisableGraphForm from '../../Components/DisableGraphForm';
import GraphSettingForm from '../../Components/GraphSettingForm';
import ShareGraphForm from '../../Components/ShareGraphForm';
// import { XMasonry, XBlock } from "react-xmasonry";
import Notification from '../../Components/Notification/Notification';
// import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import Masonry from "react-masonry-component";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Header from '../dashboardheader/header';
import useMediaQuery from '@mui/material/useMediaQuery';
import Select from 'react-select';






function Dashboard() {
 
  const nav = useNavigate()
  const dispatch = useDispatch()
  const state = useSelector((state) => state);
  const containorRef = useRef()
  const isMobile = useMediaQuery('(max-width: 766px)');

  const [addGraphModal, setAddGraphModal] = useState(false)
  const [editGraphModeal, setEditGraphModeal] = useState(false)
  const [diasbleGraphModal, setDisableGraphModal] = useState(false)
  const [isFullScreenEnabled, setIsFullScreenEnabled] = useState(false)
  const [shareGraphModal, setShareGraphModal] = useState(false)
  const [deviceConnectionCount, setDeviceConnectionCount] = useState({
    connected: 0,
    disconnected: 0
  })


  useEffect(() => {
    if (!state.user_det) {
      dispatch(fetchUserDetails())
    }
    if (!state.graph_det && state.user_det) {
      fetchGraphs()
    }
    if (!state.subscription) {
      dispatch(FetchSubscription())
    }
    if (state.user_det) {
      let count = {
        connected: 0,
        disconnected: 0
      }
      state.user_det.business_id?.map(business => {
        business.device_id.map(device => {
          if (device.connection_status) {
            count.connected += 1
          } else {
            count.disconnected += 1
          }
        })
      })
      setDeviceConnectionCount(count)
    }
  }, [state.user_det, state.data_query])

  const fetchGraphs = () => {
    // let query = {}
    // query[`${state.user_det.userRole}_id`] = {$in:[state.user_det._id]}
    // console.log(query);
    dispatch(FetchGraphs())
  }

  const addGraph = () => {
    setAddGraphModal(true)
  }

  const deleteGraph = (id) => {
    if (window.confirm("Are you sure you want to delete the graph?")) {
      DeleteGraph({ _id: id }).then(err => {
        if (!err) {
          fetchGraphs()
          window.location.reload();
        } else {
          console.log(Error)
          alert(err)
        }
      })
    }
  }

  const handleFullScreen = () => {
    if (isFullScreenEnabled) {
      // We’re exiting fullscreen
      document?.exitFullscreen();
      setIsFullScreenEnabled(false)

    } else {
      // We’re going fullscreen
      document?.body?.requestFullscreen()
      setIsFullScreenEnabled(true)
    }
  }
  return (
   
    <div className='dash-top containor'>
    
    <div className="dash-cont" ref={containorRef}>
      {isMobile && <Header />}
        <div className="graph-header">
          <div className="device-name">
            <h1>Dashboard</h1>
          </div>
          <div className="graph-header-widget-top">
            <div className="graph-header-widget" onClick={() => { setDisableGraphModal(true) }}>
              <p>Disable Graphs</p>
              {/* <img src={down_arrow_logo} alt="" /> */}
              <ExpandMoreIcon />
            </div>
            <div onClick={addGraph} className="graph-header-widget">
              <p>Add</p>
              {/* <img src={add_logo} alt="" /> */}
              <AddIcon />
            </div>
          </div>
        </div>
        <div className="graph-top">
          <Masonry>
            {state?.graph_det?.map((graph, i) =>
            (!graph.merged &&
              <Chart
                graph_det={graph}
                type={graph.type}
                title={`${graph.device_id.name}/${graph.sensor_id.name}`}
                name={graph.name}
                data_query={state?.data_query[i]}
                setEditGraphModeal={setEditGraphModeal}
                deleteGraph={deleteGraph}
                setShareGraphModal={setShareGraphModal}
              />
            )
            )}
          </Masonry>
        </div>

      </div>
      {addGraphModal && <AddGraphForm setCloseModal={setAddGraphModal} fetchGraphs={fetchGraphs} user_det={state.user_det} />}
      {editGraphModeal &&
        <GraphSettingForm
          graph_det={editGraphModeal}
          setCloseModal={setEditGraphModeal}
          fetchGraphs={fetchGraphs}
          user_det={state.user_det}
          all_graphs={state.graph_det.filter(graph => graph.type === 'line')}
        />
      }
      {diasbleGraphModal &&
        <DisableGraphForm
          graph_det={[...state?.graph_det, ...state?.disabled_graph_det]}
          disabled_graphs={state?.disabled_graph_det.map(graph => graph._id)}
          setCloseModal={setDisableGraphModal}
          fetchGraphs={fetchGraphs}
        />
      }
      {shareGraphModal &&
        <ShareGraphForm
          business_id={shareGraphModal.business_id}
          graph_id={shareGraphModal.graph_id}
          setCloseModal={setShareGraphModal}
        />
      }

    </div>
  )
}

export default Dashboard