import React, { useState } from 'react'
import callApi from '../../util/callApi';
import { useNavigate, useParams } from 'react-router-dom';

function ResetPassword() {
  const [password, setPassword] = useState('')
  const [rePassword, setRePassword] = useState('')
  const {token} = useParams()
  const nav = useNavigate()
  
  const handleSubmit = async (e)=>{
    e.preventDefault()
    if(!token){
      return alert("Invalid token!")
    }
    if(password === rePassword){
      try{
        const res = await callApi('/reset_password','post',{password:password,token:token})
        alert(res.data)
        nav('/login')
      }catch(err){
        alert(err.data)
      }
    }else{
      alert("Password deosn't match. Please try again!")
    }
  }

  return (
    <div className='login-box'>
    <form action="" onSubmit={handleSubmit} className='form-box'>
      <div className="login-head">
        <p>Reset Password</p>
      </div>
      <div className="login-form-body-top">
        {/* {formErrors && <p>{formErrors}</p>} */}
        <div className="login-form-body">
          <label htmlFor="" className='login-label'>
            New Password
            <input type="password" onChange={(e)=>{setPassword(e.target.value)}}/>
          </label>   
        </div>     
        <div className="login-form-body">
          <label htmlFor="" className='login-label'>
            Re-type New Password
            <input type="password" onChange={(e)=>{setRePassword(e.target.value)}}/>
          </label>   
        </div>     
        <div className="login-but-group">
          <button className='login-but'>Reset</button>
        </div>
      </div>
    </form>
  </div>
  )
}

export default ResetPassword