import React, { useState } from 'react'
import callApi from '../../util/callApi';

function ForgotPassword() {
  const [email, setEmail] = useState()
  
  const handleSubmit = async (e)=>{
    e.preventDefault()
    try{
      const res = await callApi('/forgot_password','post',{email:email})
      alert(res.data)
    }catch(err){
      alert(err.data)
    }
  }

  return (
    <div className='login-box'>
    <form action="" onSubmit={handleSubmit} className='form-box'>
      <div className="login-head">
        <p>Reset Password</p>
      </div>
      <div className="login-form-body-top">
        {/* {formErrors && <p>{formErrors}</p>} */}
        <div className="login-form-body">
          <label htmlFor="" className='login-label'>
            Email
            <input placeholder='john@doe.com' type="email" onChange={(e)=>{setEmail(e.target.value)}}/>
          </label>   
        </div>     
        <div className="login-but-group">
          <button className='login-but'>Reset</button>
        </div>
      </div>
    </form>
  </div>
  )
}

export default ForgotPassword