import React, { useEffect, useState } from 'react'
import BatteryGauge from 'react-battery-gauge'

function BatteryChart({ data }) {
  const [value, setValue] = useState(0)
 

  useEffect(() => {
    if (data?.length > 0) {
      const mappedValue = data[0].value * 25;
      setValue(mappedValue)
    }
  }, [data])

  const styles = {
    batteryBody: {
      strokeWidth: 2,
      cornerRadius: 3,
      fill: 'none',
      strokeColor: 'white'
    },
    batteryCap: {
      fill: 'none',
      strokeWidth: 2,
      strokeColor: 'white',
      cornerRadius: 2,
      capToBodyRatio: 0.3
    },
    batteryMeter: {
      fill: 'rgba(36, 255, 0, 0.75)',
      lowBatteryValue: 15,
      lowBatteryFill: 'red',
      outerGap: 1,
      noOfCells: 10, // more than 1, will create cell battery
      interCellsGap: 2
    },
    readingText: {
      lightContrastColor: 'white',
      darkContrastColor: '#fff',
      lowBatteryColor: 'red',
      fontFamily: 'inherit',
      fontSize: 14,
      showPercentage: false // Hide percentage
    },
  }

  return (
    <BatteryGauge 
      value={value} 
      padding={10}
      aspectRatio={.56}
      customization={styles}
    />
  )
}

export default BatteryChart
