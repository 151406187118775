


// import RangeSlider from 'react-range-slider-input';
// import 'react-range-slider-input/dist/style.css';
// import React, { useState, useEffect } from 'react';
// import { publishData } from '../appActions';
// import './rtlbutton.css';

// function SendMessageComponent( topicvalue ) {
//   const [value, setValue] = useState(0);
//   const [RTLText, setRTLText] = useState('Slide to RTL');
//   const [isSending, setIsSending] = useState(false);
//   const [firstValue, setFirstValue] = useState('');
//   useEffect(() => {
//     if (topicvalue && topicvalue.data && topicvalue.data.length > 0) {
//       setFirstValue(topicvalue.data[0].value);
      
//     }
//   }, [topicvalue]);
  


  

//   useEffect(() => {
//     if (isSending) {
//       sendMessages();
//     }
//   }, [isSending]);

//   const sendMessages = () => {
//     const data = {
//       data: { value: 'RTL' },
//       topic: `ah/send/${topicvalue.data}`
//     };

//     let sendCount = 0;
//     setRTLText('Processing...');
//     setIsSending(true);

//     // Start sending messages every 5 seconds
//     const intervalId = setInterval(() => {
//       if (sendCount < 3) {
//         publishData(data)
//           .then(res => {
//             console.log(`RTL message sent successfully! Attempt ${sendCount + 1}`, res);
//             sendCount += 1; // Increment sendCount correctly
//             if (sendCount === 3) {
//               setRTLText('RTL'); // Update text state after all attempts are done
//               setIsSending(false); // Stop sending
//               clearInterval(intervalId); // Stop interval after all attempts
//             }
//           })
//           .catch(err => {
//             console.error(`Error sending RTL message: Attempt ${sendCount + 1}`, err);
//             if (sendCount === 2) {
//               setRTLText('Error'); // Update text state if all attempts fail
//               setIsSending(false); // Stop sending
//               clearInterval(intervalId); // Stop interval after all attempts
//             }
//           });
//       } else {
//         clearInterval(intervalId); // Stop interval after 3 attempts
//         setRTLText('RTL');
//         setIsSending(false); // Stop sending
//       }
//     }, 5000); // 5000 milliseconds = 5 seconds
//   };

//   const handleSliderChange = (newValue) => {
//     setValue(newValue);
//     if (newValue === 100) {
//       setRTLText('Processing...');
//       setIsSending(true);
//     } else {
//       setValue(0);
//       setRTLText('Slide to RTL'); // Reset text state to initial value
//       setIsSending(false); // Stop sending if slider value changes
//     }
//   };

//   return (
//     <div className="send-message-component">
//       <div className="slider-container">
//         <RangeSlider 
//           value={[0, value]}
//           thumbsDisabled={[true, false]}
//           rangeSlideDisabled={true}
//           onInput={x => { handleSliderChange(x[1]) }}
//           className='slider-chart1'
//         />
//         <div className="slider-label" id='text'>{RTLText}</div>
//       </div>
//     </div>
//   );
// }

// export default SendMessageComponent;


import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import React, { useState, useEffect } from 'react';
import { publishData } from '../appActions';
import './rtlbutton.css';

function SendMessageComponent( topicvalue,data ) {
  const [value, setValue] = useState(0);
  const [Value1,setValue1]=useState(0)
  const [RTLText, setRTLText] = useState('Slide to RTL');
  const [isSending, setIsSending] = useState(false);
  const [isSending2,setIsSending2]=useState(false);
  const [firstValue, setFirstValue] = useState('');
  const [secondValue, setSecondValue] = useState('');
  const [text1,setText1]=useState('')
  const [text2,setText2]=useState('')
  const [text3,setText3]=useState('')
  

  useEffect(() => {
    if (topicvalue && topicvalue.data && topicvalue.data.length > 0) {
      const [fv,sv]=topicvalue.data[0].value.split('+')
      setFirstValue(fv);
      setSecondValue(sv);

      
    }
  }, [topicvalue]);

  
  // Use a separate useEffect to handle state updates after setting firstValue
  useEffect(() => {
    // Ensure firstValue is a number and compare strictly to 1
    if (Number(firstValue) === 1) {
      setValue(100);
      
      setRTLText('RTL');
    }
    else{
      setValue(0);
      setRTLText('Slide to RTL');
    }
   
    if (Number(secondValue)===1){
      setValue1(0)
      setText2('Slide To Disarm >>')
      setText3()
      setText1('ARM')
    }
    
    else if(Number(secondValue)===0){
      setValue1(100)
      setText2('<< Slide to Arm')
      setText1()
      setText3('DIS')
    }
  }, [firstValue,secondValue]);

  useEffect(() => {
    if (isSending) {
      sendMessages()
      }
    if (isSending2){
      if(Value1===0){
        const data = {
          data:{ tim: new Date(), 
            val: "n,n,n,n,n,ARM,n" },
          topic: `ah/add/${topicvalue.topic}`
        };
        sendMessages2(data)
      }
      if (Value1===100){
        
        const data = {
          data:{ tim: new Date(), 
            val: "n,n,n,n,n,DIS,n" },
          topic: `ah/add/${topicvalue.topic}`
        };
        sendMessages2(data);
      }
    }
    
  }, [isSending,isSending2]);

  const sendMessages = () => {
    const data = {
      data:{ tim: new Date(), 
        val: "n,n,n,n,n,RTL,n" },
      topic: `ah/add/${topicvalue.topic}`
    };
   

    let sendCount = 0;
    setRTLText('Processing...');
    setIsSending(true);

    // Start sending messages every 5 seconds
    const intervalId = setInterval(() => {
      if (sendCount < 3) {
        publishData(data)
          .then(res => {
            sendCount += 1; // Increment sendCount correctly
            if (sendCount === 3 ) {
              if (Number(firstValue)===1){
                setRTLText('RTL')
              }
              setIsSending(false); // Stop sending
              clearInterval(intervalId); // Stop interval after all attempts
              
            }
          })
          .catch(err => {
            if (sendCount === 2) {
              setRTLText('Error'); // Update text state if all attempts fail
              setIsSending(false); // Stop sending
              clearInterval(intervalId); // Stop interval after all attempts
            }
          });
      } else {
        clearInterval(intervalId); // Stop interval after 3 attempts
        setRTLText('RTL');
        setIsSending(false); // Stop sending
      }
    }, 5000); // 5000 milliseconds = 5 seconds
  };

  const sendMessages2 = (data) => {
    

    let sendCount = 0;
    setIsSending2(true)
    // Start sending messages every 5 seconds
    const intervalId = setInterval(() => {
      if (sendCount < 3) {
        publishData(data)
          .then(res => {
            sendCount += 1; // Increment sendCount correctly
            if (sendCount === 3 ) {
              setIsSending2(false)
              if (Number(secondValue)===1){
                setText2('Slide to Disarm>>')
                setText1('ARM')
                setText3()
                setValue1(0)
              }
              else if(Number(secondValue)===0){
                setText2('<<Slide to Arm')
                setText3('DIS')
                setText1()
                setValue1(100)
              }
              
              clearInterval(intervalId); // Stop interval after all attempts
              
            }
          })
          .catch(err => {
            if (sendCount === 2) {
              setText2('Error'); // Update text state if all attempts fail
              setIsSending2(false); // Stop sending
              clearInterval(intervalId); // Stop interval after all attempts
            }
          });
      } else {
        clearInterval(intervalId); // Stop interval after 3 attempts
        // setRTLText('RTL');
        setIsSending2(false); // Stop sending
      }
    }, 5000); // 5000 milliseconds = 5 seconds
  };



  const handleSliderChange = (newValue) => {
    setValue(newValue);
    if (newValue === 100) {
      setRTLText('Processing...');
      setIsSending(true);
    } else {
      setValue(0);
      setRTLText('Slide to RTL'); // Reset text state to initial value
      setIsSending(false); // Stop sending if slider value changes
    }
  };
  const handleSliderChange2 = (newValue) => {
    if (newValue === 100) {
      setValue1(100)
      setText3("DIS")
      setText1()
      setText2('processing..')
      setIsSending2(true)
     
    } if (newValue ===0) {
      setValue1(0)
      setText3()
      setText1('ARM')
      setText2('processing..')
      setIsSending2(true)
    }
    if (newValue!=0 && newValue!=100){
      setValue1(Value1)
    }
  };

  return (
    <div className="send-message-component">
      <div className="slider-container">
        <RangeSlider 
          value={[0, value]}
          thumbsDisabled={[true, false]}
          rangeSlideDisabled={true}
          onInput={x => { handleSliderChange(x[1]) }}
          className='slider-chart1'
        />
        <div className="slider-label" id='text'>{RTLText}</div>
      </div>
      
      <div className="slider-container">
        <RangeSlider 
        value={[0, Value1]}
        thumbsDisabled={[true, false]}
        rangeSlideDisabled={true}
        onInput={x => { handleSliderChange2(x[1]) }}
        
          className='slider-chart1'
        />
        <div className="slider-label2" id='text2'>{text2}</div>
        <div className="slider-label1" id='text1'>{text1}</div>
        <div className="slider-label3" id='text3'>{text3}</div>

      </div>
    </div>
  );
}

export default SendMessageComponent;
