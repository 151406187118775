import React, { useEffect } from 'react'
import './Pay.css'
import back_arrow from '../../Assets/Icons/back_arrow.png'
import logo from '../../Assets/Images/logo.png'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import callApi from '../../util/callApi'
import { useDispatch, useSelector } from 'react-redux'
import { ResetState, fetchUserDetails } from '../../appActions'

import CheckoutForm from '../../Components/CheckoutForm'
import { Elements, useElements } from "@stripe/react-stripe-js";
import logout_logo from '../../Assets/Icons/logout.png'
import storageService from '../../util/storageService'
import configVariables from '../../util/constants'


function Pay({showLogOut}) {

  const nav = useNavigate()
  const dispatch = useDispatch()
  const [checkoutFormModal, setCheckoutFormModal] = useState(false)
  const [plans, setPlans] = useState([])
  const [plan, setPlan] = useState({})
  const [subscription, setSubscription] = useState({})
  


  const options = {}


  useEffect(() => {
    if(plans.length == 0) {
      callApi('fetch_plans','post',{}).then(res=>{
        if(res.status === 'Success'){
          setPlans(res.data.plans)
        }else{
          alert("An error occured. Please try again later!")
        }
      })
    }
  }, [])

  const handleSubscribe = (plan)=>{
    setPlan(plan)
    setCheckoutFormModal(true)
  }

  const handleLoadFailure = (event) => {
    if(subscription.id){
      callApi('cancel_subscription','post',{subscriptionId:subscription.id}).then(res=>{
        if(res.status === 'Success'){
          // 
        }else{
          alert("An error occured. Please try again later.")
        }
      })
    }
  };
  
  const logOut =  ()=>{
    storageService.remove(configVariables.USER_ID)
    storageService.remove(configVariables.USER_ROLE);
    storageService.remove(configVariables.USER_JWT_TOKEN);
    dispatch(ResetState())
    nav('/login')
  }

  return (
    <div className='pricing-table-top'>
      <div className='pricing-table-head'>
        <h1 className=''><img src={back_arrow} onClick={()=>{nav('/manage/business')}} alt="arrow" />Select a Plan</h1>
        <div className='pay-table-head-right'>
          {showLogOut && <p onClick={logOut}><img src={logout_logo} alt="logo" />Logout</p>}
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className='pricing-table'>
        {plans.map(plan=>
          <div className='pay-card'>
            <div className='pay-card-head'>
              <h2>{plan?.product?.name}</h2>
              <p>{plan?.product?.description}</p>
            </div>
            <div className='pay-card-cont'>
              <p className='pay-card-cont-amnt'>₹ {(plan?.unit_amount)/100}</p>
            <p className='pay-card-cont-intvl'><span>per</span><span>{plan?.recurring?.interval}</span></p>
            </div>
            <div>
              
            <button className='pay-card-but' onClick={()=>{handleSubscribe(plan)}}>Subscribe</button>
            </div>
          </div>
        )}
      </div>
      {checkoutFormModal && 
        <CheckoutForm setCloseModal={setCheckoutFormModal} plan={plan} />

      }
    </div>
  )
}

export default Pay