import fetch from 'isomorphic-fetch';
import configVariables from './constants';
import storageService from "./storageService";


export default function callApi(endpoint, method = 'get', body={}, external=false) {
  const API_URL = external ? `${configVariables.data_link}/api_data` : `/api`
  let headers = {};
  let token = storageService.get(configVariables.USER_JWT_TOKEN);

  if (token && token !== '') {
    headers.Authorization = `Bearer ${token}`;
  }
  headers['content-type'] = 'application/json';
  headers['userId'] = storageService.get(configVariables.USER_ID);


  return fetch(`${API_URL}/${endpoint}`, {
    headers: headers,
    method,
    ...(method !== 'get' && { body: JSON.stringify(body) })
  }).then(response => response.json().then(json => ({json, response})))
    .then(({json, response}) => {
      if(json.status === 'Access Denied'){
        // alert('Access Denied. Please login')
        storageService.remove(configVariables.USER_ID)
        storageService.remove(configVariables.USER_ROLE)
        storageService.remove(configVariables.USER_JWT_TOKEN)
        window.location.reload()
      }
      if (!response.ok) {
        return Promise.reject(json);
      }
      
      return json;
    })
    .then(
      response => response,
      error => error
    );
}
