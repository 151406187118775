import React, { useEffect, useState } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function CircularProgressChart({data}) {

  const [value, setvalue] = useState(0)

  useEffect(() => {
    if(data?.length>0){
      setvalue(data[0].value)
    }
  }, [data])

  return (
    <>
      <GradientSVG></GradientSVG>
      <CircularProgressbar 
        value={value} 
        text={`${value}%`} 
        strokeWidth={3}
        styles={buildStyles({
          strokeLinecap: 'butt',
          textSize: '16px',
          pathColor: 'linear-gradient(315deg, #63a4ff 0%, #83eaf1 74%)',
          trailColor: '#1E1E2D',
        })}
      />
    </>
  )
}

export default CircularProgressChart


function GradientSVG() {
  let startColor = '#63a4ff' 
  let endColor = '#83eaf1'
  let idCSS= 'progress-gradient' 
  let rotation = '315deg';

  let gradientTransform = `rotate(${rotation})`;
  return (
    <svg style={{ height: 0 }} className='bar-grad-svg'>
    <defs>
      <linearGradient id={idCSS} gradientTransform={gradientTransform}>
        <stop offset="0%" stopColor={startColor} />
        <stop offset="100%" stopColor={endColor} />
      </linearGradient>
    </defs>
    </svg>
  )
}
