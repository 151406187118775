import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DeleteBusiness, DeleteProject, fetchDeviceInBusiness, fetchUserDetails } from '../../appActions';
import AddBusinessForm from '../../Components/AddBusinessForm';
import AddProjectForm from '../../Components/AddProjectForm';
import './Business.css'
import mail_logo from '../../Assets/Icons/mail.png'
import phone_logo from '../../Assets/Icons/phone.png'
import loc_logo from '../../Assets/Icons/loc.png'
import edit_logo from '../../Assets/Icons/edit.png'
import trash_logo from '../../Assets/Icons/trash.png'
import add_big_logo from '../../Assets/Icons/add_big.png'
import add_logo from '../../Assets/Icons/add.png'
import configVariables from '../../util/constants';
import AddManagerSuperForm from '../../Components/AddManagerSuperForm';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Header from '../dashboardheader/header';
import useMediaQuery from '@mui/material/useMediaQuery';

function Business() {

  const dispatch = useDispatch()
  const state = useSelector((state) => state);
  const nav = useNavigate()
  const isMobile = useMediaQuery('(max-width: 766px)');

  const [addBusinessModal, setAddBusinessModal] = useState(false)
  const [addProjectModal, setAddProjectModal] = useState(false)
  const [addManagerSuperModal, setAddManagerSuperModal] = useState(false)
  const [editBusinessModal, setEditBusinessModal] = useState({})
  const [editProjectModal, setEditProjectModal] = useState({})

  useEffect(() => {
    if(!state.user_det ){
      dispatch(fetchUserDetails())
    }
  }, [editProjectModal])

  const handleEdit = (business_det)=>{
    setEditBusinessModal(business_det)
  }
  const handleEditProject = (project_det)=>{
    let query = {
      business_id:project_det.business_id,
      project_id:null
    }
    dispatch(fetchDeviceInBusiness(query))
    setEditProjectModal(project_det)
  }
  
  const handleAddProject = (business_id)=>{
    let query = {
      business_id:business_id,
      project_id:null
    }
    dispatch(fetchDeviceInBusiness(query))
    setAddProjectModal(business_id)
  }

  const deleteBusiness = (business_det)=>{
    dispatch(DeleteBusiness(business_det)).then((res)=>{
      if(res){
        setEditBusinessModal(false)
      }else{
        alert(configVariables.error_contact_message)
      }
    })
  }

  const deleteProject = (project_det)=>{
    if(window.confirm('Are you certain you wish to remove this project? All related data will also be deleted.')){
      dispatch(DeleteProject({_id:project_det._id, business_id:project_det.business_id}))
    }
  }

  return (
    <div className='biz-top containor'>
       {isMobile && <Header />}
      <div className="biz-header noti-header">
        <h1>Business</h1>
        <div className="report-data-opts-top biz-opts-top">
          <div onClick={()=>{setAddManagerSuperModal(true)}} className="graph-header-widget noti-add-but ptr">
            <p>Add Manager</p>
            {/* <img src={add_logo} alt="" /> */}
            <AddIcon/>
          </div>
          <div onClick={()=>{setAddBusinessModal(true)}} className="graph-header-widget noti-add-but ptr">
            <p>Add Business</p>
            {/* <img src={add_logo} alt="" /> */}
            <AddIcon/>
          </div>
          <div onClick={()=>{nav('/subscription_details')}} className="graph-header-widget noti-add-but ptr">
            <p>Recharge</p>
          </div>
        </div>
      </div>
      {state?.user_det?.business_id?.length === 0 && <p className='empty-message'>No business to show. Please create one!</p>}
      {state?.user_det?.business_id?.map(business=>{
        return (
          <div className='biz-box'>
            <div className="det-box">
              <div className="biz-det">
                <div className="biz-det-opts">
                  <div onClick={()=>{handleEdit(business)}} className="opt-box ptr">
                    {/* <img src={edit_logo} alt="" /> */}
                    <EditIcon/>
                  </div>
                  <div className="opt-box ptr" onClick={() => {
                      if (window.confirm("Are you sure you want to delete this business?")) {
                        deleteBusiness(business);
                      }
                    }}>
                    <DeleteIcon/>
                  </div>

                </div>
                <h1>{business.name}</h1>
                {/* <p className='biz-p'><img src={mail_logo} alt="" />{business.email}</p> */}
                <p className='biz-p'><EmailIcon />{business.email}</p>
                {/* <p className='biz-p'><img src={phone_logo} alt="" />{business.phone}</p>
                <p className='biz-p-2'><img src={loc_logo} alt="" />{business.address}</p> */}
                {/* Phone */}
                <p className='biz-p'><PhoneIcon />{business.phone}</p>

                {/* Location */}
                <p className='biz-p-2'><LocationOnIcon />{business.address}</p>
                <h3>Total Devices : {business.device_id.length}</h3>
              </div>
            </div>
            <div className="proj-cards-top">
              {business.project_id.map(project=>{
                return(
                  <div className="proj-card">
                    <div className="proj-card-header">
                      <h1>{project.name}</h1>
                      <div className="proj-card-opts">
                        <div onClick={()=>{handleEditProject(project)}} className="opt-box ptr">
                          {/* <img src={edit_logo} alt="" /> */}
                          <EditIcon/>
                        </div>
                        <div className="opt-box ptr">
                          <DeleteIcon onClick={() => {
                            if (window.confirm("Are you sure you want to delete this project?")) {
                              deleteProject(project);
                            }
                          }} />
                        </div>

                      </div>
                    </div>
                    <div className="proj-card-cont">
                      <p>Manager : <span>{project?.manager_id?.username}</span></p>
                      <p>Total Devices : <span>{project.device_id.length}</span></p>
                    </div>
                  </div>
                    )
                  })}
                  <div onClick={()=>{handleAddProject(business._id)}} className="proj-card proj-add-card ptr">
                    <h1>Add <br /> Project</h1>
                    {/* <img src={add_big_logo} alt="" /> */}
                    <AddIcon/>
                  </div>
            </div>
          </div>
        )   
        })}
      
      {addProjectModal && 
        <AddProjectForm 
          devices={state.devicesInBusiness} 
          user_det={{b_id:addProjectModal,...state.user_det}} 
          setCloseModal={setAddProjectModal} 
        />
      }
      {addBusinessModal && 
        <AddBusinessForm 
          setCloseModal={setAddBusinessModal} 
          user_email={state.user_det.contact_email} 
        />
      }
      {Object.keys(editBusinessModal).length !== 0 && 
        <AddBusinessForm 
          setCloseModal={setEditBusinessModal} 
          edit_business_det={editBusinessModal} 
          mode='edit' 
          deleteBusiness={deleteBusiness}
        />
      }
      {Object.keys(editProjectModal).length !== 0 && 
        <AddProjectForm 
          devices={state.devicesInBusiness} 
          edit_project_det={editProjectModal} 
          setCloseModal={setEditProjectModal} 
          mode='edit' 
        />
      }
      {addManagerSuperModal &&
        <AddManagerSuperForm
          setCloseModal={setAddManagerSuperModal}
          role={configVariables.role_manager}
        />
      }
    </div>
  )
}
  
export default Business