import React from 'react';
import './Table.css'; // Import your CSS file for styling

const Tablechart = ( data ) => {
  // Check if data is an object and contains the 'data' property
  if (!data || !Array.isArray(data.data)) {
    return <div>Error: Data is not an array or is in an invalid format</div>;
  }

  const dataValues = data.data;

  // Check the structure of dataValues
  if (dataValues.length === 0 || !dataValues.every(item => item.hasOwnProperty('datetime') && item.hasOwnProperty('value'))) {
    return <div>Error: Data does not have the expected structure</div>;
  }

  return (
    <div className="table-container">
      <div className="table-wrapper">
        <table className="responsive-table" border="5" cellPadding="5" cellSpacing="0">
          <thead>
            <tr>
              <th>Date Time</th>
              <th>Value</th>
             
            </tr>
          </thead>
          <tbody>
            {dataValues.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.datetime}</td>
                  <td>{item.value.replace(/\+/g, ',')}</td>
                  
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Tablechart;
