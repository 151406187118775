
import {
    Airspeed,
    Altimeter,
    AttitudeIndicator,
    HeadingIndicator,
    TurnCoordinator,
    Variometer,
} from "react-typescript-flight-indicators";
import React, { useState, useEffect, useRef } from 'react';
import './altitude.css';
import { set } from "lodash";

const FlightGps = ({ data }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [gpsValues, setgpsValues] = useState([]);
    const [airspeed, setairspeed] = useState([]);
    const [groundspeed, setgroundspeed] = useState(0);
    const [gps, setgps] = useState(0);
    const [voltage, setvoltage] = useState(0);
    const [ampere, setampere] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const [lastgps,setlastgps]=useState(0)
    const [lastProcessedDatetime, setLastProcessedDatetime] = useState(localStorage.getItem('lastProcessedDatetime'));

    const processCurrentData = (currentData) => {
        // console.log('navigation',currentData)
        const [gpsstart, gpsend,voltage,ampere, airspeed,groundspeed] = currentData.value.split('+');
        const gpsArray = [];
        
        for (let i = parseFloat(gpsstart); i <= parseFloat(gpsend); i++) {
            gpsArray.push(i);
        }
       

        setgpsValues(gpsArray);
        setvoltage(voltage);
        setampere(ampere);
        setCurrentIndex(0);
        setgps(gpsArray[0]);
        setairspeed(airspeed)
        setgroundspeed(groundspeed)
        
        localStorage.setItem('lastgps', gpsend);
        localStorage.setItem('lastvoltage', voltage);
        localStorage.setItem('lastampere', ampere);
        localStorage.setItem('lastgroundspeed',groundspeed)
        localStorage.setItem('lastairspeed',airspeed)
       
        if (intervalId) {
             clearInterval(intervalId);
        }
        setLastProcessedDatetime(currentData.datetime);
        localStorage.setItem('lastProcessedDatetime', currentData.datetime);

        const id = setInterval(() => {
            setCurrentIndex(prevIndex => {
                const newIndex = prevIndex + 1;
                if (newIndex < gpsArray.length) {
                    setgps(gpsArray[newIndex]);
                    
                    return newIndex;
                } else {
                    clearInterval(id);
                    return prevIndex;
                }
            });
        }, 1200);

        setIntervalId(id);
    };
    useEffect(() => {
        if (data && data.length > 0) {
            const currentData = data[0];
            if (lastProcessedDatetime !== currentData.datetime) {
                processCurrentData(currentData);
            }
        } else {
            
            const lasgps = localStorage.getItem('lastgps');
            const lastvoltage = localStorage.getItem('lastvoltage');
            const lastampere = localStorage.getItem('lastampere');
            const lastgroundspeed=localStorage.getItem('lastgroundspeed')
            const lastairspeed=localStorage.getItem('lastairspeed')
            

            if (lasgps && lastvoltage && lastampere && lastgroundspeed && lastairspeed ) {
                setgps(lasgps)
                setvoltage(lastvoltage)
                setampere(lastampere)
                setairspeed(lastairspeed)
                setgroundspeed(lastgroundspeed)
               
            }
        }
    }, [data, lastProcessedDatetime]);


    useEffect(() => {
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [intervalId]);
    if (!data || data.length === 0) {
        return <p>No data available</p>;
    }
    return (
                   <div className="flight-card" style={{borderRadius:'5px'}} id="fg">
                <div className="flight-items">
               
                     <p id="myfont">Volt:{voltage} v</p>
                    <p id="myfont">Amp:{ampere} amp</p> 
                </div>
                <div className="heading">
                    <HeadingIndicator heading={parseFloat(gps)} showBox={false} />
                    
                </div>
                <div className="flight-items">
                <p id="myfont">AS:{airspeed} m/s</p>
                    <p id="myfont">GS:{groundspeed} m/s</p>
                    {/* <p>volt:{volt} v</p>
                    <p>ampere:{ampere} amp</p> */}
                </div>
                
            </div>
    );
};

export default FlightGps;

