import React, { useState } from 'react'
import { useEffect } from 'react'
import cross_logo from '../Assets/Icons/cross.png'
import {
  PaymentElement,
  useElements,
  CardElement,
  Elements,
  useStripe
} from "@stripe/react-stripe-js";
import callApi from '../util/callApi';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserDetails } from '../appActions';



const stripePromise = loadStripe(
  'pk_test_51N3df3SF3bREshy6tW9SO0u6kIkDOQ4OHEzEd7BiIAHMXdvOayH8DQRatkSOlAz5U5Zxy9oclZj3lNzBqOayAqvR00qzUm1Cbs'
);



function CheckoutForm({ setCloseModal, plan }) {

  const [clientSecret, setClientSecret] = useState('')
  const state = useSelector((state) => state);
  const nav = useNavigate()
  const dispatch = useDispatch()


  useEffect(() => {
    createPaymentIntent(plan?.unit_amount, 'inr')
    dispatch(fetchUserDetails())
  }, [])


  const createPaymentIntent = async (amount, currency) => {
    try {
      const response = await callApi('/create-payment-intent', 'post', { amount, currency })

      if (response) {
        setClientSecret(response.clientSecret);
      } else {
        throw new Error('Failed to create PaymentIntent');
      }
    } catch (error) {
      // Handle error appropriately
    }
  };

  const Subscribe = async () =>{
    const email = state.user_det.contact_email
    callApi('subscribe','post',{
      email : email, 
      plan: plan,
      description: 'Test transaction - dummy description',
    }).then(async res=>{
      if(res.status === 'Success'){
        nav('/')
      }else if(res.status === 'Failed'){
        alert(res.data)
      }else{
        alert("An error occured. If money deducted please contact the customer support.")
      }
    })
  }

  return (
    clientSecret &&
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <CheckoutForm2 setCloseModal={setCloseModal} Subscribe={Subscribe}/>

    </Elements>


  )
}

const CheckoutForm2 = ({ setCloseModal, Subscribe }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [checkoutStarted, setCheckoutStarted] = useState(false)

  const handleSubmit = async (event) => {
    setCheckoutStarted(true)
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    // Just use the clientSecret from the PaymentIntent to confirm the payment
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_ENV == 'dev' ? 'http://localhost:3000' : 'https://cloud.abhram.com'
      },
      redirect:'if_required'
    });
    if (error) {
      // Handle error
      alert(error.message);
    } else if (paymentIntent.status === 'succeeded') {
      // Handle successful payment
      // Your logic for handling successful payment (e.g., updating the UI, storing order info, etc.)
      Subscribe()
      alert('Payment successful!');
      handleClose()
    }
  };


  const handleClose = () => {
    setCheckoutStarted(false)
    setCloseModal(false)
  }


  return (
    <div className='modal'>
      <form onSubmit={handleSubmit}>
        <img onClick={() => { handleClose() }} className='form-cross-but' src={cross_logo} alt="" />
        <label htmlFor="" className='form-label'>
          <PaymentElement />

        </label>
        <button className='checkout-btn' disabled={checkoutStarted} type='submit'>Checkout</button>

      </form>
    </div>

  );
};

export default CheckoutForm