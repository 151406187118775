// import React, { useEffect } from 'react'
// import './Sidebar.css'
// import dash_logo from '../../Assets/Icons/dashboard.png'
// import biz_logo from '../../Assets/Icons//biz.png'
// import device_logo from '../../Assets/Icons/devices.png'
// import report_logo from '../../Assets/Icons/report.png'
// import noti_logo from '../../Assets/Icons/noti.png'
// import about_logo from '../../Assets/Icons/about.png'
// import cross_logo from '../../Assets/Icons/cross.png'
// import { Link, useLocation } from 'react-router-dom'
// import storageService from '../../util/storageService'
// import configVariables from '../../util/constants'
// import { useDispatch, useSelector } from 'react-redux'
// import { useState } from 'react'
// import { OpenMobileNav} from '../../appActions'

// function Sidebar() {

//   const sidebarCollapsed = useSelector((state) => state.nav_state);
//   const [activeNav, setActiveNav] = useState(window.location.pathname)
//   const [userRole, setUserRole] = useState('')
//   const location = useLocation()
//   const dispatch = useDispatch()

//   useEffect(() => {
//     setActiveNav(location.pathname)
//     dispatch(OpenMobileNav(false))
//     setUserRole(storageService.get(configVariables.USER_ROLE))
//   }, [location])


//   const anchors = [
//     {
//       name: "Dashboard",
//       icon: dash_logo,
//       to: '/'
//     },
//     {
//       name: "Business",
//       icon: biz_logo,
//       to: '/manage/business'
//     },
//     {
//       name: "Project",
//       icon: biz_logo,
//       to: '/manage/project'
//     },
//     {
//       name: "Devices",
//       icon: device_logo,
//       to: '/manage/devices'
//     },
//     {
//       name: "Report",
//       icon: report_logo,
//       to: '/report'
//     },
//     {
//       name: "Notification",
//       icon: noti_logo,
//       to: '/notification'
//     }
//   ]
//   const adminAnchors = [
//     {
//       name: "Devices",
//       icon: device_logo,
//       to: '/admin'
//     },
//     {
//       name: "Types",
//       icon: about_logo,
//       to: '/admin/types'
//     }
//   ]

//   const handleMobileNavClose = () => {
//     dispatch(OpenMobileNav(false))
//   }
//   return (
//     <div className={`sidebar-top ${sidebarCollapsed ? 'collapsed-sidebar-top' : ''}`}>
//       {/* {!sidebarCollapsed && <div className="collapse-but" onClick={() => { setSidebarCollapsed(true) }}></div>} */}
//       {/* <img onClick={handleMobileNavClose} src={cross_logo} className='mobile-nav-cross' alt="" /> */}

//       <div className="menu-top">

//         <div className="menu-box-1">
//           {userRole !== configVariables.role_admin ?
//             <>
//               {anchors.map(anchor => {
//                 if (anchor.name === 'Business') {
//                   if (userRole === configVariables.role_owner) {
//                     return <div className={`${activeNav === anchor.to ? 'nav-active' : ''} menu-item-top`}>
//                       <Link to={anchor.to}><img src={anchor.icon} alt="menu_icon" /></Link>
//                       <Link className='menu-label' to={anchor.to}>{anchor.name}</Link>
//                     </div>
//                   }
//                 } else if (anchor.name === 'Project') {
//                   if (userRole === configVariables.role_manager || userRole === configVariables.role_owner) {
//                     return <div className={`${activeNav === anchor.to ? 'nav-active' : ''} menu-item-top`}>
//                       <Link to={anchor.to}><img src={anchor.icon} alt="menu_icon" /></Link>
//                       <Link className='menu-label' to={anchor.to}>{anchor.name}</Link>
//                     </div>
//                   }
//                 } else {
//                   return <div className={`${activeNav === anchor.to ? 'nav-active' : ''} menu-item-top`}>
//                     <Link to={anchor.to}><img src={anchor.icon} alt="menu_icon" /></Link>
//                     <Link className='menu-label' to={anchor.to}>{anchor.name}</Link>
//                     {/* {anchor.name === 'Notification' && !sidebarCollapsed && <div className='noti-count'><p>5</p></div>} */}
//                   </div>
//                 }
//               })}
//             </>

//             :
//             adminAnchors.map(anchor => (
//               <div className={`${activeNav === anchor.to ? 'nav-active' : ''} menu-item-top`}>
//                 <Link to={anchor.to}><img src={anchor.icon} alt="menu_icon" /></Link>
//                 {!sidebarCollapsed && <Link to={anchor.to}>{anchor.name}</Link>}
//                 {/* {anchor.name === 'Notification' && !sidebarCollapsed && <div className='noti-count'><p>5</p></div>} */}
//               </div>
//             ))}
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Sidebar

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { OpenMobileNav } from '../../appActions';
import storageService from '../../util/storageService';
import configVariables from '../../util/constants';
import './Sidebar.css';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessIcon from '@mui/icons-material/Business';
import ProjectIcon from '@mui/icons-material/AccountTree';
import DevicesIcon from '@mui/icons-material/Devices';
import ReportIcon from '@mui/icons-material/Assessment';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TypesIcon from '@mui/icons-material/Category';
import CloseIcon from '@mui/icons-material/Close'; // For the cross icon

function Sidebar() {
  const sidebarCollapsed = useSelector((state) => state.nav_state);
  const [activeNav, setActiveNav] = useState(window.location.pathname);
  const [userRole, setUserRole] = useState('');
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    setActiveNav(location.pathname);
    dispatch(OpenMobileNav(false));
    setUserRole(storageService.get(configVariables.USER_ROLE));
  }, [location, dispatch]);

  const anchors = [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      to: '/'
    },
    {
      name: "Business",
      icon: <BusinessIcon />,
      to: '/manage/business'
    },
    {
      name: "Project",
      icon: <ProjectIcon />,
      to: '/manage/project'
    },
    {
      name: "Devices",
      icon: <DevicesIcon />,
      to: '/manage/devices'
    },
    {
      name: "Report",
      icon: <ReportIcon />,
      to: '/report'
    },
    {
      name: "Notification",
      icon: <NotificationsIcon />,
      to: '/notification'
    }
  ];

  const adminAnchors = [
    {
      name: "Devices",
      icon: <DevicesIcon />,
      to: '/admin'
    },
    {
      name: "Types",
      icon: <TypesIcon />,
      to: '/admin/types'
    }
    
  ];

  const handleMobileNavClose = () => {
    dispatch(OpenMobileNav(false));
  }

  return (
    <div className={`sidebar-top ${sidebarCollapsed ? 'collapsed-sidebar-top' : ''}`}>
      <div className="menu-top">
        <div className="menu-box-1">
          {userRole !== configVariables.role_admin ? (
            anchors.map(anchor => {
              if (anchor.name === 'Business' && userRole !== configVariables.role_owner) return null;
              if (anchor.name === 'Project' && !(userRole === configVariables.role_manager || userRole === configVariables.role_owner)) return null;
              return (
                <div key={anchor.name} className={`${activeNav === anchor.to ? 'nav-active' : ''} menu-item-top`}>
                  <Link to={anchor.to}>{anchor.icon}</Link>
                  <Link className='menu-label' to={anchor.to}>{anchor.name}</Link>
                </div>
              );
            })
          ) : (
            adminAnchors.map(anchor => (
              <div key={anchor.name} className={`${activeNav === anchor.to ? 'nav-active' : ''} menu-item-top`}>
                <Link to={anchor.to}>{anchor.icon}</Link>
                {!sidebarCollapsed && <Link className='menu-label' to={anchor.to}>{anchor.name}</Link>}
              </div>
            ))
          )}
        </div>
      </div>
      {/* <CloseIcon onClick={handleMobileNavClose} className='mobile-nav-cross' /> */}
    </div>
  )
}

export default Sidebar;
