import React, { useEffect, useState } from 'react'
import ReactSpeedometer from 'react-d3-speedometer';

function SingleValue({ data, no_divs, unit }) {
  const [currentValue, setCurrentValue] = useState(0);
  

  useEffect(() => {
    if (data && data.length > 0) {
      // Convert the value to an integer
      let last_value = parseInt(data[0].value, 10); // The second argument is the radix (base) for the number system
      if (!isNaN(last_value) && currentValue !== last_value) {
        setCurrentValue(last_value);
      }
    }
  }, [data, currentValue]);
  return (
    <div style={{ width: '190px', height: '100px' }}>
      <ReactSpeedometer
        value={currentValue}
        maxValue={10}  // Set maxValue to 10
        needleColor="gray"
        segments={10}  // Number of segments
        segmentColors={Array(10).fill('#FF8C00')}  // Orange color for all segments
        customSegmentStops={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}  // Customize segment stops
        segmentLabel="none"  // Hide default segment labels
        currentValueText={`${currentValue} ${unit}`}
        textColor="#FFFFFF"
        width={200}
        height={150}
        ringWidth={20}  // Narrower segments
        needleTransitionDuration={2000}
        needleTransition="easeElastic"
      />
    </div>
  );
}

export default SingleValue;
