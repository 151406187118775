import { ADD_EDIT_DEVICE, ADD_GRAPH, ADD_PROJECT, ADD_SENSOR, DELETE_BUSINESS, FETCH_DEVICE, FETCH_DEVICES, FETCH_GRAPH, FETCH_GRAPH_DATA, FETCH_KEYS, FETCH_NOTIFICATIONS, FETCH_PROJECTS, FETCH_SUBSCRIPTION, FETCH_TYPES, GET_USER, GET_USER_AND_PROJECT, LOGIN_USER, OPEN_CLSOE_NAV, RESET_STATE, SEND_OTP, SET_BUSINESS, SET_KEY, SET_USER, USERS_IN_BUSINESS, VERIFY_DEVICE, VERIFY_MULTI_DEVICES } from "./appActions";

const initialState = {
  user_det: {},
  business_det:{},
  isOtpSent:false,
  isDeviceVerified:false,
  otp_message:''
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user_det: action.user_det,
        signupStatus: action.signupStatus,
      };
    case SET_BUSINESS:
      return {
        ...state,
        business_det: action.business_det,
      };
    case SEND_OTP:
      return {
        ...state,
        isOtpSent: action.isOtpSent,
        otp_message: action.message,
        device_det: action.device_det,
      };
    case VERIFY_DEVICE:
     return {
        ...state,
        isDeviceVerified: action.isDeviceVerified,
        verification_message:action.message
     };
    case SET_KEY:
      return{
        ...state,
        key_det:action.key_det,
        verification_message: action.verification_msg
      }
    case LOGIN_USER:
      return{
        ...state,
        login_msg:action.login_msg,
        login_status: action.login_status
      }
    case GET_USER:
      return{
        ...state,
        user_det:action.user_det
      }
    case GET_USER_AND_PROJECT:
      return{
        ...state,
        user_project_det:action.user_project_det
      }
    case DELETE_BUSINESS:
      return{
        ...state,
        deleteStatus :action.delete_status
      }
    case FETCH_DEVICES:
      return{
        ...state,
        devicesInBusiness :action.devicesInBusiness
      }
    case ADD_PROJECT:
      return{
        ...state,
        project_created :action.project_created
      }
    case ADD_EDIT_DEVICE:
      return{
        ...state,
        device_det: action.device_det,
        device_updated: action.device_updated
      }
    case VERIFY_MULTI_DEVICES:
      return{
        ...state,
        devices_verified: action.devices_verified,
        message: action.message
      }
    case FETCH_PROJECTS:
      return{
        ...state,
        projects_det: action.projects
      }
    case ADD_GRAPH:
      return{
        ...state,
        graph_added: action.graph_added
      }
      
    case FETCH_GRAPH:
      
      let dataQuery = action.graph_det.map(graph=>{
        let obj = {
          
          sensor_no: graph.sensor_id.sensor_no,
          topic: graph.device_id.topic,
          limit: graph.fetch_data_period,
          timequery:{end_date:graph.sensor_id?.last_save_date},
          merged_graphs:[]
        }
        if(graph.merged_graphs?.length>0){
          graph.merged_graphs.map(graph=>{
            obj.merged_graphs.push({
              sensor_no: graph.sensor_id.sensor_no,
              topic: graph.device_id.topic,
              limit: graph.fetch_data_period,
            })
          })
        }
        return obj
      })
      return{
        ...state,
        graph_det: action.graph_det,
        data_query: dataQuery,
        disabled_graph_det: action.disabled_graph_det
      }
    case FETCH_DEVICE:
      return{
        ...state,
        device_det: action.device_det
      }
    case ADD_SENSOR:
      return{
        ...state,
        sensor_created: action.sensor_created
      }
    case FETCH_KEYS:
      return{
        ...state,
        reg_keys: action.reg_keys
      }
    case OPEN_CLSOE_NAV:
      return{
        ...state,
        nav_state: action.nav_state
      }
    case FETCH_NOTIFICATIONS:
      return{
        ...state,
        notifications: action.notifications
      }
    case USERS_IN_BUSINESS:
      return{
        ...state,
        users_in_business: action.users_in_business
      }
    case FETCH_SUBSCRIPTION:
      return{
        ...state,
        subscription: action.subscription
      }
    case FETCH_TYPES:
      return{
        ...state,
        types: action.types
      }
    case RESET_STATE:
      return{}
    // case FETCH_GRAPH_DATA:
    
    //   return{
    //     ...state,
    //     graph_data: plotData
    //   }

    default:
      return state;
  }
};

export default AppReducer