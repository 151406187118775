import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AddProject, EditProject } from '../appActions';
import Select from 'react-select'
import cross_logo from '../Assets/Icons/cross.png'
import storageService from '../util/storageService';
import configVariables from '../util/constants';
import colourStyles from '../util/reactSelectStyle';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    
    backgroundColor: 'rgba(47, 47, 47, 1)',
    boxShadow: '0px 7.2px 10.8px 5.4px rgba(0, 0, 0, 0.15), 0px 3.6px 3.6px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',
    border: 'none',
    color: 'white',
    height: '50px',
    padding: '10px',
    width: '100%',
    gridArea: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px'
    
  }),
  menu: (provided) => ({
    ...provided,
        background:'rgba( 76, 76, 76, 0.65 )',
    backdropFilter:'blur( 9.5px )',
    borderRadius: '10px',
    border: '1px solid rgba( 255, 255, 255, 0.18 )',
    // backgroundColor: '#4a4948',
    fontSize:'12px',
    
    // border:'1px solid #7469B6',
    color: 'white',
    fontSize:'12px'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '' : 'transparent',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#303043',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'white',
    ':hover': {
      backgroundColor: '#FB493F',
      color: 'white',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'white',
  }),
};

function AddProjectForm({devices,user_det, setCloseModal, edit_project_det, mode}) {

  const { register, handleSubmit, control, reset } = useForm();
  const dispatch = useDispatch()


  const [formErrors, setFormErrors] = useState('')
  const [selectOptions, setSelectOptions] = useState('')
  const [deviceOptions, setDeviceOptions] = useState([])
  const [deviceOptionsCopy, setDeviceOptionsCopy] = useState([])

  useEffect(() => {
    let options = devices?.map(device=>{
      return(
        {
          value:device._id,
          label:device.name
        }
      )
    })
    setSelectOptions(options)

    setDeviceOptions(edit_project_det?.device_id?.map(c => {return {label:c.name,value:c._id}}))
    setDeviceOptionsCopy(edit_project_det?.device_id?.map(c => c._id))
  }, [devices])

  useEffect(() => {
    reset(edit_project_det)
  }, [edit_project_det])
  

  const onSubmit = (data) => {
    data.device_id = deviceOptions.map(device=>device.value)
    if(mode === "edit"){
      data._id = edit_project_det._id
      let old_devices = deviceOptions.map(d=>d?.value)
      old_devices = deviceOptionsCopy.filter(d=>!old_devices.includes(d))
      data.old_device_id = old_devices
      dispatch(EditProject(data)).then(res=>{
        if(res){
          setCloseModal(false)
        }else{
          alert(configVariables.error_contact_message)
        }
      })
    }else{
      data.business_id = user_det.b_id
      data.owner_id = storageService.get(configVariables.USER_ID)
      dispatch(AddProject(data)).then((res)=>{
        if(res){
          setCloseModal(false)
        }else{
          alert(configVariables.error_contact_message)
        }
      })
    }
  };

  const handleDeviceSelect = (val,action)=>{
    if(action.action === 'select-option'){
      setDeviceOptions(val)
    }
    else if(action.action === 'remove-value'){
      setDeviceOptions(deviceOptions.filter(device=>val.includes(device)))
    }
  }
  
  return (
    <div className='modal'>
      <form action="" >
        {formErrors && <p>{formErrors}</p>}
        <img onClick={()=>{setCloseModal(false)}} className='form-cross-but' src={cross_logo} alt="" />
        <label className='form-label' htmlFor="">
          Project Name
          <input type="text" {...register('name',{ required: true, maxLength: 20 })}/>
        </label>

        <label className='form-label' htmlFor="">
          Email
          <input type="email" {...register('email',{ required: true })} value={user_det?.contact_email}  readOnly/>
        </label>

        <label className='form-label' htmlFor="">
          Phone No
          <input type="text" {...register('phone',{ required: true, maxLength: 12, pattern: /^[0-9]+$/i })} value={user_det?.phone_number} readOnly />
        </label>

        <label className='form-label' htmlFor="">
          Project Address
          <textarea {...register('address', { required: true })} id="" cols="30" rows="10"></textarea>
        </label>
        
        <label className='form-react-select' htmlFor="">
          Select Devices
          {selectOptions &&
          <Controller
          control={control}
          name="device_id"
          rules={{ required: true }}
          render={({ field: { onChange, value, ref }}) => (
            <Select
              className='random'
              inputRef={ref}
              value={deviceOptions}
              onChange={(val,action) => onChange(()=>{handleDeviceSelect(val,action)})}
              options={selectOptions}
              isMulti
              styles={customStyles}
            />
          )}
          />
          }
           
        </label>
        
        <div className="form-but-group">
          <button className='form-but-1' onClick={handleSubmit(onSubmit)} type="button">
          {mode === "signup"?
            'Register'
          :
          mode === 'edit'?
            'Save'
          :
            'Add'
          }
          </button>
        </div>
        {/* {mode === 'edit' && <button onClick={()=>{deleteBusiness(edit_project_det._id)}} type='button'>Delete</button>}  */}
      </form>
    </div>
  )
}



export default AddProjectForm