import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import configVariables from '../../util/constants';
import storageService from '../../util/storageService';
import callApi from '../../util/callApi';
import Pay from '../../Pages/Pay/Pay';
import InactiveAccount from '../../Pages/Error/InactiveAccount';
import Swal from 'sweetalert2';
import './subscribe.css'

function SubscriptionLayout() {

  const [subscribed, setSubscribed] = useState(null)

  const nav = useNavigate()
  useEffect(() => {
    if (!storageService.get(configVariables.USER_ID)) {
      return nav('/login')
    }
    checkSubscriptionStatus()
  }, [])

  const checkSubscriptionStatus = async () => {
    // Check if the user has an active Stripe subscription
    try {
      const { data } = await callApi("check-subscription-status", 'post', { userId: storageService.get(configVariables.USER_ID) });
      if (data.isSubActive) {
        setSubscribed(<Outlet />)
        if (!data.isDataLeft) {
          Swal.fire({
            title: 'Data exhausted',
            text: 'Please recharge!',
            icon: 'warning',
            showCloseButton: true,
            confirmButtonText: 'Recharge Now',
            customClass: {
              container: 'custom-swal-container',
              popup: 'custom-swal-popup',
              header: 'custom-swal-header',
              title: 'custom-swal-title',
              closeButton: 'custom-swal-close-button',
              icon: 'custom-swal-icon',
              content: 'custom-swal-content',
              actions: 'custom-swal-actions',
              confirmButton: 'custom-swal-confirm-button',
              cancelButton: 'custom-swal-cancel-button'
            }
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/pay';
            }
          });
          const alertModal = document.querySelector('.swal2-popup');
        if (alertModal) {
          alertModal.style.zIndex = 9999;
        }
        }
      } else {
        if (storageService.get(configVariables.USER_ROLE) === configVariables.role_owner) {
          setSubscribed(<Pay showLogOut />)
        } else {
          setSubscribed(<InactiveAccount />)
        }
      }
    } catch (err) {
      window.location.href = '/login';
    }
  };


  return(subscribed)
}

export default SubscriptionLayout