import React from 'react'
import { useForm } from 'react-hook-form';
import callApi from '../util/callApi';
import cross_logo from '../Assets/Icons/cross.png'


function ChangePasswordForm({setCloseModal,user_email}) {
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (data)=>{
    try{
      if(!data.new_password === data.re_new_password){
        alert("Passwords doesn't match!")
      }
      const res = await callApi('/change_password','post',{email:user_email,curr_password:data.current_password,new_password:data.new_password})
      alert(res.data)
      reset()
      setCloseModal(false)
    }catch(err){
      alert(err.data)
    }
  }

  return (
    <div className='modal'>
      <form >
        <img onClick={()=>{setCloseModal(false)}} className='form-cross-but' src={cross_logo} alt="" />
        <label className='form-label' htmlFor="">
          Current Password
          <input type="password" {...register('current_password',{ required: true })}/>
        </label>
        <label className='form-label' htmlFor="">
          New Password
          <input type="password" {...register('new_password',{ required: true })}/>
        </label>
        <label className='form-label' htmlFor="">
          Re-type New Password
          <input type="password" {...register('re_new_password',{ required: true })}/>
        </label>
        <div className="form-but-group">
          <button className='form-but-1' onClick={handleSubmit(onSubmit)} type="button">Change</button>
          </div>
      </form>
    </div>
  )
}

export default ChangePasswordForm