const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: '#161622', height:'50px', border:'none', boxShadow: '0px 7.2px 10.8px 5.4px rgba(0, 0, 0, 0.15), 0px 3.6px 3.6px rgba(0, 0, 0, 0.3)',borderRadius: '14.4px' }),
  menu: styles=>({...styles, backgroundColor:'#161622', boxShadow: '0px 7.2px 10.8px 5.4px rgba(0, 0, 0, 0.15), 0px 3.6px 3.6px rgba(0, 0, 0, 0.3)',borderRadius: '14.4px'}),
  multiValue: styles => ({...styles, backgroundColor:'#1E1E2D'}),
  multiValueLabel: styles => ({...styles, color:'white'}),
  clearIndicator: styles => ({...styles, display:'none'}),
  input: styles => ({...styles, color:'white'}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = 'white';
    return {
      ...styles,
      backgroundColor: isDisabled ? '#1E1E2D' : '#161622',
      color: '#FFF',
      cursor: isDisabled ? 'not-allowed' : 'default',
      borderRadius: '14.4px'
    };
  },
};

export default colourStyles