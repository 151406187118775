import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeviceById } from '../../appActions';
import mail_logo from '../../Assets/Icons/mail.png'
import loc_logo from '../../Assets/Icons/loc.png'
import edit_logo from '../../Assets/Icons/edit.png'
import phone_logo from '../../Assets/Icons/phone.png'
import './Sensors.css'
import { useParams } from 'react-router-dom';
import add_big_logo from '../../Assets/Icons/add_big.png'
import trash_logo from '../../Assets/Icons/trash.png'
import AddSensorForm from '../../Components/AddSensorForm';
import callApi from '../../util/callApi';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


function Sensors() {

  const state = useSelector((state) => state);
  const dispatch = useDispatch()
  const {id} = useParams()

  const [viewFormModal, setViewFormModal] = useState(false)
  const [editObject, setEditObject] = useState({})

  useEffect(() => {
    if(!state.device_det || id !== state.device_det._id){
      dispatch(fetchDeviceById({_id:id}))
  
    }
  }, [])

  const handleEditDevice = (device_det)=>{
    setEditObject(device_det)
  }

  const handleDelete = (sensor_id)=>{
    if(window.confirm('Are you certain you wish to remove this sensor? All related data will also be deleted.')){
      callApi('delete_sensor','post',{sensor_id}).then((res)=>{
        if(res.status == 'Success'){
          dispatch(fetchDeviceById({_id:id}))
        }else{
          alert('An error occured. Please try again!')
        }
      })
    }
  }

  const handleAddDevice = (data)=>{
    setViewFormModal(true)
  }

  return (
    <div className='device-top containor'>
      <div className="noti-header">
        <h1>Sensors</h1>
      </div>
      {state?.device_det && 
      <div className='biz-box'>
        <div className='det-box'>
          <div className="biz-det device-biz-det">
            <h1>{state.device_det.name}</h1>
           
            {/* <p className='biz-p'><img src={mail_logo} alt="" />{state.device_det.email}</p>
            <p className='biz-p'><img src={phone_logo} alt="" />{state.device_det.phone}</p>
            <p className='biz-p'><img src={loc_logo} alt="" />{state.device_det.address}</p> */}
          </div>
          <div className="proj-det device-proj-det">
            <h1>Total Sensors : {state.device_det.sensor_id?.length}</h1>
          </div>
        </div>
      </div> 
      }
      <div className="proj-cards-top"> 
        {state.device_det?.sensor_id?.sort((a,b)=>parseInt(a.sensor_no)>parseInt(b.sensor_no)?1:-1).map(sensor=>{
          return(
            <div className="proj-card">
              <div className="proj-card-header">
                <h1>{sensor.name}</h1>
                <div className="proj-card-opts">
                  <div onClick={()=>{handleEditDevice(sensor)}} className="opt-box ptr">
                    <EditIcon/>
                  </div>
                  <div onClick={()=>{handleDelete(sensor._id)}} className="opt-box ptr">
                    <DeleteIcon/>
                  </div>
                </div>
              </div>
              <div className="device-card-footer">
                <div className="proj-card-cont">
                  <p>Type: <span>{sensor.type?.name}</span></p>
                  {/* <p>Project name: <span>{project.name}</span></p> */}
                  <p>Save Data : <span>{`${sensor.save_data}`}</span></p>
                  <p>Hide Report : <span>{`${sensor.hide_report}`}</span></p>
                </div>
              </div>
            </div>
          )
        })}
        {/* <div onClick={()=>{handleAddDevice(state?.device_det?._id)}} className="proj-card proj-add-card ptr">
          <h1>Add <br /> Sensor</h1>
          <img src={add_big_logo} alt="" />
        </div> */}
      </div>
      {/* {viewFormModal && <AddSensorForm device_id={state?.device_det?._id} sensor_no={state.device_det.sensor_id?state.device_det.sensor_id.length:0} setCloseModal={setViewFormModal}/>} */}
      {Object.keys(editObject).length !== 0 && 
        <AddSensorForm
          editObject={{...editObject,type:editObject.type?._id}} 
          setCloseModal={setEditObject}
          mode = 'edit'
          device_id = {state?.device_det?._id}
        />
      }
    </div>
  )
}

export default Sensors