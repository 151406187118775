import React, { useEffect, useState } from 'react'
import { ResponsiveBullet } from '@nivo/bullet'
import RangeSlider from 'react-range-slider-input';

function LevelChart({data, layout, unit}) {
  const [chartData, setchartData] = useState(0)
  useEffect(() => {
    if(data?.length>0){
      // let formattedData = {ranges:[100],measures:[data[0].value], markers:[data[0].value]}
      // setchartData([formattedData])
      setchartData(data[0].value)
    }
  }, [data])
  return (
    <>
      <RangeSlider 
          value={[0,chartData]}
          // thumbsDisabled={[true,false]}
          orientation={layout}
          rangeSlideDisabled={true}
          className={`${layout}-level-chart level-chart`}
          // min={-20}
      />
      <p className='level-chart-value'>{chartData} {unit}</p>
      {/* <ResponsiveBullet
        data={chartData}
        margin={{ top: 50, right: 90, bottom: 50, left: 90 }}
        layout={layout}
        titleAlign="start"
        titleOffsetX={-70}
        measureSize={1}
        markerSize={1}
        rangeColors='red_blue'
        measureColors='white'
        markerColors='white'
        spacing={200}
        theme = {{
          textColor: 'white',
          axis:{
            tickColor: "white",
            textColor: "white"
          }
        }}
      /> */}
    </>
  )
}

export default LevelChart