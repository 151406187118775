const isJson = (item) => {
  item = typeof item !== "string" ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === "object" && item !== null) {
    return true;
  }

  return false;
};

const storageService = {
  html5StorageEnabled: typeof window !== 'undefined' && window.localStorage && window.sessionStorage,
  appStoragePrefix: "peping_",

  set: (name, value) => {
    if (storageService.html5StorageEnabled) {
      localStorage.setItem(storageService.appStoragePrefix + name, value);
    }
  },

  setObject: (name, value, isForSession) => {
    let validateValue = isJson(value);
    if (validateValue) {
      localStorage.setItem(
        storageService.appStoragePrefix + name,
        JSON.stringify(value),
      );
    } else {
    }
  },
  // getter for the set key value pair
  get: (name) => {
    if (storageService.html5StorageEnabled) {
      return localStorage.getItem(storageService.appStoragePrefix + name);
    }
  },

  //getter for a set object
  getObject: (name) => {
    if (storageService.html5StorageEnabled) {
      const localValue = localStorage.getItem(storageService.appStoragePrefix + name);
      if (localValue != null) {
        if (isJson(localValue)) return JSON.parse(localValue);
        else console.error(localValue + " is not a JSON object");
      }
    }
  },
  //removing a name
  remove: (name) => {
    if (storageService.html5StorageEnabled) {
      localStorage.removeItem(storageService.appStoragePrefix + name);
    }
  },

  flush: () => {
    localStorage.clear();
  },
};

export default storageService;
