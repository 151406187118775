import React, { useState } from 'react';
import { useEffect } from 'react';
import { publishData } from '../appActions';
import { MapContainer, TileLayer, Marker, Polyline,useMap,Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';


const blueDotIcon = new L.DivIcon({
  html: '<div class="blue-dot"></div>',
  className: 'custom-blue-dot-icon',
  iconSize: [20, 20],
  iconAnchor: [10, 10] // Center the icon
});

const CurvedPolyline = ({ positions }) => {
  const map = useMap();

  useEffect(() => {
    if (positions.length > 1) {
      const path = [];
      for (let i = 0; i < positions.length - 1; i++) {
        path.push('M', positions[i], 'Q', positions[i], positions[i + 1]);
      }
      const curve = L.curve(path, { color: 'blue' });
      curve.addTo(map);

      return () => {
        map.removeLayer(curve);
      };
    }
  }, [map, positions]);

  return null;
};

function FlightmapSecond({ data, data1, topic }) {
  const [fileContent, setFileContent] = useState('');
  const [coordinates, setCoordinates] = useState([]);
  const [currentpos,setcurrentpos] =useState([])
  const [Topic,setTopic] =useState([])
 

  useEffect(() => {
    if (data && data.length > 0) {
      setcurrentpos(data[0].value)
    }

    if (data1 && data1.length > 0) {
      
      const curretfulldata = data1[0];
      const lines = curretfulldata.value.split("\r\n");
      lines.shift(); // Remove header line
      const arrays = lines.map(line => {
        const values = line.split("\t");
        const lat = parseFloat(values[8]);
        const lon = parseFloat(values[9]);
        return [lat, lon];
      }).filter(([lat, lon]) => !isNaN(lat) && !isNaN(lon) && lat !== 0 && lon !== 0); // Filter out invalid and 0 values

      setCoordinates(arrays);
    }

    if (topic) {
      setTopic(topic)
    }
  }, [data, data1, topic]);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setFileContent(e.target.result);
      sendMessages(e.target.result);
    };

    reader.readAsText(file);
  };

  const sendMessages = (file) => {
    if (topic.length>1){
    const sendingData = {
      data: { tim: new Date(), val: `n,n,n,n,n,n,${file}` },
      topic: `ah/add/${topic}`
    };
  
    publishData(sendingData)
    console.log('sendingData:', sendingData);
  }
  };

  return (
    <div className='second'>
      {coordinates.length > 0 ? (
        <MapContainer style={{ borderRadius: '5px', height: '100%', width: '100%' }} center={coordinates[0]} zoom={13} scrollWheelZoom={true} className='secondmap'>
          {/* <TileLayer
            attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
            maxZoom={50}
          /> */}
<TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        maxZoom={19}
      />

  

          {coordinates.map((position, index) => (
            <Marker key={index} position={position} icon={blueDotIcon}>
              <Popup>
                <div style={{ textAlign: 'center' }}>
                  <h3>Point {index + 1}</h3>
                  <p>Latitude: {position[0]}</p>
                  <p>Longitude: {position[1]}</p>
                </div>
              </Popup>
            </Marker>
          ))}
          <CurvedPolyline positions={coordinates} />
        </MapContainer>
        ) : (
          <p>Loading map data...</p>
      )}
      <br />
     <input type="file" onChange={handleFileChange} className='inp' />

    </div>
  );
}

export default FlightmapSecond;
