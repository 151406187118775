// // import React from 'react'
// // import { useState } from 'react'
// // import { useEffect } from 'react'
// // import { useDispatch, useSelector } from 'react-redux'
// // import cross_logo from '../Assets/Icons/cross.png'
// // import { AddNotification, EditNotification, FetchNotifications, FetchUsersInBusiness } from '../appActions'
// // import { useForm } from 'react-hook-form'

// // function AddNotificationForm({ setCloseModal, user_det, edit_obj }) {

// //   const { register, handleSubmit, getValues } = useForm({
// //     defaultValues: {
// //       ...edit_obj,
// //       project_id: edit_obj?.device_id.project_id,
// //       device_id: edit_obj?.device_id._id,
// //       sensor_id: edit_obj?.sensor_id._id
// //     }
// //   });
// //   const dispatch = useDispatch()
// //   const state = useSelector((state) => state);

// //   const [projectOptions, setProjectOptions] = useState([])
// //   const [deviceOptions, setDeviceOptions] = useState([])
// //   const [sensorOptions, setSensorOptions] = useState([])
// //   const [selectedSensor, setSelectedSensor] = useState({})

// //   useEffect(() => {
// //     if (!state.users_in_business) {
// //       dispatch(FetchUsersInBusiness({ business_id: user_det.business_id }))
// //     }

// //     if (edit_obj) {
// //       let biz = getObjectById(user_det.business_id, edit_obj.business_id)
// //       setProjectOptions(biz.project_id)
// //       let proj = getObjectById(biz.project_id, edit_obj.device_id.project_id)
// //       setDeviceOptions(proj.device_id)
// //       let dev = getObjectById(proj.device_id, edit_obj.device_id._id)
// //       setSensorOptions(dev)
// //     }
// //   }, [])




// //   const handleBusinessSelect = (e) => {
// //     if (e.target.value !== null) {
// //       setProjectOptions(getObjectById(user_det.business_id, e.target.value).project_id)
// //     }
// //   }
// //   const handleProjectSelect = (e) => {
// //     if (e.target.value !== null) {
// //       setDeviceOptions(getObjectById(projectOptions, e.target.value).device_id)
// //     }
// //   }
// //   const handleDeviceSelect = (e) => {
// //     if (e.target.value !== null) {
// //       let sensor_det = getObjectById(deviceOptions, e.target.value)
// //       setSensorOptions(sensor_det)
// //     }
// //   }
// //   const handleSensorSelect = (e) => {
// //     if (e.target.value !== null) {
// //       let sensor_det = getObjectById(sensorOptions.sensor_id, e.target.value)
// //       setSelectedSensor(sensor_det)
// //     }
// //   }

// //   const getObjectById = (arr, id) => {
// //     for (let i = 0; i < arr.length; i++) {
// //       if (arr[i]._id === id) {
// //         return arr[i];
// //       }
// //     }
// //   }

// //   const onSubmit = (data) => {
// //     if (parseInt(data.upper_threshold) > parseInt(data.upper_limit) && parseInt(data.lower_threshold) < parseInt(data.lower_limit)) {
// //       if (edit_obj) {
// //         data._id = edit_obj._id
// //         data.topic = sensorOptions.topic
// //         data.sensor_no = selectedSensor.sensor_no
// //         data.sensor_name = selectedSensor.name
// //         data.device_name = sensorOptions.name
// //         data.business_name = getObjectById(projectOptions, getValues('project_id'))?.name
// //         EditNotification(data).then(res => {
// //           if (res) {
// //             dispatch(FetchNotifications({ business_id: user_det.business_id }))
// //             setCloseModal(false)
// //           } else {
// //             alert("Error occured please try again!")
// //           }
// //         })
// //       } else {
// //         data.topic = sensorOptions.topic
// //         data.sensor_no = selectedSensor.sensor_no
// //         data.sensor_name = selectedSensor.name
// //         data.device_name = sensorOptions.name
// //         data.business_name = getObjectById(projectOptions, getValues('project_id'))?.name
// //         dispatch(AddNotification(data).then(res => {
// //           if (res) {
// //             dispatch(FetchNotifications({ business_id: user_det.business_id }))
// //             setCloseModal(false)
// //           } else {
// //             alert("Unable to add notification. Please try again!")
// //           }
// //         }))

// //       }
// //     } else {
// //       alert("Upper and Lower Threshold values should be above and below the upper and lower limit values respectively.")
// //     }
// //   }

// //   return (
// //     <div className='modal'>
// //       <form onSubmit={handleSubmit(onSubmit)}>
// //         <img onClick={() => { setCloseModal(false) }} className='form-cross-but' src={cross_logo} alt="" />
// //         <label className='form-label' htmlFor="">
// //           Select Business
// //           <select {...register('business_id', { required: "select one option" })} onChange={handleBusinessSelect}>
// //             <option value=''></option>
// //             {user_det?.business_id?.map(business => <option value={business?._id}>{business?.name}</option>)}
// //           </select>
// //         </label>
// //         <label className='form-label' htmlFor="">
// //           Select Project
// //           <select {...register('project_id', { required: "select one option" })} onChange={handleProjectSelect}>
// //             <option value=''></option>
// //             {projectOptions?.map(project => <option value={project?._id} selected={edit_obj?.device_id?.project_id === project?._id ? true : false}>{project?.name}</option>)}
// //           </select>
// //         </label>
// //         <label className='form-label' htmlFor="">
// //           Select Device
// //           <select {...register('device_id', { required: "select one option" })} onChange={handleDeviceSelect}>
// //             <option value=''></option>
// //             {deviceOptions?.map(device => <option value={device?._id} selected={edit_obj?.device_id?._id === device?._id ? true : false}>{device?.name}</option>)}
// //           </select>
// //         </label>
// //         <label className='form-label' htmlFor="">
// //           Select Sensor
// //           <select {...register('sensor_id', { required: "select one option" })} onChange={handleSensorSelect}>
// //             <option value=''></option>
// //             {sensorOptions.sensor_id?.map(sensor => <option value={sensor?._id} selected={edit_obj?.sensor_id?._id === sensor?._id ? true : false}>{sensor?.name}</option>)}
// //           </select>
// //         </label>
// //         <label className='form-label'>
// //           Upper Limit
// //           <input type="number" {...register('upper_limit', { required: true })} />
// //         </label>
// //         <label className='form-label'>
// //           Lower Limit
// //           <input type="number" {...register('lower_limit', { required: true })} />
// //         </label>
// //         <label className='form-label'>
// //           Upper Threshold
// //           <input type="number" {...register('upper_threshold', { required: true })} />
// //         </label>
// //         <label className='form-label'>
// //           Lower Threshold
// //           <input type="number" {...register('lower_threshold', { required: true })} />
// //         </label>
// //         <label className='form-label' htmlFor="">
// //           Select user for SMS
// //           <select {...register('sms_users', { required: "select one option" })}>

// //             <option value=''></option>
// //             {state?.users_in_business?.map(user => <option value={user?.phone_number} selected={edit_obj?.sms_users.includes(user?.phone_number.toString()) ? true : false}>{user?.username}</option>)}
// //           </select>
// //         </label>
// //         <label className='form-label' htmlFor="">
// //           Select user for Email
// //           <select {...register('email_users', { required: "select one option" })}>
// //             <option value=''></option>
// //             {state?.users_in_business?.map(user => <option value={user?.contact_email} selected={edit_obj?.email_users.includes(user.contact_email) ? true : false}>{user?.username}</option>)}
// //           </select>
// //         </label>
// //         <button className='form-but-1'>{edit_obj ? "Save" : "Add"}</button>
// //       </form>
// //     </div>
// //   )
// // }

// // export default AddNotificationForm


// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import cross_logo from '../Assets/Icons/cross.png';
// import { AddNotification, EditNotification, FetchNotifications, FetchUsersInBusiness } from '../appActions';
// import { useForm } from 'react-hook-form';
// import Select from 'react-select';

// const customStyles = {
//   control: (provided, state) => ({
//     ...provided,
//     backgroundColor: 'rgba(47, 47, 47, 1)',
//     boxShadow: '0px 7.2px 10.8px 5.4px rgba(0, 0, 0, 0.15), 0px 3.6px 3.6px rgba(0, 0, 0, 0.3)',
//     borderRadius: '8px',
//     border: 'none',
//     color: 'white',
//     height: '50px',
//     padding: '10px',
//     width: '100%',
//     gridArea: 'none',
//     cursor: 'pointer',
//     display: 'flex',
//     alignItems: 'center',
//     fontSize:'12px'
//   }),
//   menu: (provided) => ({
//     ...provided,
//     backgroundColor: 'rgba(47, 47, 47, 1)',
//   }),
//   singleValue: (provided) => ({
//     ...provided,
//     color: 'white',
//   }),
//   option: (provided, state) => ({
//     ...provided,
//     backgroundColor: state.isSelected ? 'white' : 'transparent',
//     color: 'white',
//     '&:hover': {
//       backgroundColor: 'rgba(255, 255, 255, 0.1)',
//     },
//   }),
//   multiValue: (provided) => ({
//     ...provided,
//     backgroundColor: '#303043',
//   }),
//   multiValueLabel: (provided) => ({
//     ...provided,
//     color: 'white',
//   }),
//   multiValueRemove: (provided) => ({
//     ...provided,
//     color: 'white',
//     ':hover': {
//       backgroundColor: '#FB493F',
//       color: 'white',
//     },
//   }),
//   placeholder: (provided) => ({
//     ...provided,
//     color: 'white',
//   }),
// };

// function AddNotificationForm({ setCloseModal, user_det, edit_obj }) {
//   const { register, handleSubmit, getValues, setValue } = useForm({
//     defaultValues: {
//       ...edit_obj,
//       project_id: edit_obj?.device_id.project_id,
//       device_id: edit_obj?.device_id._id,
//       sensor_id: edit_obj?.sensor_id._id,
//     },
//   });

//   const dispatch = useDispatch();
//   const state = useSelector((state) => state);

//   const [projectOptions, setProjectOptions] = useState([]);
//   const [deviceOptions, setDeviceOptions] = useState([]);
//   const [sensorOptions, setSensorOptions] = useState([]);
//   const [selectedSensor, setSelectedSensor] = useState({});
//   const [smsOptions, setSmsOptions] = useState([]);
//   const [emailOptions, setEmailOptions] = useState([]);

//   useEffect(() => {
//     if (!state.users_in_business) {
//       dispatch(FetchUsersInBusiness({ business_id: user_det.business_id }));
//     }

//     if (edit_obj) {
//       let biz = getObjectById(user_det.business_id, edit_obj.business_id);
//       setProjectOptions(biz.project_id);
//       let proj = getObjectById(biz.project_id, edit_obj.device_id.project_id);
//       setDeviceOptions(proj.device_id);
//       let dev = getObjectById(proj.device_id, edit_obj.device_id._id);
//       setSensorOptions(dev);
//     }
//   }, []);

//   useEffect(() => {
//     if (state.users_in_business) {
//       const smsOpts = state.users_in_business.map((user) => ({
//         value: user.phone_number,
//         label: user.username,
//       }));
//       const emailOpts = state.users_in_business.map((user) => ({
//         value: user.contact_email,
//         label: user.username,
//       }));
//       setSmsOptions(smsOpts);
//       setEmailOptions(emailOpts);
//     }
//   }, [state.users_in_business]);

//   const handleBusinessSelect = (e) => {
//     if (e.target.value !== null) {
//       setProjectOptions(getObjectById(user_det.business_id, e.target.value).project_id);
//     }
//   };
//   const handleProjectSelect = (e) => {
//     if (e.target.value !== null) {
//       setDeviceOptions(getObjectById(projectOptions, e.target.value).device_id);
//     }
//   };
//   const handleDeviceSelect = (e) => {
//     if (e.target.value !== null) {
//       let sensor_det = getObjectById(deviceOptions, e.target.value);
//       setSensorOptions(sensor_det);
//     }
//   };
//   const handleSensorSelect = (e) => {
//     if (e.target.value !== null) {
//       let sensor_det = getObjectById(sensorOptions.sensor_id, e.target.value);
//       setSelectedSensor(sensor_det);
//     }
//   };

//   const getObjectById = (arr, id) => {
//     for (let i = 0; i < arr.length; i++) {
//       if (arr[i]._id === id) {
//         return arr[i];
//       }
//     }
//   };

//   const onSubmit = (data) => {
//     if (
//       parseInt(data.upper_threshold) > parseInt(data.upper_limit) &&
//       parseInt(data.lower_threshold) < parseInt(data.lower_limit)
//     ) {
//       if (edit_obj) {
//         data._id = edit_obj._id;
//         data.topic = sensorOptions.topic;
//         data.sensor_no = selectedSensor.sensor_no;
//         data.sensor_name = selectedSensor.name;
//         data.device_name = sensorOptions.name;
//         data.business_name = getObjectById(projectOptions, getValues('project_id'))?.name;
//         EditNotification(data).then((res) => {
//           if (res) {
//             dispatch(FetchNotifications({ business_id: user_det.business_id }));
//             setCloseModal(false);
//           } else {
//             alert('Error occurred, please try again!');
//           }
//         });
//       } else {
//         data.topic = sensorOptions.topic;
//         data.sensor_no = selectedSensor.sensor_no;
//         data.sensor_name = selectedSensor.name;
//         data.device_name = sensorOptions.name;
//         data.business_name = getObjectById(projectOptions, getValues('project_id'))?.name;
//         dispatch(
//           AddNotification(data).then((res) => {
//             if (res) {
//               dispatch(FetchNotifications({ business_id: user_det.business_id }));
//               setCloseModal(false);
//             } else {
//               alert('Unable to add notification. Please try again!');
//             }
//           })
//         );
//       }
//     } else {
//       alert(
//         'Upper and Lower Threshold values should be above and below the upper and lower limit values respectively.'
//       );
//     }
//   };

//   return (
//     <div className="modal">
//       <form onSubmit={handleSubmit(onSubmit)}>
//         <img onClick={() => setCloseModal(false)} className="form-cross-but" src={cross_logo} alt="" />
//         <label className="form-label" htmlFor="">
//         <div className="select-wrapper">
//           <select
          
//             {...register('business_id', { required: 'select one option' })}
//             onChange={handleBusinessSelect}
//             placeholder='Select Business'
//             defaultValue=""
//           >
//             <option value="" disabled>Select Business</option>
//             {user_det?.business_id?.map((business) => (
//               <option key={business?._id} value={business?._id}>{business?.name}</option>
//             ))}
//           </select>
//           </div>
//         </label>
//         <label className="form-label" htmlFor="">
//         <div className="select-wrapper">
//           <select
//             {...register('project_id', { required: 'select one option' })}
//             onChange={handleProjectSelect}
//             placeholder='Select Project'
//             defaultValue=""
//           >
//             <option value="" disabled>Select Project</option>
//             {projectOptions?.map((proj) => (
//               <option key={proj?._id} value={proj?._id}>{proj?.name}</option>
//             ))}
//           </select>
//           </div>
//         </label>
//         <label className="form-label" htmlFor="">
//         <div className="select-wrapper">
//           <select
//             {...register('device_id', { required: 'select one option' })}
//             onChange={handleDeviceSelect}
//             placeholder='Select Device'
//             defaultValue=""
//           >
//             <option value="" disabled>Select Device</option>
//             {deviceOptions?.map((dev) => (
//               <option key={dev?._id} value={dev?._id}>{dev?.name}</option>
//             ))}
//           </select>
//           </div>
//         </label>
//         <label className="form-label" htmlFor="">
//         <div className="select-wrapper">
//           <select
//             {...register('sensor_id', { required: 'select one option' })}
//             onChange={handleSensorSelect}
//             placeholder='Select Sensor'
//             defaultValue=""
//           >
//             <option value="" disabled>Select Sensor</option>
//             {sensorOptions?.sensor_id?.map((sensor) => (
//               <option key={sensor?._id} value={sensor?._id}>{sensor?.name}</option>
//             ))}
//           </select>
//           </div>
//         </label>
//         <label className="form-label" htmlFor="">
//           <input
//             className="form-input"
//             placeholder="Notification Name"
//             {...register('notification_name', { required: 'Enter notification name' })}
//             type="text"
//           />
//         </label>
//         <label className="form-label" htmlFor="">
//           <input
//             className="form-input"
//             placeholder="Upper Limit"
//             {...register('upper_limit', { required: 'Enter Upper Limit' })}
//             type="number"
//           />
//         </label>
//         <label className="form-label" htmlFor="">
//           <input
//             className="form-input"
//             placeholder="Upper Threshold"
//             {...register('upper_threshold', { required: 'Enter Upper Threshold' })}
//             type="number"
//           />
//         </label>
//         <label className="form-label" htmlFor="">
//           <input
//             className="form-input"
//             placeholder="Lower Limit"
//             {...register('lower_limit', { required: 'Enter Lower Limit' })}
//             type="number"
//             style={{color:'white'}}
//           />
//         </label>
//         <label className="form-label" htmlFor="">
//           <input
//             className="form-input"
//             placeholder="Lower Threshold"
//             {...register('lower_threshold', { required: 'Enter Lower Threshold' })}
//             type="number"
//           />
//         </label>
//         <label className="form-label" htmlFor="">
//           <Select
//             placeholder="Select SMS Users"
//             styles={customStyles}
//             isMulti
//             name="phone_numbers"
//             options={smsOptions}
//             className="basic-multi-select"
//             classNamePrefix="select"
//             defaultValue={[]}
//             {...register('phone_numbers')}
//           />
//         </label>
//         <label className="form-label" htmlFor="">
          
//           <Select
//             placeholder="Select Email Users"
//             styles={customStyles}
//             isMulti
//             name="emails"
//             options={emailOptions}
//             className="basic-multi-select"
//             classNamePrefix="select"
//             defaultValue={[]}
//             {...register('emails')}
//           />
//         </label>
//         <input className="submit" type="submit" value="Submit" />
//       </form>
//     </div>
//   );
// }

// export default AddNotificationForm;


import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cross_logo from '../Assets/Icons/cross.png';
import { AddNotification, EditNotification, FetchNotifications, FetchUsersInBusiness } from '../appActions';
import { useForm } from 'react-hook-form';
import Select from 'react-select';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    
    backgroundColor: 'rgba(47, 47, 47, 1)',
    boxShadow: '0px 7.2px 10.8px 5.4px rgba(0, 0, 0, 0.15), 0px 3.6px 3.6px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',
    border: 'none',
    color: 'white',
    height: '50px',
    padding: '10px',
    width: '100%',
    gridArea: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px'
    
  }),
  menu: (provided) => ({
    ...provided,
        background:'rgba( 76, 76, 76, 0.65 )',
    backdropFilter:'blur( 9.5px )',
    borderRadius: '10px',
    border: '1px solid rgba( 255, 255, 255, 0.18 )',
    // backgroundColor: '#4a4948',
    fontSize:'12px',
    
    // border:'1px solid #7469B6',
    color: 'white',
    fontSize:'12px'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '' : 'transparent',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#303043',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'white',
    ':hover': {
      backgroundColor: '#FB493F',
      color: 'white',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'white',
  }),
};

function AddNotificationForm({ setCloseModal, user_det, edit_obj }) {
  const { register, handleSubmit, getValues, setValue } = useForm({
    defaultValues: {
      ...edit_obj,
      project_id: edit_obj?.device_id.project_id,
      device_id: edit_obj?.device_id._id,
      sensor_id: edit_obj?.sensor_id._id,
    },
  });

  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const [projectOptions, setProjectOptions] = useState([]);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [sensorOptions, setSensorOptions] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState({});
  const [smsOptions, setSmsOptions] = useState([]);
  const [emailOptions, setEmailOptions] = useState([]);
  console.log(smsOptions)
  useEffect(() => {
    if (!state.users_in_business) {
      dispatch(FetchUsersInBusiness({ business_id: user_det.business_id }));
    }

    if (edit_obj) {
      let biz = getObjectById(user_det.business_id, edit_obj.business_id);
      setProjectOptions(biz.project_id);
      let proj = getObjectById(biz.project_id, edit_obj.device_id.project_id);
      setDeviceOptions(proj.device_id);
      let dev = getObjectById(proj.device_id, edit_obj.device_id._id);
      setSensorOptions(dev);
    }
  }, []);

  useEffect(() => {
    if (state.users_in_business) {
      const smsOpts = state.users_in_business.map((user) => ({
        value: user.phone_number,
        label: user.username,
      }));
      const emailOpts = state.users_in_business.map((user) => ({
        value: user.contact_email,
        label: user.username,
      }));
      setSmsOptions(smsOpts);
      setEmailOptions(emailOpts);
    }
  }, [state.users_in_business]);

  const handleBusinessSelect = (selectedOption) => {
    if (selectedOption) {
      setValue('business_id', selectedOption.value);
      setProjectOptions(getObjectById(user_det.business_id, selectedOption.value).project_id);
    }
  };

  const handleProjectSelect = (selectedOption) => {
    if (selectedOption) {
      setValue('project_id', selectedOption.value);
      setDeviceOptions(getObjectById(projectOptions, selectedOption.value).device_id);
    }
  };

  const handleDeviceSelect = (selectedOption) => {
    if (selectedOption) {
      setValue('device_id', selectedOption.value);
      let sensor_det = getObjectById(deviceOptions, selectedOption.value);
      setSensorOptions(sensor_det);
    }
  };

  const handleSensorSelect = (selectedOption) => {
    if (selectedOption) {
      setValue('sensor_id', selectedOption.value);
      let sensor_det = getObjectById(sensorOptions.sensor_id, selectedOption.value);
      setSelectedSensor(sensor_det);
    }
  };

  const getObjectById = (arr, id) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]._id === id) {
        return arr[i];
      }
    }
  };

  const onSubmit = (data) => {
  data.sms_users = data.sms_users.map((option) => option.value);
  data.email_users = data.email_users.map((option) => option.value);
    if (
      parseFloat(data.upper_threshold) > parseFloat(data.upper_limit) &&
      parseFloat(data.lower_threshold) < parseFloat(data.lower_limit)
    ) {
      if (edit_obj) {
        data._id = edit_obj._id;
        data.topic = sensorOptions.topic;
        data.sensor_no = selectedSensor.sensor_no;
        data.sensor_name = selectedSensor.name;
        data.device_name = sensorOptions.name;
        data.business_name = getObjectById(projectOptions, getValues('project_id'))?.name;
        EditNotification(data).then((res) => {
          if (res) {
            dispatch(FetchNotifications({ business_id: user_det.business_id }));
            setCloseModal(false);
          } else {
            alert('Error occurred, please try again!');
          }
        });
      } else {
        data.topic = sensorOptions.topic;
        data.sensor_no = selectedSensor.sensor_no;
        data.sensor_name = selectedSensor.name;
        data.device_name = sensorOptions.name;
        data.business_name = getObjectById(projectOptions, getValues('project_id'))?.name;
        dispatch(
          AddNotification(data).then((res) => {
            if (res) {
              dispatch(FetchNotifications({ business_id: user_det.business_id }));
              setCloseModal(false);
            } else {
              alert('Unable to add notification. Please try again!');
            }
          })
        );
      }
    } else {
      alert(
        'Upper and Lower Threshold values should be above and below the upper and lower limit values respectively.'
      );
    }
  };

  return (
    <div className="modal">
      <form onSubmit={handleSubmit(onSubmit)}>
        <img onClick={() => setCloseModal(false)} className="form-cross-but" src={cross_logo} alt="" />
        
        <label className="form-label" htmlFor="">
          <div className="select-wrapper">
            <Select
              placeholder="Select Business"
              
              styles={customStyles}
              options={user_det?.business_id?.map((business) => ({
                value: business?._id,
                label: business?.name,
              }))}
              onChange={handleBusinessSelect}
            />
          </div>
        </label>
        
        <label className="form-label" htmlFor="">
          <div className="select-wrapper">
            <Select
              placeholder="Select Project"
              styles={customStyles}
              options={projectOptions?.map((proj) => ({
                value: proj?._id,
                label: proj?.name,
              }))}
              onChange={handleProjectSelect}
            />
          </div>
        </label>
        
        <label className="form-label" htmlFor="">
          <div className="select-wrapper">
            <Select
              placeholder="Select Device"
              styles={customStyles}
              options={deviceOptions?.map((dev) => ({
                value: dev?._id,
                label: dev?.name,
              }))}
              onChange={handleDeviceSelect}
            />
          </div>
        </label>
        
        <label className="form-label" htmlFor="">
          <div className="select-wrapper">
            <Select
              placeholder="Select Sensor"
              styles={customStyles}
              options={sensorOptions?.sensor_id?.map((sensor) => ({
                value: sensor?._id,
                label: sensor?.name,
              }))}
              onChange={handleSensorSelect}
            />
          </div>
        </label>
        
        <label className="form-label" htmlFor="">
          <input
            className="form-input"
            placeholder="Notification Name"
            {...register('notification_name', { required: 'Enter notification name' })}
            type="text"
          />
        </label>
        
        <label className="form-label" htmlFor="">
          <input
            className="form-input"
            placeholder="Upper Limit"
            {...register('upper_limit', { required: 'Enter Upper Limit' })}
            type="number"
            step="0.01"
          />
        </label>
        <label className="form-label" htmlFor="">
          <input
            className="form-input"
            placeholder="Lower Limit"
            {...register('lower_limit', { required: 'Enter Lower Limit' })}
            type="number"
            step="0.01"
            style={{ color: 'white' }}
          />
        </label>
        
        <label className="form-label" htmlFor="">
          <input
            className="form-input"
            placeholder="Upper Threshold"
            {...register('upper_threshold', { required: 'Enter Upper Threshold' })}
            type="number"
            step="0.01"
          />
        </label>
        
       
        
        <label className="form-label" htmlFor="">
          <input
            className="form-input"
            placeholder="Lower Threshold"
            {...register('lower_threshold', { required: 'Enter Lower Threshold' })}
            type="number"
            step="0.01"
          />
        </label>
        
        <label className="form-label" htmlFor="">
          <Select
            placeholder="Select Whatsapp Users"
            styles={customStyles}
            isMulti
            
            name="sms_users"
            options={smsOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            defaultValue={[]}
            {...register('sms_users', { required: true })}
            onChange={(selectedOptions) => setValue('sms_users', selectedOptions)}
          />
        </label>
        
        <label className="form-label" htmlFor="">
          <Select
            placeholder="Select Email Users"
            styles={customStyles}
            isMulti
            name="email_users"
            
            options={emailOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            defaultValue={[]}
            {...register('email_users', { required: true })}
            onChange={(selectedOptions) => setValue('email_users', selectedOptions)}
          />
        </label>
        
        <input className="submit" type="submit" value="Submit" id='notiform' />
      </form>
    </div>
  );
}

export default AddNotificationForm;
