import React, { useEffect, useState } from 'react'

function Alert({data, width}) {

  const [cardColor, setCardColor] = useState('#24ff00bf')
  const [cardContent, setCardContent] = useState('All Normal')
  const [lastValueDate, setLastValueDate] = useState('');
  function convertTo12Hour(time24) {
    // Split the input into hours and minutes
    const [time, date] = time24.split(' ');
    let [hours, minutes] = time.split(':').map(Number);
    
    // Determine AM or PM suffix
    const suffix = hours >= 12 ? 'PM' : 'AM';
    
    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    
    // Format minutes and hours as two-digit strings
    minutes = minutes < 10 ? '0' + minutes : minutes;
    
    setLastValueDate( `${date} ${hours}:${minutes} ${suffix}`);
}

  useEffect(() => {
    if(data && data.length>0){
      convertTo12Hour(data[0].datetime)
      let last_value = data[0].value
      if(last_value?.includes('fire alert')){
        setCardColor('#D30000')
      }else{
        setCardColor('#24ff00bf')
      }
      setCardContent(last_value)
    }
  }, [data])
  
  return (
    <>
      <div className='alert-graph-card' style={{ color: cardColor }}>
        <p className='alert-graph-content'>{cardContent}</p>
      </div>
      {lastValueDate && (
        <p style={{ marginBottom: '50px', color: '#999' }}>
          Last updated on: {lastValueDate}
        </p>
      )}
    </>
  );
  
}

export default Alert